<template>
    <div>
        <div class="unit-des">
            <p class="description" v-if="space.unit.description">{{ space.unit.description }}</p>
            <p class="default-promo" v-if="defaultPromo && space.status=='available'">{{ defaultPromo.promo_description }}</p>
        </div>
        <div class="banner_slider_wrapper" ref="slide" v-if="spaceImages.length">
            <ul class="slider_controls">
                <li v-if="leftPosition < 0" class="prev icon-view_more_icon" @click.prevent="prev"></li>
                <li v-if="showNext" class="next icon-view_more_icon" @click.prevent="next"></li>
            </ul>
            <ul class="banner_slider" :style="{ left: leftPosition + 'px' }" ref="eachSlide">
                <li v-for="(media, index) in spaceImages" class="scroll_image" v-images-loaded:on.progress="imageProgress">
                    <div class="slide_box">
                        <a class="slide_wrapper" v-on:click="popUpSpaceImage(index)">
                            <img :src="media.thumbnail_image" :alt="media.category"/>
                        </a>
                    </div>
                </li>
            </ul>
            <div class="imgbox" v-bind:class="{ block: showPopup, 'media-loder': isMediaLoading}">
                <div class="gif-loader-wrapper">
                    <div class="gif-loader"></div>
                </div>
                <div class="imgbox-wrapper on-media-load">

                    <div class="imgbox-content-wrapper">
                        <div class="imgbox-content">
                            <div class="close-button" @click.prevent="hideSpacePopup">
                                <span class="icon-x-out"></span>
                            </div>
                            <img @load="mediaLoad" :src="selectedPop.image_url" alt="" height="570" ref="popupImage" max-width="879"/>
                        </div>
                        <div class="imgbox-arrows">
                            <span class="prev-arrow icon-arrow_top" @click.prevent="prevSpacePopUp" v-if="selectedMediaIndex > 0"></span>
                            <span class="next-arrow icon-arrow_top" @click.prevent="nextSpacePopUp" v-if="selectedMediaIndex < spaceImages.length-1"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mask" v-bind:class="{ block: showPopup }"></div>
        </div>
        <div class="structure-img">
            <ul>
                <li class="size">
                    <span>Unit Size:</span>
                    <span>{{ space.unit.actual_width}} X {{ space.unit.actual_length }}</span>
                </li>
                <li>
                    <span>Door Height (DH):</span>
                    <span>{{ spaceDimention('Height') }}</span>
                </li>
                <li>
                    <span>Door Width (DW):</span>
                    <span>{{ spaceDimention('Width') }}</span>
                </li>
                <li>
                    <span>Ceiling Height (CH):</span>
                    <span>{{ spaceDimention('Ceiling Height') }}</span>
                </li>
            </ul>
        </div>
        <ul class="features" v-if="features.length">
            <li v-for="(feature, index) in features" v-if="!['Height', 'Width', 'Ceiling Height'].includes(feature.property_name)">{{ feature.property_name }}: {{ feature.selected_choice }}</li>
        </ul>
        <ul class="amenities" v-if="amenities.length">
            <li class="head">Amenities</li>
            <li v-for="(amenitie, index) in amenities">{{ amenitie }}</li>
        </ul>
        <!-- <div v-if="index==total && buttonStatus && space.status.length!=0" class="button-wrap">
            <span @click="clearSpaceSearch" @click.prevent="setRentalActiveSection('detail_page')">Select another Space</span>
            <span class="pay-bill" @click.prevent="setActiveSection('pay_bill')">Pay Bill</span>
        </div> -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
import imagesLoaded from 'vue-images-loaded'
export default {
  name: 'spaceAccordion',
  props: ['space', 'defaultPromo', 'spaceImages', 'amenities', 'features'],
  data: function () {
    return {
      leftPosition: -0,
      scrollLength: 0,
      totalSlideLength: 0,
      showNext: false,
      showPopup: false,
      scrollElements: [],
      selectedMediaIndex: 0,
      isMediaLoading: false,
      imageLoaded: 0
    }
  },
  directives: {
    imagesLoaded
  },
  mounted: function () {
    if (this.spaceImages.length) {
      this.scrollElements = this.$refs.eachSlide.children
    }
  },
  methods: {
    imageProgress (instance, image) {
      if (image.isLoaded) {
        this.imageLoaded = this.imageLoaded + 1
        if (this.imageLoaded >= this.scrollElements.length) {
          this.updateSpaceSlideData()
        }
      }
    },
    next: function () {
      var x = 0
      for (const i in this.scrollElements) {
        if (i < this.scrollElements.length) {
          x = x + Math.floor(this.scrollElements[i].clientWidth)
          if ((this.leftPosition - this.scrollLength) > -x) {
            var leftMove = x - Math.floor(this.scrollElements[i].clientWidth)
            if (leftMove < (this.totalSlideLength - this.scrollLength)) {
              this.leftPosition = -(leftMove)
            } else {
              this.leftPosition = -(this.totalSlideLength - this.scrollLength)
            }
            return
          }
        }
      }
    },
    prev: function () {
      var x = 0
      for (const i in this.scrollElements) {
        if (i < this.scrollElements.length) {
          x = x + Math.floor(this.scrollElements[i].clientWidth)
          if (x > -(this.leftPosition)) {
            var rightMove = this.leftPosition + (this.scrollLength - (x + this.leftPosition))
            if (rightMove < 0) {
              this.leftPosition = (this.leftPosition + (this.scrollLength - (x + this.leftPosition))) + 3
            } else {
              this.leftPosition = 0
            }
            return
          }
        }
      }
    },
    updateSpaceSlideData: function () {
      this.scrollLength = Math.floor(this.$refs.slide.clientWidth)
      for (const x in this.scrollElements) {
        if (x < this.scrollElements.length) {
          this.totalSlideLength = this.totalSlideLength + Math.floor(this.scrollElements[x].clientWidth)
        }
      }
      if (this.totalSlideLength < this.scrollLength || !this.spaceImages.length) {
        this.showNext = false
      } else {
        this.showNext = true
      }
    },
    popUpSpaceImage: function (index) {
      this.selectedMediaIndex = index
      this.showPopup = true
    },
    hideSpacePopup: function () {
      this.showPopup = false
      this.selectedMediaIndex = 0
    },
    prevSpacePopUp: function () {
      this.selectedMediaIndex--
      this.isMediaLoading = true
    },
    nextSpacePopUp: function () {
      this.selectedMediaIndex++
      this.isMediaLoading = true
    },
    mediaLoad: function () {
      var self = this
      setTimeout(function () { self.isMediaLoading = false }, 1000)
    },
    spaceDimention: function (value) {
      for (const i in this.features) {
        if (this.features[i].property_name == value) {
          return this.features[i].selected_choice.length > 0 ? this.features[i].selected_choice : 'N/A'
        }
      }
      return 'N/A'
    }
  },
  computed: {
    selectedPop: function () {
      return this.spaceImages[this.selectedMediaIndex]
    }
  },
  watch: {
    leftPosition: function () {
      if (this.leftPosition == -(this.totalSlideLength - this.$refs.slide.clientWidth) || this.totalSlideLength < this.scrollLength) {
        this.showNext = false
      } else {
        this.showNext = true
      }
    },
    selectedMediaIndex: function () {
      this.isMediaLoading = true
    }
  }
}
</script>

<style scoped lang="less">
    @import '../../assets/less/colors';
    @import '../../assets/less/mixins';
    @import '../../assets/less/flexbox';
    div {
        .unit-des {
            padding: 0px 30px;
            .description {
                font-size: 14px;
                line-height: 18px;
                margin-top: 23px;
            }
            .default-promo {
                color: @call_to_action_color;
                font-weight: bold;
            }
        }
        .banner_slider_wrapper {
            width: 100%;
            min-height: 153px;
            max-height: 153px;
            overflow: hidden;
            margin: 30px auto;
            background-color: #fff;
            position: relative;
            ul {
                &.banner_slider {
                    left: 0px;
                    width: 10000px;
                    position: absolute;
                    .transition(all .65s cubic-bezier(.42,.97,.52,1.49));
                    li {
                        display: inline-block;
                        max-height: 153px;
                        padding-right: 3px;
                        box-sizing: border-box;
                        .slide_box {
                            .slide_wrapper {
                                img {
                                    width: auto;
                                    height: 153px;
                                }
                            }
                        }
                        &:last-child {
                            padding-right: 0px;
                        }
                    }
                }
                &.slider_controls {
                    z-index: 1;
                    position: relative;
                    li {
                        top: 57px;
                        color: #fff;
                        z-index: 10;
                        height: 35px;
                        width: 35px;
                        color: #f8f8fa;
                        cursor: pointer;
                        font-size: 16px;
                        line-height: 36px;
                        border-radius: 50%;
                        position: absolute;
                        text-align: center;
                        list-style-type: none;
                        border: 2px solid #fff;
                        background-color: rgba(0,0,0, 0.5);
                        &.next {
                            right: 20px;
                        }
                        &.prev {
                            left: 20px;
                            -webkit-transform: rotate(-180deg);
                            transform: rotate(-180deg);
                        }
                        &.disabled {
                            opacity: 0.1;
                            transition: opacity 250ms;
                        }
                    }
                }
            }
            div {
                &.imgbox {
                    display: none;
                    position: fixed;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 757px;
                    z-index: 150000;
                    .close-button {
                        position: absolute;
                        right: -35px;
                        top: -35px;
                        z-index: 5;
                        padding: 18px;
                        box-sizing: border-box;
                        line-height: 0;
                        color: #53AD29;
                        font-size: 28px;
                        position: absolute;
                        background-color: #fff;
                        border-radius: 50%;
                    }
                    .imgbox-content-wrapper {
                        display: flex;
                        width: 879px;
                        justify-content: center;
                        align-items: center;
                        iframe {
                            background: white;
                        }
                        .imgbox-content {
                            text-align: center;
                            position: relative;
                            img {
                                max-width: 879px;
                            }
                        }
                        .imgbox-arrows {
                            position: absolute;
                            width: 100%;
                            z-index: 5;
                            .next-arrow, .prev-arrow {
                                top: 40%;
                                right: -15%;
                                float: right;
                                position: relative;
                                transform: rotate(90deg);
                                font-size: 50px;
                            }
                            .prev-arrow {
                                transform: rotate(270deg);
                                right: 0;
                                left: -15%;
                                float: left;
                            }
                        }
                    }
                }
                &.mask {
                    display: none;
                    opacity: 0.8;
                    width: 100%;
                    height: 1749px;
                    position: fixed;
                    left: 0px;
                    top: 0px;
                    background: #000;
                    z-index: 11;
                }
                &.block {
                    display: block;
                    -webkit-animation: fade .3s linear;
                    -moz-animation: fade .3s linear;

                }
                &.media-loder {
                    display: none;
                    width: 75%;
                    margin: auto;
                    left: 25%;
                    right: 0px;
                    &.block {
                        display: block;
                    }
                    .gif-loader-wrapper {
                        background: white;
                        height: 200px;
                        width: 300px;
                        border-radius: 5px;
                        margin: auto;
                        .gif-loader {
                            height: 100%;
                            width: 100%;
                            background: url('../../assets/graphics/loader.gif') no-repeat center;
                        }
                    }
                    .imgbox-content-wrapper {
                        display: none;
                    }
                    .close-button {
                        display: none;
                    }
                }
            }
        }
        .structure-img {
            margin-top: 26px;
            ul {
                width: 499px;
                height: 211px;
                margin: auto;
                box-sizing: border-box;
                padding: 34px 15px 0px 244px;
                background-image: url('../../assets/graphics/unit_dimension.png');
                li {
                    padding: 0px;
                    margin: 0px;
                    height: 35px;
                    font-size: 16px;
                    color: #5A5A5A;
                    font-weight: bold;
                    .flex-display(flex);
                    .align-items(center);
                    .justify-content(space-between);
                    background-color: transparent;
                    &.size {
                        color: #000;
                    }
                }
            }
        }
        .features {
            margin-top: 15px;
            box-sizing: border-box;
            padding: 0px 45px 0px 29px;
            li {
                font-size: 16px;
                font-weight: bold;
                line-height: 40px;
                border-bottom: 1px solid #979797;
            }
        }
        .amenities {
            margin-top: 15px;
            box-sizing: border-box;
            padding: 0px 45px 0px 29px;
            li {
                width: 49%;
                color: #4a4a4a;
                font-size: 15px;
                font-weight: 300;
                line-height: 24px;
                display: inline-block;
                &.head {
                    width: 100%;
                    color: #5A5A5A;
                    display: block;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
            }
        }
        .button-wrap {
            // width: 499px;
            .flex-display(flex);
            box-sizing: border-box;
            padding: 24px 18px 9px 19px;
            span {
                height: 39px;
                width: 317px;
                font-size: 15px;
                font-weight: 500;
                .flex-display(flex);
                .align-items(center);
                border-radius: 3px;
                .justify-content(center);
                color: @primary_color;
                background-color: #fff;
                text-transform: uppercase;
                border: 1px solid @primary_color;
                &.pay-bill {
                    width: 160px;
                    color: #fff;
                    margin-left: 15px;
                    border: 1px solid @call_to_action_color;
                    background-color: @call_to_action_color;
                }
            }
        }
    }
</style>
