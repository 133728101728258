<template>
    <div class="samples">
        <!-- {{ $store.state.facilityInfo }} -->
        <!-- {{ facility }} -->
        <KHeader title="Rental" sub_title="Enter Details" ></KHeader>
        <div class="acc">
            <h3 @click.prevent="mytoggle" :class="{ 'active': isActive }">hello world</h3>
            <p>8 months ago
I have had the pleasure of having a storage unit with Storage Outlet of Escondido for almost three years. The staff is amazing, the property is very well maintained, and I know my belongings are always safe and secure. Thank you so much Javier and Richard for your excellent customer service:)
NESSA RAMIREZ
5 days ago
The place is always clean and the staff is very nice...
Victoria Wilson
6 months ago
Its a very nice and clean storage your belongs are very safe there the people that runs it are very nice and helpful
Cali Warrior
6 months ago
I have Rented storages before, but This one was the Best Experience Ive had. Management was friendly & knowledgeable, prices were good & the facility spotless. I would highly recommend this place to my friends and family.</p>
        </div>
        <div>
            <h2>{{ facility.dba_name }}</h2>
            <h3>{{ facility.address_exact }}</h3>
        </div>
        <div>
            Are you active military?
            <input type="radio" v-model="military" value=0>No
            <input type="radio" v-model="military" value=1>Yes
        </div>
        <a href="#" v-on:click.prevent="setActiveSection('new_section')">Next</a>
        {{ $store.state.rental }}
        <p v-if="$store.state.rental.militarySelected">You selected military</p>
        <div class="key_parent">
            <fieldset>
              <legend>Reset Password (Normal Layout)</legend>
              <input type="text"  @focus="showKeyBoard" data-layout="normal" />
            </fieldset>
            <fieldset>
              <legend>Compact layout</legend>
              <input type="text" placeholder="Text input" @focus="showKeyBoard" data-layout="compact" />
            </fieldset>
            <fieldset>
              <legend>Numeric layout</legend>
              <input type="text" placeholder="Text input" @focus="showKeyBoard" data-layout="numeric" />
            </fieldset>
        </div>

		<vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField" />
        <KFooter cancel-title="Cancel" done-title="Save" :cancel-action="cancelAction" :done-action="doneAction"></KFooter>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import KHeader from './Header'
import KFooter from './Footer'
import { keyboardMixin } from '../mixins/Keyboard.js'
import Api from '../utils/api.js'
import MTSCRAWebApi from '../utils/MTSCRAWebApi.js'

export default {
  mixins: [keyboardMixin],
  name: 'samples',
  components: {
    KHeader, KFooter
  },
  methods: Object.assign({},
    mapActions({
      setMilitary: 'rental/setMilitary',
      setActiveSection: 'rental/setActiveSection'
    }),
    {
      fieldClassName: function (field) {
        if (!field) {
          return ''
        }
        if ((field.$touched || field.$submitted) && field.$valid) {
          return 'has-success'
        }
        if ((field.$touched || field.$submitted) && field.$invalid) {
          return 'has-danger'
        }
      },
      cancelAction () {
        // alert("You Pressed Cancel");
      },
      doneAction () {
        // alert("You Pressed Save");
      },
      mytoggle () {
        this.isActive = !this.isActive
      }
    }
  ),
  watch: {
    military: function (value) {
      this.$store.dispatch('rental/setMilitary', value == 1)
    }
  },
  data: function () {
    return {
      military: '',
      formstate: {},
      isActive: false,
      readerHealth: [],
      cardData: {},
      model: {
        name: '',
        email: '',
        phone: '',
        department: null,
        comments: '',
        notValidated: '',
        agree: false
      },
      officeHours: this.$store.state.facilityInfo.timings.office_hours
    }
  },
  computed: mapState({
    facility: state => state.facilityInfo
  })
}
</script>

<style lang="less" scoped>
    .acc {
        p {
            display: none;
        }
        h3.active + p {
            display: block;
        }
    }
</style>
