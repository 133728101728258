<template>
    <div class="size_guide">
        <KHeader title="SIZE GUIDE" sub_title="Which unit size is right for you?"></KHeader>

        <div v-for="sizeVideo in sizeGuideVideos" v-if="video == sizeVideo.video_id">
            <transition>
                <div :id="videoClass(sizeVideo.video_id)" :class="videoClass(sizeVideo.video_id)" class="wistia_embed" style="width:960px;height:540px;"></div>
            </transition>
        </div>
        <div class="size-scale-wrapper">
            <ul :class="[sizeClass, slocker]">
                <li
                    is="Unit"
                    v-for="(unit, index) in sizeGuideVideos"
                    :unit="unit"
                    @setVideo="setVideo"
                    @clickedUnitGroup="clickedUnitGroup"
                ></li>
            </ul>
        </div>
        <div class="clear">
        </div>
        <button type="button" @click.prevent="chooseUnit" :class="{ 'disabled' : !unit.available }">{{ btnMessage }}</button>
    </div>
</template>

<script>
import KHeader from '../Header'
import API from '../../utils/api'
import Unit from './Unit'
import { mapGetters, mapActions, mapState } from 'vuex'

const api = new API()
export default {
  name: 'sizeguide',
  components: {
    KHeader,
    Unit
  },
  data: function () {
    return {
      sizes: [],
      sizeGuideVideos: [],
      video: '',
      unit: '',
      btnMessage: ''
    }
  },
  computed: Object.assign(
    {},
    mapState({
      facilityUnits: state => state.facilityUnits,
      selectedSizeUnit: state => state.selectedSizeGuideUnit
    }),
    {
      sizeClass: function () {
        return 'el_' + this.sizes.length
      },
      slocker: function () {
        return this.sizes.includes('Extra Small') ? 'slocker_present' : ''
      }
    }
  ),
  methods: Object.assign({},
    mapActions({
      setActiveSection: 'setActiveSection',
      setSelectedUnit: 'rental/setSelectedUnit',
      setRentalActiveSection: 'rental/setActiveSection',
      setOpenedUnitGroup: 'rental/setOpenedUnitGroup'
    }),
    {
      setVideo: function (vidId) {
        if (this.video) {
          var wistiaVideo = Wistia.api(this.video)
          wistiaVideo.time(0)
          wistiaVideo.pause()
          wistiaVideo.replaceWith(vidId)
          this.video = vidId
        }
      },
      clickedUnitGroup: function (unit) {
        this.unit = unit
        this.updateMessage()
      },
      videoClass: function (video) {
        return 'wistia_async_' + video
      },
      chooseUnit: function () {
        if (this.unit.available) {
          if (this.unit.unit_type_id) {
            const unitGroups = Object.values(this.facilityUnits)
            for (const ug of unitGroups) {
              for (const each_unit of ug) {
                this.setSelectedUnit(each_unit)
                // if (each_unit.unit_id == this.unit.unit_type_id) {
                //     this.setSelectedUnit(each_unit)
                // }
              }
            }
            // this.setRentalActiveSection('hold_unit')
          }
          this.setOpenedUnitGroup(this.unit.size.replace('Extra ', 'X-'))
          this.setActiveSection('rent')
        }
      },
      updateMessage: function () {
        this.btnMessage = this.unit.available ? 'CHOOSE THIS SIZE' : 'Sorry, unfortunately no units available in this size'
      }
    }
  ),
  mounted: function () {
    api.getSizeGuide().then((response) => {
      this.sizeGuideVideos = response.data.result.map(vid => {
        if (this.sizes.includes(vid.size)) {
          vid.isSecond = true
        } else {
          this.sizes.push(vid.size)
          vid.isSecond = false
        }
        return vid
      })
      this.video = this.selectedSizeUnit && this.sizes.includes(this.selectedSizeUnit.size) ? this.selectedSizeUnit.video_id : this.sizeGuideVideos[Math.floor(this.sizeGuideVideos.length / 2)].video_id
    })
  }
}
</script>

<style scoped lang="less">
    @import '../../assets/less/colors';

    // .wistia_vulcan_elem {
    //     animation-duration: 10s;
    //     -webkit-animation-fill-mode: both;
    //     animation-fill-mode: both;
    //     -webkit-animation-name: slideInLeft;
    //     animation-name: slideInLeft;
    //     border: 1px solid red;
    // }
    @keyframes slideInLeft {
      from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
      }

      to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    .wistia_embed {
        margin: 0 auto;
        // position: relative;
        // &:after {
        //    display: block;
        //    content: "";
        //    width: 100%;
        //    height: 100%;
        //    position: absolute;
        //    top: 0;
        //    animation-duration: 10s;
        //     -webkit-animation-fill-mode: both;
        //     animation-fill-mode: both;
        //     -webkit-animation-name: slideInLeft;
        //     animation-name: slideInLeft;
        // }

    }
    .size-scale-wrapper {
        width: 958px;
        height: 97px;
        box-shadow: 0 2px 6px 1px #ccc;
        border: 1px solid #dcdcdc;
        margin: 20px auto;
    }
    ul {
        // display: flex;
        justify-content: center;
        background-image: url('../../assets/graphics/measure_scale.png');
        width: 918px;
        height: 97px;
        background-repeat: repeat-x;
        margin: 0 auto;
        background-position-x: -8px;
        li:last-child {
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        &.el_1 {
            li {
                &:first-child {
                    padding-left: 438px;
                }
            }
            &.slocker_present {
                li {
                    &:first-child {
                        padding-left: 400px;
                    }
                }
            }
        }
        &.el_2 {
            li {
                &:first-child {
                    padding-left: 341px;
                }
            }
            &.slocker_present {
                li {
                    &:first-child {
                        padding-left: 303px;
                    }
                }
            }
        }
        &.el_3 {
            li {
                &:first-child {
                    padding-left: 249px;
                }
            }
            &.slocker_present {
                li {
                    &:first-child {
                        padding-left: 211px;
                    }
                }
            }
        }
        &.el_4 {
            li {
                &:first-child {
                    padding-left: 155px;
                }
            }
            &.slocker_present {
                li {
                    &:first-child {
                        padding-left: 118px;
                    }
                }
            }
        }
        &.el_5 {
            li {
                &:first-child {
                    padding-left: 61px;
                }
            }
            &.slocker_present {
                li {
                    &:first-child {
                        padding-left: 23px;
                    }
                }
            }
        }
        &.slocker_present {
            li {
                &:first-child {
                    width: 124px;
                }
                &:nth-child(2) {
                    padding-left: 102px;
                }
            }
        }
        .slocker {
            width: 187px;
            .size_name {
                min-width: 187px;
            }
        }
    }
    button {
        font-size: 22px;
        display: block;
        width: 477px;
        margin: 0 auto;
        letter-spacing: 4px;
        font-weight: 500;
        border-radius: 5px;
        padding: 30px;
        &.disabled {
            width: 1000px;
            background-color: #ccc !important;
        }
    }
</style>
