<template>
        <section id="facilitymap">
            <embed :src="mapUrl" width="100%" height="100%" showcontrols="false"></embed>
        </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Welcome',
  computed: Object.assign(
    {},
    mapState({
      facility: state => state.facilityInfo
    }),
    {
      mapUrl: function () {
        if (this.facility.facility_map_url.split('.').pop() == 'pdf') {
          return this.facility.facility_map_url + '#view=Fit&toolbar=0&statusbar=1'
        }
        return this.facility.facility_map_url
      }
    }
  )
}
</script>

<style scoped lang="less">
    section {
        float: left;
        width: calc(100%);
        height: calc(100%);
        margin: 0;
    }
</style>
