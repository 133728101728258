<template>
    <div class="my_account_detail">
        <div class="custom_keyboard" v-if="showCustomKeyBoard">
            <div class="keyboard_el_wrapper">
                <ul class="controls">
                    <li><a class="close_button" @click="hideKeyBoard">Close</a></li>
                </ul>
                <ul class="select_wrapper">
                    <li v-for="(i, index) in selectOptions" @click.prevent="setSelectedOption(i, index)" :class="{selected: i.value == selectedOptionValue}">{{ i.text }}</li>
                </ul>
                <ul class="controls">
                    <li><a class="next_button" @click="nextKeyBoardField">Next</a></li>
                </ul>
            </div>
        </div>
        <section class="load_section" v-show="loadingDetails">
            <div  class="container">
                <div class="loader_wrapper">
                    <div class="loader_content">
                        <h3>We are locating your account.</h3>
                        <div class="bar_loader">
                            <span></span>
                        </div>
                    </div>
                </div>
                <trivia></trivia>
            </div>
        </section>

        <section id="paybill_thankyou" v-show="thankyou_page && !pandadoc.isActive && !isShowBrochure">
            <div class="container">
                <div class="loader_wrapper" :class="{ success: payment_success, failed: !payment_success }">
                    <div class="loader_content">
                        <div v-if="payment_loading" class="payment_loading_wrapper">
                            <h3>Payment Processing...</h3>
                            <div class="bar_loader">
                                <span></span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="payment_success">
                                <h3>Congratulations</h3>
                                <h6>Your payment has been made</h6>
                                <div class="status-circle">
                                    <div></div>
                                </div>
                            </div>
                            <div v-else class="failed">
                                <div class="status-circle">
                                    <h3>Oops!</h3>
                                    <h6>Some of your payment did not go through</h6>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="thankyou_unit_container">
                    <div v-if="thankyou_page && !payment_loading" class="thankyou_unit_wrapper">

                        <!-- its for payment success of fail -->
                        <div class="unit_wrapper" v-for="payBill in getPaidUnits(rentalDetails)" v-if="payBill.spaceDetails.prePayDetails && !payBill.spaceDetails.isAutoPayUpdate">
                            <div class="unit_detail_section">
                                <div class="name_wrapper"><h3>{{ hasParking(payBill.spaceDetails.is_parking) }} {{ payBill.spaceDetails.unit_number }}</h3> <p >{{ payBill.spaceDetails.actual_length }}' x {{ payBill.spaceDetails.actual_width }}' , {{ payBill.spaceDetails.city }}, {{ payBill.spaceDetails.state }}</p></div>
                                <div class="status">
                                    <span v-if="payBill.spaceDetails.payment_status == 'success'" class="success">Payment Success</span>
                                    <span v-else class="failed">Payment Failed</span>
                                </div>
                            </div>
                            <div class="paybill_charge_details">
                                <div class="pre_pay_update">
                                    <p class="prepay_data" v-for="balance in payBill.spaceDetails.prePayDetails.balance">
                                        <span class="amt_text">
                                            <span>{{ balance.description }}</span>
                                        </span>
                                        <span class="rate" v-if="balance.total">{{ balance.total|formatAmount }}</span>
                                        <span class="rate" v-else>{{ balance.amount|formatAmount }}</span>

                                    </p>
                                    <p class="prepay_data" v-for="cost in payBill.spaceDetails.prePayDetails.movein_cost">
                                        <span class="amt_text">
                                            <span>{{ appendUnitNumber(cost.costType, payBill.spaceDetails.unit_number, payBill.spaceDetails.is_parking) }}</span>
                                            <span v-if="showDate(cost)">({{ cost.start_date }} to {{ cost.end_date }})</span>
                                        </span>
                                        <span class="rate">{{ cost.amount|formatAmount }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="total_amount" v-if="payBill.spaceDetails.prePayDetails">
                                <span>Total Payment made</span>
                                <span>{{ payBill.spaceDetails.prePayDetails.total_amt|formatAmount }}</span>
                            </div>
                        </div>

                        <!-- its for autopay enrole and cancell both eror and success case -->
                        <!-- autopay_success
                        card_update
                        card_update_success
                        enrolle_autopay   these are the flag set for testing -->
                        <div class="unit_wrapper autopay" v-for="payBill in getPaidUnits(rentalDetails)" v-if="payBill.spaceDetails.isAutoPayUpdate">
                            <div class="unit_detail_section">
                                <div class="name_wrapper"><h3>{{ hasParking(payBill.spaceDetails.is_parking) }} {{ payBill.spaceDetails.unit_number }}</h3> <p >{{ payBill.spaceDetails.actual_length }}' x {{ payBill.spaceDetails.actual_width }}' , {{ payBill.spaceDetails.city }}, {{ payBill.spaceDetails.state }}</p></div>
                                <div class="status">
                                    <span class="success" v-if="payBill.spaceDetails.payment_status == 'success'">{{getStatusMessage(payBill.spaceDetails)}}</span>
                                    <span class="failed" v-else>{{getStatusMessage(payBill.spaceDetails)}}</span>
                                </div>

                               <!--  <div v-if="autopay_success">
                                    <div class="status">
                                        <span class="success" v-if="card_update_success">Card Updated Successful</span>
                                        <span class="failed" v-else>Card Updated Failed</span>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="status" v-if="autopay_success">
                                        <span class="success" v-if="enrolle_autopay">Enrolled in Autopay</span>
                                        <span class="success" v-else>Autopay Cancelled</span>
                                    </div>
                                    <div class="status" v-else>
                                        <span class="failed" v-if="enrolle_autopay">Enrolled in Autopay Failed</span>
                                        <span class="failed" v-else>Autopay Cancel Failed</span>
                                    </div>
                                </div> -->

                            </div>
                            <div class="paybill_charge_details">
                                <div class="pre_pay_update">
                                    <h3>{{payBill.spaceDetails.facility_name}}</h3>
                                    <p>{{payBill.spaceDetails.address}}</p>
                                    <h3>{{ payBill.spaceDetails.actual_length }}' x {{ payBill.spaceDetails.actual_width }}' {{ hasParking(payBill.spaceDetails.is_parking) }}</h3>
                                    <p>{{ hasParking(payBill.spaceDetails.is_parking) }} {{ payBill.spaceDetails.unit_number }}</p>
                                    <p>{{payBill.spaceDetails.unit_description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="payment_success">
                 <KFooter v-if="!payment_loading" cancel-title="make another payment" done-title="log out" @cancelAction="anotherPayment" @doneAction="logout"></KFooter>
            </div>
            <div v-else>
                 <KFooter v-if="!payment_loading" cancel-title="cancel" done-title="use a different card" @cancelAction="anotherPayment" @doneAction="anotherPayment"></KFooter>
            </div>
        </section>

        <section id="swipe_scan" v-show="swipe_scan">
            <KHeader title="Payment" sub_title="Please swipe or scan your credit card"></KHeader>
            <div class="container">
                <img class="swipe-img" src="../../assets/graphics/payment.png" alt="payment"/>
                <div class="card_separator">
                    <div class="card-border"></div>
                    <div class="card-text">OR</div>
                    <div class="card-border"></div>
                </div>
                <a class="action-link" @click.prevent="enterCardDetails">ENTER CREDIT CARD MANUALLY</a>
            </div>
        </section>

        <section id="paybill_myaccount"  :class="{ thankyou_page: thankyou_page }" v-if="!pandadoc.isActive && !isShowBrochure">
            <KHeader title="PAY MY BILL" sub_title=""></KHeader>
            <div class="container">
                <div class="unit_listing_wrapper">
                    <UnitPayment
                        v-for="(rental, index) in rentalDetails"
                        v-if="paybill_myaccount && !getPrimaryDetails(rental.tenantDetails).res_id"
                        :space="rental.spaceDetails"
                        :tenant="getPrimaryDetails(rental.tenantDetails)"
                        :key="index"
                        @updateUnitPaymentInfo="updateUnitPaymentInfo"
                        @cancelAutoPay="cancelAutoPay"
                    ></UnitPayment>
                    <div class="total gross" :class="{grey: !totalAmount}" v-if="paybill_myaccount || card_details">
                        <p>Total to be charged <span class="rate gross_balance">{{ totalAmount | formatAmount }}</span></p>
                    </div>
                    <div class="form-fields payment_info_section" v-if="card_details">
                        <h2>Payment information</h2>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Card Name</label>
                                <span :class="{ error: errors.has('cc_name') }" v-show="errors.has('cc_name')">{{ errors.first('cc_name') }}</span>
                                <input :class="{ input_error: errors.has('cc_name') }" type="text" placeholder="Name On Card" name="cc_name" v-validate="'required'" v-model="model.card_name"
                                @focus="showKeyBoard" data-layout="normal" />
                            </div>
                            <div class="form_field_align card-name">
                                <label class="show_in_keyboard">Card Number</label>
                                <span :class="{ error: errors.has('credit_card') }" v-show="errors.has('credit_card')">{{ errors.first('credit_card') }}</span>
                                <input :class="{ input_error: errors.has('credit_card') }" type="text" v-mask="creditCardFormatter"  placeholder="XXXX XXXX XXXX XXXX" name="credit_card" v-validate="'required|credit_card'" v-model="model.card_number" @focus="showKeyBoard" data-layout="numeric"/>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align card-month form_select">
                                <label class="show_in_keyboard">Credit Card Expiry Month</label>
                                <span :class="{ error: errors.has('cc_month') }" v-show="errors.has('cc_month')">invalid expiry date</span>
                                <span class="error" v-if="date_expired">invalid expiry date</span>
                                <select @click="showSelectKeyBoard" :class="{ input_error: errors.has('cc_month'), input_error: date_expired }" name="cc_exp_month" id="cc_exp_month" v-validate="'required'" v-model="model.cc_exp_month">
                                    <option :value="month.value" v-for="month in options.months">{{ month.text|formatCardMonth }}</option>
                                </select>
                            </div>
                            <div class="form_field_align card-year form_select">
                                <label class="show_in_keyboard">Credit Card Expiry Year</label>
                                <span :class="{ error: errors.has('cc_year') }" v-show="errors.has('cc_year')">invalid expiry date</span>
                                <select @click="showSelectKeyBoard" :class="{ input_error: errors.has('cc_year'), input_error: date_expired }" name="cc_exp_year" id="cc_exp_year" v-validate="'required'" v-model="model.cc_exp_year">
                                    <option :value="year.value" v-for="year in options.years">{{ year.text }}</option>
                                </select>
                            </div>
                            <div class="form_field_align card-cvv">
                                <label class="show_in_keyboard">CVV</label>
                                <span :class="{ error: errors.has('cc_cvv') }" v-show="errors.has('cc_cvv')">{{ errors.first('cc_cvv') }}</span>
                                <input type="password" :class="{ input_error: errors.has('cc_cvv') }" name="cc_cvv" placeholder="CVV" v-validate="{ required: true, validate_cvv: model.card_number }" :maxlength="cvvLength" v-model="model.cvv"
                                @focus="showKeyBoard" data-layout="numeric" autocomplete="new-password" />
                            </div>
                            <div class="form_field_align img_wrapper">
                                <img src="../../assets/graphics/cc_image.png">
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <KFooter v-if="(paybill_myaccount || card_details) && !thankyou_page" cancel-title="" done-title="Make a payment"  @doneAction="swipeCardDetails"></KFooter>
        </section>

        <section id="unit_side_bar" v-show="side_panel && !pandadoc.isActive">
            <div class="head">
                <span>My Rental</span>
                <span class="link-rent" v-if="rentalDetails.length == 1" v-on:click.prevent="setActiveSection('link_rental')">Link a Rental</span>
                <div class="rental_head_select" v-else>
                    <select v-model="selectunit_number" class="default_select">
                        <option :value="rental.spaceDetails.unit_number" v-for="rental in rentalDetails" v-if="rental.spaceDetails.leaseId">
                        {{ hasParking(rental.spaceDetails.is_parking) }} {{ rental.spaceDetails.unit_number }}, {{ rental.spaceDetails.city }}</option>
                        <option :value="1" v-on:click.prevent="setActiveSection('link_rental')">Link a Rental</option>
                    </select>
                </div>
            </div>
            <RentUnitDetails
                v-for="(rental, index) in rentalDetails"
                :space="rental.spaceDetails"
                :tenant="getPrimaryDetails(rental.tenantDetails)"
                :key="index"
                @showBrochure="showBrochure"
                v-if="showSelect(rental.spaceDetails.unit_number) && !getPrimaryDetails(rental.tenantDetails).res_id"
            ></RentUnitDetails>
        </section>

        <NonInteractiveAgreements v-if="isShowBrochure" show-close-button="true" :selectedDocumentIndex="selected_document" :userDocuments="currentUnitDocs" @closeAction="closeAction"></NonInteractiveAgreements>

        <section id="sign_docs" v-show="pandadoc.isActive" :class="{loading: pandadoc.isLoading}">
            <div class="loader_wrapper">
                <div class="loader"></div>
            </div>
            <header class="pandadoc_header">
                <h1>Sign documents</h1>
                <ul class="agreements_list">
                    <li v-for="(doc, index) in pandadoc.agreements" :class="{ active: index==pandadoc.selectedIndex, 'completed': doc.completed }">{{ doc.display_name }}</li>
                </ul>
            </header>
            <div class="container">
                <div class="document_list">
                    <iframe  v-for="(doc, index) in pandadoc.agreements" v-show="index==pandadoc.selectedIndex" :src="doc.url"></iframe>
                </div>
            </div>'
        </section>
        <vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField"/>
    </div>
</template>

<script>
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import MaskedInput from 'vue-masked-input'
import VueScrollTo from 'vue-scrollto'
import { mapGetters, mapActions, mapState } from 'vuex'
import KHeader from '../Header'
import KFooter from '../Footer'
import Trivia from '../Trivia'
import API from '../../utils/api'
import RentUnitDetails from './RentUnitDetails'
import { MONTHS, YEARS } from '../../utils/constants'
import { keyboardMixin } from '../../mixins/Keyboard.js'
import { PhoneMixin } from '../../mixins/phone.js'
import UnitPayment from './UnitPayment'
import MTSCRAWebApi from '../../utils/MTSCRAWebApi.js'
import NonInteractiveAgreements from '../NonInteractiveAgreements'

import VuejsDialog from 'vuejs-dialog'
var cardAPi = new MTSCRAWebApi()
Vue.use(VuejsDialog)

const api = new API()
Vue.use(VueScrollTo)

VeeValidate.Validator.extend('validate_cvv', {
  getMessage: field => 'Invalid CVV',
  validate: (value, ccnum) => {
    if (ccnum[0].startsWith('37') || ccnum[0].startsWith('34')) {
      return (value.length == 3 || value.length == 4)
    }
    return (value.length == 3)
  }
})

export default {
  name: 'details',
  data: function () {
    return {
      selected_document: 0,
      currentUnitDocs: [],
      isShowBrochure: false,
      selectunit_number: '',
      loadingDetails: true,
      resultCount: 0,
      tenantCount: 0,
      userItems: [],
      rentalDetails: [],
      paybill_myaccount: false,
      card_details: false,
      swipe_scan: false,
      side_panel: false,
      payment_success: false,
      payment_loading: false,
      thankyou_page: false,
      autopay_success: true,
      enrolle_autopay: true,
      card_update: false,
      card_update_success: true,
      date_expired: false,
      pandadoc: {
        isLoading: true,
        isActive: false,
        agreements: [],
        selectedIndex: 0,
        completedDoc: ''
      },
      model: {
        card_name: '',
        card_number: '',
        cc_exp_month: '',
        cc_exp_year: '',
        cvv: ''
      },
      unitPaymentInfo: {},
      options: {
        months: MONTHS,
        years: YEARS
      },
      selectedUnitCount: 0,
      successCount: 0,
      responseCount: 0,
      cvvLength: 3
    }
  },
  mixins: [keyboardMixin, PhoneMixin],
  components: {
    Trivia,
    KHeader,
    KFooter,
    MaskedInput,
    RentUnitDetails,
    UnitPayment,
    NonInteractiveAgreements
  },
  computed: Object.assign(
    {},
    mapState({
      totalAmount: state => state.paybill.totalAmount,
      capabilities: state => state.capabilities
    }),
    {
      creditCardFormatter: function () {
        if (this.model.card_number.startsWith('37') || this.model.card_number.startsWith('34')) {
          this.cvvLength = 4
          return '#### ###### #####'
        } else {
          this.cvvLength = 3
          return '#### #### #### ####'
        }
      }
    }
  ),
  methods: Object.assign(
    {},
    mapActions({
      setActiveSection: 'paybill/setActiveSection',
      getUserItems: 'getUserItems',
      logout: 'logout',
    }),
    {
      showBrochure: function (docs, index) {
        this.isShowBrochure = true
        this.side_panel = false
        this.currentUnitDocs = []
        this.selected_document = index
        var self = this
        docs.forEach(function (i) {
          self.currentUnitDocs.push({
            url: i.location,
            document_type: i.type,
            display_name: i.name
          })
        })
        this.setActiveSection('non_interactive_agreements')
      },
      closeAction: function () {
        this.isShowBrochure = false
        this.side_panel = true
        this.setActiveSection('pay')
      },
      getPrimaryDetails: function (tenantContacts) {
        return tenantContacts.filter(
          contact => contact.type === 'primary'
        )[0]
      },
      getPaidUnits: function (rentalDetails) {
        const paidUnits = rentalDetails.filter(details => {
          // return details.spaceDetails.prePayDetails ? true : false
          if (details.spaceDetails.prePayDetails) {
            return true
          } else {
            if (details.spaceDetails.isAutoPayUpdate) {
              return true
            } else {
              return false
            }
          }
        })
        return paidUnits
      },
      showDate: function (charge) {
        return !!(charge.start_date && charge.end_date)
      },
      appendUnitNumber: function (costType, unit_number, parking) {
        // console.log("Unit number is===",costType, unit_number, parking)
        if (costType == 'Monthly Rent') {
          if (parking) {
            return `${costType} (Space ${unit_number})`
          } else {
            return `${costType} (Unit ${unit_number})`
          }
        }
        return costType
      },
      fetchDetails: function () {
        for (const item of this.userItems) {
          const facility = item.facility
          const tenants = item.tenants
          this.tenantCount += tenants.length
          for (const tenant of tenants) {
            api.getUserItemDetails(facility, tenant).then(response => {
              this.resultCount += 1
              const result = response.data.result
              if (result.space_details) {
                for (const details of result.space_details) {
                  this.rentalDetails.push({
                    tenantDetails: result.tenant_details,
                    spaceDetails: details
                  })
                }
              }
            }).catch(error => {
              this.resultCount += 1
            })
          }
        }
      },
      swipeCardDetails: function () {
        const unitPaymentComponents = this.$children.filter(vueComponent => vueComponent.$el.classList ? vueComponent.$el.classList.contains('unit_wrapper') : false)
        // Unitpayment components are not available in payment page. Use card_details instead
        const loadNext = unitPaymentComponents.length ? unitPaymentComponents.map(vc => vc.isSelected).includes(true) : this.card_details
        if (loadNext) {
          if (this.card_details) {
            this.submitPaymentInfo()
          } else {
            this.paybill_myaccount = false
            this.swipe_scan = true
            cardAPi.getDeviceHealth().then(response => {
              if (response.statusText == 'OK') {
                this.requestCardSwipe()
              }
            })
          }
        }
      },
      enterCardDetails: function () {
        this.swipe_scan = false
        this.paybill_myaccount = false
        this.card_details = true
        this.thankyou_page = false
        var options = {
          container: '.unit_listing_wrapper'
        }
        this.$scrollTo('.payment_info_section', 5000, options)
      },
      anotherPayment: function () {
        this.payment_success = false
        this.loadingDetails = true
        this.thankyou_page = false
        this.side_panel = false
        this.pandadoc.agreements = ''
        this.pandadoc.selectedIndex = 0
        this.pandadoc.isLoading = true
        this.rentalDetails = []
        this.fetchDetails()
      },
      requestCardSwipe: function () {
        cardAPi.getCardSwipe().then(response => {
          this.getDecryptedCardData(response.data)
        })
      },
      getDecryptedCardData: function (request) {
        api.postCardSwipe(JSON.stringify(request)).then(response => {
          if (response.data.status == 'ok') {
            var cardData = response.data.result.DecryptedCardSwipe
            this.model.card_name = cardData.name
            this.model.card_number = cardData.card_number
            this.model.cc_exp_month = parseInt(cardData.exp_month)
            this.model.cc_exp_year = '20' + cardData.exp_year
            this.enterCardDetails()
          }
        })
      },
      updateUnitPaymentInfo: function (key, model) {
        this.unitPaymentInfo[key] = model
      },
      cancelAutoPay: function (unit) {
        this.thankyou_page = true
        this.payment_loading = true
        this.paybill_myaccount = false
        this.card_details = false
        const selectedUnit = this.getSelectedUnitDetails(unit)
        selectedUnit.spaceDetails.isAutoPayUpdate = true
        selectedUnit.spaceDetails.isCancelAutoPay = true
        api.cancelAutoPay(unit).then(response => {
          selectedUnit.spaceDetails.payment_status = 'success'
          this.payment_loading = false
          this.payment_success = true
        }).catch(error => {
          this.payment_loading = false
          selectedUnit.spaceDetails.payment_status = 'error'
        })
      },
      getStatusMessage: function (details) {
        if (details.payment_status == 'success') {
          if (details.isCancelAutoPay) { return 'Autopay Cancelled' } else {
            return details.updateCardDetails ? 'Card Update Successful' : 'Autopay Enroll Successful'
          }
        } else {
          if (details.isCancelAutoPay) { return 'Autopay Cancel failed' } else {
            return details.updateCardDetails ? 'Card Update Failed' : 'Autopay Enroll Failed'
          }
        }
      },
      submitPaymentInfo: function () {
        // filter out selected units from array of model datas
        this.$validator.validateAll().then(result => {
          if (result && !this.date_expired) {
            this.clearPaymentStatus()
            this.thankyou_page = true
            this.payment_loading = true
            this.paybill_myaccount = false
            this.card_details = false

            const selectedUnits = Object.values(this.unitPaymentInfo).filter(unit => {
              return unit.payMyBill || unit.enroll_autopay
            })
            this.selectedUnitCount = selectedUnits.length
            const autoPayEnrolledUnits = []
            for (const unit of selectedUnits) {
              if (unit.enroll_autopay) {
                autoPayEnrolledUnits.push(unit)
              } else {
                // payment
                this.payBill(this.generatePaymentModel(unit))
              }
            }
            if (autoPayEnrolledUnits.length) {
              this.generateAutoPayAgreements(autoPayEnrolledUnits)
              this.pandadoc.isActive = true
            }
          }
        })
      },
      autoPayMailReq: function (unit) {
        console.log('unit', unit, 'document_data', document_data)
      },
      payBill: function (unit) {
        if (!unit.payMyBill) unit.total_amount = 0
        this.card_details = false
        const selectedUnit = this.getSelectedUnitDetails(unit)
        let paymentWithAutopay = false
        selectedUnit.spaceDetails.prePayDetails = unit.prePayDetails
        if (unit.payMyBill && (unit.enroll_autopay || unit.autoPayChecked || unit.updateCardDetails)) {
          const charges = unit.prePayDetails
          if (charges.balance) {
            const total_balance_amt = charges.balance.map(a => a.amount).reduce(function (a, b) { return a + b })
            unit.prePayDetails.total_amt = total_balance_amt || unit.prePayDetails.total_amt
            unit.period = '0'
            paymentWithAutopay = !!total_balance_amt
          } else {
            unit.payMyBill = false
          }
        }
        if (!unit.payMyBill || unit.updateCardDetails) {
          selectedUnit.spaceDetails.isAutoPayUpdate = true
          selectedUnit.spaceDetails.updateCardDetails = unit.updateCardDetails
          api.enrollAutoPay(unit).then(response => {
            const requestData = {
              tenantId: unit.tenant_id,
              facilityId: unit.facility_gds_id,
              data: {
                source: 'desktop',
                request: {
                  spaceId: unit.space_id,
                  documents: response.data.result.documents
                },
                response: response.data.result.response,
                unit_no: unit.unit_door_number
              }
            }
            this.successCount += 1
            this.responseCount += 1
            api.autoPaymentMail(requestData)
            selectedUnit.spaceDetails.payment_status = 'success'
          }).catch(error => {
            this.responseCount += 1
            selectedUnit.spaceDetails.payment_status = 'error'
          })
        } else if (paymentWithAutopay) {
          api.payBill(unit).then(response => {
            const requestPayBillData = {
              tenantId: unit.tenant_id,
              facilityId: unit.facility_gds_id,
              data: {
                source: 'desktop',
                balance: unit.prePayDetails.mail_request_data.current,
                prepay: unit.prePayDetails.mail_request_data,
                payment: response.data.result.mail_request_data,
                unit_no: unit.unit_door_number
              }
            }
            api.enrollAutoPay(unit).then(response => {
              const requestData = {
                tenantId: unit.tenant_id,
                facilityId: unit.facility_gds_id,
                data: {
                  source: 'desktop',
                  request: {
                    spaceId: unit.space_id,
                    documents: response.data.result.documents
                  },
                  response: response.data.result.response,
                  unit_no: unit.unit_door_number
                }
              }
              this.successCount += 1
              this.responseCount += 1
              if (! this.capabilities?.pmsNotifications?.payment)
                api.paymentMail(requestPayBillData)
              api.autoPaymentMail(requestData)
              selectedUnit.spaceDetails.updateCardDetails = unit.updateCardDetails
              selectedUnit.spaceDetails.isAutoPayUpdate = true
              selectedUnit.spaceDetails.payment_status = 'success'
            }).catch(error => {
              this.successCount += 1
              this.responseCount += 1
              selectedUnit.spaceDetails.payment_status = 'error'
            })
          }).catch(error => {
            this.responseCount += 1
            selectedUnit.spaceDetails.payment_status = 'error'
          })
        } else {
          api.payBill(unit).then(response => {
            const requestData = {
              tenantId: unit.tenant_id,
              facilityId: unit.facility_gds_id,
              data: {
                source: 'desktop',
                balance: unit.prePayDetails.mail_request_data.current,
                prepay: unit.prePayDetails.mail_request_data,
                payment: response.data.result.mail_request_data,
                unit_no: unit.unit_door_number
              }
            }
            this.successCount += 1
            this.responseCount += 1
            if (! this.capabilities?.pmsNotifications?.payment)
                api.paymentMail(requestData)
            selectedUnit.spaceDetails.payment_status = 'success'
          }).catch(error => {
            this.responseCount += 1
            selectedUnit.spaceDetails.payment_status = 'error'
          })
        }
      },
      getSelectedUnitDetails: function (unit) {
        return this.rentalDetails.filter(u => u.spaceDetails.unit_number == unit.unit_door_number)[0]
      },
      clearPaymentStatus: function () {
        this.rentalDetails = this.rentalDetails.map(details => {
          details.spaceDetails.payment_status = null
          details.spaceDetails.prePayDetails = null
          return details
        })
      },
      generatePaymentModel (unitModel) {
        return Object.assign({}, unitModel, this.model)
      },
      generateAutoPayAgreements: function (units) {
        var self = this
        var commonReqObj = {
          tenant_email: this.$store.state.userEmail,
          flow: 'autoDebit',
          no_secondary_contact: true,
          cc_number: this.model.card_number,
          name_on_card: this.model.card_name,
          cc_exp_month: this.model.cc_exp_month,
          cc_exp_year: this.model.cc_exp_year,
          cvv: this.model.cvv
        }
        units.forEach(function (el) {
          var req = Object.assign({}, commonReqObj, el)
          api.getPandaTokens(req).then(response => {
            var tokens = response.data.result.dynamic_tokens
            var docReq = Object.assign({}, req, { dynamic_tokens: JSON.stringify(tokens) })
            api.getPandaAgreements(docReq, 'autoDebit').then(docRes => {
              self.pandadoc.isLoading = false
              docRes.data.result.agreements.forEach(function (doc) {
                doc.display_name = doc.display_name + ' - ' + el.unit_door_number
                doc.paymentData = req
                doc.completed = false
                var document_data = [{
                  type: 'autopay',
                  name: 'Auto Debit Enrollment',
                  location: doc.url
                }]
              })
              self.pandadoc.agreements = [...self.pandadoc.agreements, ...docRes.data.result.agreements]
            })
          })
        })
      },
      showSelect: function (optvalue) {
        var self = this
        if (self.selectunit_number == 1) {
          self.setActiveSection('link_rental')
        } else {
          if (optvalue == self.selectunit_number) {
            return true
          }
        }
      },
      validateCreditCardMonthYear: function (m, y) {
        var cMonth = new Date().getMonth() + 1
        var cYear = new Date().getFullYear()
        if (y < cYear) {
          this.date_expired = true
        } else {
          if ((y == cYear) && (m < cMonth)) {
            this.date_expired = true
          }
        }
      },
      hasRentals: function () {
        for (var details of this.rentalDetails) {
          if (details.spaceDetails && details.spaceDetails.leaseId) {
            return true
          }
        }
        return false
      },
      hasParking: function (parking) {
        return parking ? 'Space' : 'Unit'
      }

    }
  ),
  watch: {
    responseCount: function (count) {
      if (count == this.selectedUnitCount) {
        this.payment_loading = false
        if (this.successCount == this.selectedUnitCount) { this.payment_success = true }
        this.responseCount = 0
        this.successCount = 0
      }
    },
    resultCount: function (val) {
      if (val == this.tenantCount) {
        if (this.hasRentals()) {
          this.loadingDetails = false
          this.side_panel = true
          this.paybill_myaccount = true
          this.selectunit_number = this.rentalDetails[0].spaceDetails.unit_number
        } else {
          this.setActiveSection('link_rental')
        }
      }
    },
    'pandadoc.completedDoc': function () {
      var completedDoc = this.pandadoc.agreements[this.pandadoc.selectedIndex]
      completedDoc.completed = true
      completedDoc.paymentData.document_id = this.pandadoc.completedDoc
      // api.payBill(this.generatePaymentModel(completedDoc.paymentData)).then(response => {
      //     this.paybill_myaccount = false
      //     this.card_details = false
      //     this.payment_success = true
      //     this.payment_loading = true
      // })
      this.payBill(this.generatePaymentModel(completedDoc.paymentData))
      if (this.pandadoc.agreements.length - 1 > this.pandadoc.selectedIndex) {
        this.pandadoc.selectedIndex++
      } else {
        this.pandadoc.isActive = false
      }
    },
    'model.cc_exp_month': function () {
      this.date_expired = false
      if (this.model.cc_exp_month && this.model.cc_exp_year) {
        this.validateCreditCardMonthYear(this.model.cc_exp_month, this.model.cc_exp_year)
      }
    },
    'model.cc_exp_year': function () {
      this.date_expired = false
      if (this.model.cc_exp_month && this.model.cc_exp_year) {
        this.validateCreditCardMonthYear(this.model.cc_exp_month, this.model.cc_exp_year)
      }
    }
  },
  mounted: function () {
    var self = this
    this.resultCount = 0
    this.isLoading = true
    var requestedTime = new Date()
    this.getUserItems().then(response => {
      var responseTime = new Date()
      var timeDiff = responseTime - requestedTime
      var delay = (timeDiff > 2000) ? 0 : 2000 - timeDiff
      if (response.status == 'error') {
        setTimeout(function () {
          self.setActiveSection('link_rental')
          self.isLoading = false
        }, delay)
      } else {
        setTimeout(function () {
          if (response.result.length == 0) {
            self.setActiveSection('link_rental')
          } else {
            self.userItems = response.result
            self.fetchDetails()
          }
        }, delay)
      }
    }).catch(error => {
      self.setActiveSection('link_rental')
    })

    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
    window[eventMethod](messageEvent, function (e) {
      if (e && e.data) {
        try {
          var message = JSON.parse(e.data)
          if (message && message.event) {
            if (message.event == 'session_view.document.completed') {
              self.pandadoc.completedDoc = message.data.uuid
            }
          }
        } catch (e) {
        }
      }
    }, false)
  }
}
</script>

<style scoped lang="less">
    @import '../../assets/less/colors';
    @import '../../assets/less/flexbox';
    .my_account_detail {
        #swipe_scan {
            .container {
                height: calc(~'100vh - 195px');
                position:relative;
                .swipe-img {
                    position: relative;
                    left: 173px;
                    top: 110px;
                }
                .card_separator {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 525px;
                    position: relative;
                    top: 150px;
                    left: 155px;
                    font-family: Gotham;
                    font-size: 15px;
                    font-weight: 400;
                    color: #9B9B9B;
                    letter-spacing: 3.07px;
                    text-align: center;
                    .card-border {
                        border: 1px solid #9B9B9B;
                        width: 300px;
                        &:first-child {
                            margin-right: 10px;
                        }
                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                }
                .action-link {
                    position: absolute;
                    bottom: 150px;
                    left: 9vw;
                    font-size: 22px;
                    letter-spacing: 4.5px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: @primary_color;
                }
            }
        }
        #paybill_thankyou {
            .thankyou_unit_container {
                width: 750px;
                margin-left: 90px;
                margin-top: 100px;
                overflow: hidden;
                .thankyou_unit_wrapper {
                    width: 722px;
                    overflow: auto;
                    height: 450px;
                    // height: calc(100vh - 600px);
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .unit_wrapper {
                        margin-bottom: 20px;
                        .unit_detail_section {
                            color: #4A4A4A;
                            height: 70px;
                            padding-left: 21px;
                            padding-right: 36px;
                            background-color: @ma_unit_block_top_bg;
                            .flex-display(@display: flex);
                            .align-items(center);
                            .justify-content(@justify: space-between);
                            .name_wrapper {
                                h3 {
                                    font-size: 16px;
                                    font-weight: bold;
                                    line-height: 18px;
                                }
                                p {
                                    font-size: 14px;
                                    font-weight: 300;
                                    line-height: 18px;
                                }
                            }
                            .status {
                                span {
                                    font-size: 24px;
                                    font-weight: 500;
                                    color: @call_to_action_color;
                                    &:after {
                                        font-family: "fontello";
                                        content: '\e854';
                                        font-size: 20px;
                                        border: 2px solid @call_to_action_color;
                                        border-radius: 50%;
                                        height: 40px;
                                        width: 40px;
                                        display: inline-block;
                                        text-align: center;
                                        line-height: 40px;
                                        margin-left: 20px;
                                    }
                                    &.failed {
                                        color: @red_color;
                                        &:after {
                                            content: '\E81B';
                                            border: 2px solid @red_color;
                                        }
                                    }
                                }
                            }
                        }
                        .paybill_charge_details {
                            width: 722px;
                            padding: 47px 33px;
                            box-sizing: border-box;
                            background-color: @primary_shading_color;
                            .pre_pay_update {
                                p {
                                    color: #5a5a5a;
                                    font-weight: 500;
                                    font-size: 14px;
                                    .flex-display(flex);
                                    .align-items(center);
                                    .justify-content(space-between);
                                    .amt_text {
                                        color: #9A9A9A;
                                        span {
                                            display: block;
                                            &:first-child {
                                                color: #5a5a5a;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .total_amount {
                            width: 722px;
                            height: 50px;
                            color: #fff;
                            font-size: 18px;
                            font-weight: 500;
                            .flex-display(flex);
                            .align-items(center);
                            padding: 0 33px;
                            box-sizing: border-box;
                            background-color: @primary_color;
                            .justify-content(space-between);
                        }
                        &.autopay {
                            .paybill_charge_details {
                                text-align: left;
                                padding: 30px 33px;
                                h3 {
                                    font-size: 16px;
                                    font-weight: bold;
                                    line-height: 20px;
                                    padding-top: 11px;
                                    margin-bottom: 3px;
                                    color: @primary_color;
                                }
                                p {
                                    color: #4A4A4A;
                                    font-size: 14px;
                                    font-weight: 300;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        #paybill_myaccount {
            .container {
                display: flex;
                overflow: hidden;
                position: relative;
                box-sizing: border-box;
                padding: 60px 0 20px 90px;
                height: 100%;
                .unit_listing_wrapper {
                    width: 722px;
                    height: calc(~'100vh - 400px');
                    overflow: auto;
                    position: relative;
                    -webkit-overflow-scrolling: touch;
                    -ms-overflow-style: none;
                    box-sizing: border-box;
                    overflow-x: hidden;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .total {
                        background-color: @call_to_action_color;
                        line-height: 50px;
                        padding-left: 21px;
                        padding-right: 24px;
                        &.grey.gross {
                            background-color: #ccc;
                        }
                        p {
                            color: #fff;
                            font-size: 18px;
                            font-weight: 500;
                            span {
                                float: right;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .payment_info_section {
                padding-top: 50px;
                margin-left: 22px;
                h2 {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 25px;
                }
            }
            &.thankyou_page {
                .header_space {
                    height: 0px;
                }
                .container {
                    display: none;
                }
            }
        }
        #unit_side_bar {
            top: -1px;
            width: 398px;
            right: 60px;
            position: absolute;
            background-color: #FFFFFF;
            border: 1px solid #DDDDDD;
            box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.16);
            z-index: 5;
            .head {
                color: #FFFFFF;
                .flex-display(flex);
                .align-items(center);
                .justify-content(space-between);
                padding: 12px 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                border-radius: 0px 0px 3px 3px;
                width: 399px;
                height: 60px;
                box-sizing: border-box;
                background-color: @primary_color;
                .rental_head_select {
                    position: relative;
                    top: 10px;
                    select {
                        width: 225px;
                    }
                    &:after {
                        color: #5a5a5a;
                        font-family: "fontello";
                        content: '\e823';
                        position: absolute;
                        top: 15px;
                        font-size: 8px;
                        right: 15px;
                    }

                }
            }
            .link-rent {
                &:after {
                    content: '\E865';
                    margin-left: 10px;
                    font-family: "fontello";
                }
            }
        }
        #paybill_thankyou {
            .container {
                h6 {
                    font-size: 22px;
                    color: @small_instructions;
                    line-height: 15px;
                    font-weight: 400;
                    margin: 20px 0px 0px;
                }
                .loader_wrapper {
                    background-image: url('../../assets/graphics/confirm_bg.png');
                    background-size: 300px;
                    padding-left: 0px;
                    .loader_content {
                        h3, h6 {
                            padding-left: 90px;
                        }
                    }
                    .bar_loader {
                        margin-left: 90px;
                    }
                    &.failed {
                        background-image: none;
                        .loader_content {
                            padding-left: 0px;
                        }
                        .status-circle {
                            h3 {
                                margin: auto;
                                font-size: 80px;
                                line-height: 92px;
                                padding-top: 55px;
                            }
                            h3, h6 {
                                padding-left: 0px;
                            }
                            div {
                                top: 50px;
                                &:before {
                                    content: '\E81B';
                                }
                            }
                        }
                    }
                    .payment_loading_wrapper {

                    }
                }
                .status-circle {
                    // width: 955px;
                    width: calc(~'100% - 460px');
                    text-align: center;
                    align-items: center;
                    position: relative;
                    div {
                        height: 132px;
                        width: 132px;
                        border-radius: 50%;
                        margin: auto;
                        position: relative;
                        top: 120px;
                        z-index: 2;
                        color: @call_to_action_color;
                        border: 10px solid @call_to_action_color;;
                        background-color: #ffffff;
                        &:before {
                            font-family: "fontello";
                            content: '\e854';
                            font-size: 60px;
                            line-height: 130px;
                        }
                    }
                }
            }
        }
    }
</style>
