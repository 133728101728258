<template>
<div>
		<h1>{{error}}</h1>
</div>
</template>

<script >
export default {
  props: {
    error: {
      type: String,
      default: 'This page is not valid'
    }
  }
}
// methods:{
// 	toHome(){
//            this.router.push({ name: 'home', params: { facId: this.route } })
// 		}
// 	}
</script>
