<template>
    <div class="unit_wrapper">
        <div class="unit_detail_section">
            <div class="name_wrapper">
                <div v-if="autoPay.enabled" class="unit-name">
                    <checkbox type="checkbox" v-model="model.showPayBill">
                        <span>
                            <h3>{{ unitDescriptions }} {{ space.unit_number }}</h3>
                            <p>{{ space.actual_length }}' x {{ space.actual_width }}' {{ space.unit_standard }}, {{ space.city }}, {{ space.state }}</p>
                        </span>
                    </checkbox>
                </div>
                <checkbox type="checkbox" v-else v-model="model.payMyBill" :disabled="disable_pay_check">
                    <span>
                        <h3>{{ unitDescriptions }} {{ space.unit_number }}</h3>
                        <p>{{ space.actual_length }}' x {{ space.actual_width }}' {{ space.unit_standard }}, {{ space.city }}, {{ space.state }}</p>
                    </span>
                </checkbox>
            </div>
            <div class="price_wrapper">
                <h3 data-unit="VT5">{{ due|formatAmount }}</h3>
                <p class="due_date" v-if="autoPay.enabled && !due">Autopay On</p>
                <p class="due_date" v-else-if="!due">No payment is due.</p>
                <p class="due_date due" v-else>PAST DUE</p>
            </div>
        </div>
        <div class="unit_price_wrapper" v-if="model.payMyBill || model.showPayBill">
            <div class="autopay_status">
                <div v-show="!autoPay.enabled">
                    <checkbox type="checkbox" v-model="model.autoPayChecked"><div class="enrolled_text">Enroll in Autopay</div></checkbox>
                    <p class="enroll_block"> <strong>Save</strong> Time &amp; Money!<span class="debit_arrow"></span></p>
                </div>
                <p class="debit_text" v-show="autoPay.enabled">Enrolled in Autopay</p>
            </div>
            <div class="amount_details">
                <div class="autopay_enrolled" v-if="autoPay.enabled">
                    <div class="charge_details">
                        <ul class="check_wrapper">
                            <li class="custom_checkbox cc_update_check">
                                <checkbox type="checkbox" v-model="model.updateCardDetails"><h3>Update Credit Card Info</h3></checkbox>
                            </li>
                        </ul>
                        <div class="charge_pwrapper">
                            <p v-if="autoPay.paymentInstrument"><strong>Charged to </strong><span>{{ autoPay.paymentInstrument.card.number|maskCardNumber }}</span></p>
                            <!-- <p><strong>Charged on </strong><span>28th of each month</span></p> -->
                            <p v-if="space.billingDate"><strong>Charged on </strong><span>{{ space.billingDate|humanDate|getDate }} of each month</span></p>
                        </div>
                    </div>
                    <a class="autopay_cancel ripple" v-confirm="{ok: cancelAutoPay, cancel: doNothing, message: 'Are you sure want to cancel Autopay?', okText: 'Yes', cancelText: 'No', reverse: true}">Cancel Autopay</a>
                    <div class="clear"></div>
                </div>
                <div class="monthly_rent_section" v-if="!autoPay.enabled">
                    <div class="paybill_monthly_charge">
                        <div class="pre_pay_update load_next_month_due">
                             <p class="prepay_data" v-for="charge in balance" v-if="charge.amount">
                                <span class="amt_text" v-if="charge.costType == 'rent'">
                                    <span>{{ appendUnitNumber(charge.costType) }}</span>
                                    <span v-if="showDate(charge)">({{ charge.start_date }} to {{ charge.end_date }})</span>
                                </span>
                                <span class="amt_text " v-else>
                                    <span>{{ charge.description }}</span>
                                </span>
                                <span class="rate" v-if="charge.total">{{ charge.total|formatAmount }}</span>
                                <span class="rate" v-else>{{ charge.amount|formatAmount }}</span>
                            </p>
                            <p class="prepay_data" v-for="charge in monthlyCharges" v-if="charge.amount">
                                <span class="amt_text " v-if="charge.costType == 'discount'">
                                    <span>{{ charge.description }}</span>
                                </span>
                                <span class="amt_text" v-else>
                                    <span>{{ appendUnitNumber(charge.costType) }}</span>
                                    <span v-if="showDate(charge)">({{ charge.start_date }} to {{ charge.end_date }})</span>
                                </span>
                                <span class="rate">{{ charge.amount|formatAmount }}</span>
                            </p>
                            <p class="prepay_data" v-if="prePayDetails.tax">
                                <span class="amt_text">
                                    <span>Total Tax</span>
                                </span>
                                <span class="rate">{{ prePayDetails.tax|formatAmount }}</span>

                            </p>
                        </div>
                        <div class="bar_loader" v-show="loadingMonthlyCharges">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <ul class="next_payment_wrapper" v-if="!autoPay.enabled && model.payMyBill">
            <li class="drop_down" :style="hideDropDown" >
                <span class="demo-icon icon-select_arrow_down paythrough-icon-arrow"></span>
                <select class="pay_through_date selected default_select" v-model="period">
                    <option value="0" v-if="due">Pay Balance Due</option>
                    <option value="1">Pay for 1 Month</option>
                    <option value="2">Pay for 2 Months</option>
                    <option value="3">Pay for 3 Months</option>
                    <option value="4">Pay for 4 Months</option>
                    <option value="5">Pay for 5 Months</option>
                    <option value="6">Pay for 6 Months</option>
                </select>
            </li>
            <li class="pay_tittle" v-if="!loadingMonthlyCharges">{{ paymentTitle }}</li>
            <li class="date long_date" v-if="!loadingMonthlyCharges"><span v-if="model.period == 1">Due </span>{{ dueDate | humanDate(ms=true) }}</li>
            <li class="amount update_amount" v-if="!loadingMonthlyCharges">{{ prePayDetails.total_amt | formatAmount}}</li>
        </ul>
        <ul class="next_payment_wrapper total_amount_thankyou" v-if="!autoPay.enabled">
            <li class="pay_tittle">Total Payment made</li>
            <li class="amount update_amount" v-if="!loadingMonthlyCharges">{{ prePayDetails.total_amt | formatAmount}}</li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { paybillMixin } from '../../mixins/paybill.js'

export default {
  mixins: [paybillMixin],
  props: ['space', 'tenant'],
  data: function () {
    return {

      dueDate: new Date(),
      paymentTitle: 'Next Payment',
      period: '0',
      model: {
        period: '1',
        space_id: this.space.spaceId,
        total_amount: 0,
        username: this.$store.state.userEmail,
        facility_gds_id: this.space.gds_id,
        facility_id: this.space.facility_id,
        tenant_id: this.tenant.tenant_id,
        billing_address1: this.setFieldValues(this.tenant.address ? this.tenant.address.address1 : ''),
        billing_address2: this.setFieldValues(this.tenant.address ? this.tenant.address.address2 : ''),
        billing_city: this.setFieldValues(this.tenant.address ? this.tenant.address.city : ''),
        billing_state: this.setFieldValues(this.tenant.address ? this.tenant.address.stateCode : ''),
        billing_zipcode: this.setFieldValues(this.tenant.address ? this.tenant.address.postalCode : ''),
        unit_door_number: this.setFieldValues(this.space.unit_number),
        unit_type_id: this.setFieldValues(this.space.unit_id),
        tenant_first_name: this.setFieldValues(this.tenant.name.first),
        tenant_last_name: this.setFieldValues(this.tenant.name.last),
        prePayDetails: {},
        autoPayChecked: false,
        payMyBill: false,
        enroll_autopay: false,
        updateCardDetails: false
      },
      newTotal: 0,
      oldTotal: 0,
      useAmountDiff: false,
      disable_pay_check: false
    }
  },
  computed: {
    autoPay: function () {
      let autoPay = this.space.autoPay
      if (!autoPay) autoPay = { enabled: false }
      return autoPay
    },
    isAutoPay: function () {
      console.log(this.model.autoPayChecked)
      return this.model.autoPayChecked || this.model.updateCardDetails
    },
    isSelected: function () {
      return (this.isAutoPay || this.model.payMyBill) && !this.loadingMonthlyCharges
    },
    unitDescriptions: function () {
      return this.space.is_parking ? 'Space' : 'Unit'
    },
    hideDropDown: function () {
      if (this.model.autoPayChecked || this.model.updateCardDetails) {
        return {
          visibility: 'hidden'
        }
      } else {
        return {
          visibility: 'visible'
        }
      }
    }
  },
  mounted: function () {
    this.period = this.due ? '0' : '1'
    this.clearTotalAmount()
    this.dueDate = this.getBillPaidThroughDate()
    if (this.autoPay.enabled) {
      this.loadingMonthlyCharges = false
      this.monthlyCharges = []
    } else {
      // this.getPrePayDetails(this.due ? '0' : '1')
      const period = this.due ? '0' : '1'
      this.getPrePayDetails(period)
      this.model.period = period
    }
    this.$emit('updateUnitPaymentInfo', this.space.unit_number, this.model)
  },
  watch: {
    model: function (value) {
      this.$emit('updateUnitPaymentInfo', this.space.unit_number, value)
    },
    'model.payMyBill': function (checked) {
      // if unchecked set amount to zero
      // decrease model.total_amount from net total
      if (checked) {
        this.updateTotalAmount(this.getAmountDiff())
        this.useAmountDiff = true
      } else {
        this.updateTotalAmount(-this.model.total_amount)
        this.useAmountDiff = false
      }
    },
    'model.period': function (value) {
      this.getPrePayDetails(value)
      if (value > 1) { this.paymentTitle = 'Pay through' } else { this.paymentTitle = 'Next Payment' }
    },
    isAutoPay: function (value) {
      this.model.enroll_autopay = value
      if (this.due && value) {
        this.model.payMyBill = true
        this.disable_pay_check = true
      }
      if (this.due && !value) {
        this.disable_pay_check = false
      }
    },
    monthlyCharges: function () {
      if (this.model.period == '0' || this.model.period == '1') { this.dueDate = this.getBillPaidThroughDate() } else {
        this.dueDate = new Date(this.monthlyCharges[this.monthlyCharges.length - 1].end_date)
      }
    },
    period: function (value) {
      this.model.period = value
    },
    prePayDetails: function (details, oldDetails) {
      this.model.prePayDetails = details
      this.newTotal = details.total_amt
      this.oldTotal = oldDetails.total_amt
      this.model.total_amount = this.newTotal
      const diff = this.getAmountDiff()
      if (this.model.payMyBill) {
        this.updateTotalAmount(diff)
      }
    },
    'model.updateCardDetails': function (checked) {
      console.log('Due Checked', this.due)
      const self = this
      // console.log("total_amount", this.model.total_amount)
      if (checked && (this.model.enroll_autopay) && (this.due > 0)) {
        console.log('this model total_amount', this.model.unit_door_number)
        const message = 'You currently have a $' + this.due + ' balance due. By updating the credit card information you will automatically be charged the oustanding balanced'
        const options = {
          okText: 'Continue',
          cancelText: 'Cancel'
        }
        this.$dialog.confirm(message, options, checked)
          .then(function () {
            // This will be triggered when user clicks on proceed
          })
          .catch(function () {
            // This will be triggered when user clicks on cancel
            self.model.updateCardDetails = false
          })
      } else {
        console.log('Elseeeeeee')
      }
    }
  },
  methods: Object.assign(
    {},
    mapActions({
      updateTotalAmount: 'paybill/updateTotalAmount',
      clearTotalAmount: 'paybill/clearTotalAmount'
    }),
    {
      appendUnitNumber: function (costType) {
        if (costType == 'Monthly Rent' || costType == 'rent') {
          if (this.space.is_parking) {
            return `Monthly Rent (Space ${this.space.unit_number})`
          } else {
            return `Monthly Rent (Unit ${this.space.unit_number})`
          }
        }
        return costType
      },
      getAmountDiff: function () {
        return this.oldTotal && this.useAmountDiff ? this.newTotal - this.oldTotal : this.newTotal
      },
      showDate: function (charge) {
        return !!(charge.start_date && charge.end_date)
      },
      cancelAutoPay: function () {
        // Do stuffs
        this.$emit('cancelAutoPay', this.model)
      },
      doNothing: function () {
        // Do nothing or some other stuffs
        // console.log('ppppppppppppppppppppppppppllllllllllll');
      },
      setFieldValues: function (value) {
        return value || ''
      }
    }
  )
}
</script>

<style scoped lang="less">
    @import '../../assets/less/colors';
    @import '../../assets/less/flexbox';

    .unit_wrapper {
        width: 722px;
        .name_wrapper {
            .unit-name {
                h3 {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 18px;
                }
                p {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 18px;
                }
            }
        }
        .unit_detail_section {
            color: #4A4A4A;
            height: 70px;
            padding-left: 21px;
            padding-right: 36px;
            background-color: @ma_unit_block_top_bg;
            .flex-display(@display: flex);
            .align-items(center);
            .justify-content(@justify: space-between);
            .price_wrapper {
                font-weight: 500;
                text-align: right;
                h3 {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 500;
                    color: @primary_color;
                }
                p {
                    font-size: 14px;
                    line-height: 18px;
                    &.due {
                        color: @red_color;
                    }
                }
            }
        }
        .unit_price_wrapper {
            margin-bottom: 5px;
            background-color: @primary_shading_color;
            .autopay_status {
                width: 227px;
                padding: 23px 25px;
                box-sizing: border-box;
                display: table-cell;
                vertical-align: top;
                padding-left: 20px;
                background-color: @call_to_action_shading;
                .debit_arrow {
                    display: block;
                    position: absolute;
                    top: -8px;
                    left: 67px;
                    border-bottom: 10px solid @call_to_action_color;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    right: initial;
                }
                .enroll_block {
                    width: 175px;
                    background-color: @call_to_action_color;
                    color: #fff;
                    border-radius: 4px;
                    padding: 9px 44px;
                    margin-top: 11px;
                    box-shadow: 0 2px 6px rgba(0,0,0,.6);
                    text-align: center;
                    position: relative;
                    box-sizing: border-box;
                }
                .enrolled_text {
                    color: @call_to_action_color;
                    font-size: 16px;
                    font-weight: 500;
                }
                .debit_text {
                    font-weight: 500;
                    position: relative;
                    top: -5px;
                    color: @call_to_action_color;
                }
            }
            .amount_details {
                width: 495px;
                display: table-cell;
                vertical-align: top;
                .monthly_rent_section {
                    font-weight: 500;
                    padding: 23px 25px;
                    padding-right: 36px;
                    box-sizing: border-box;
                    color: @common_book_text_color;
                    p {
                        font-size: 14px;
                        .paythrough-icon-arrow {
                            top: -1px;
                            left: 110px;
                            font-size: 7px;
                            position: relative;
                            pointer-events: none;
                        }
                        select {
                            font-size: 14px !important;
                            font-weight: bold;
                            width: 91px;
                            height: 17px;
                            padding: 8px 20px 8px 11px;
                            box-sizing: content-box;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            background-color: #fff;
                            border: 1px solid #c7c7c7;
                            border-radius: 4px;
                            cursor: pointer;
                            color: @common_book_text_color;
                            font-size: 16px;
                            outline: none;
                            margin-left: -5px;
                            margin-bottom: 15px;
                            &.selected {
                                color: @common_book_text_color !important;
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                        &.prepay_data {
                            .flex-display(flex);
                            .align-items(center);
                            .justify-content(space-between);
                            span {
                                display: inline-block;
                                width: 339px;
                                &.amt_text {
                                    color: #9A9A9A;
                                    text-transform: capitalize;
                                    span {
                                        display: block;
                                        &:first-child {
                                            color: #5a5a5a;
                                        }
                                    }
                                }
                                &.rate {
                                    width:107px;
                                    float: none;
                                    text-align: right;
                                }
                            }
                        }
                    }
                    .bar_loader {
                        margin: auto;
                        height: 15px;
                        margin-top: 20px;
                        border-radius: 13px;
                        width: 60%;
                    }
                }
                .autopay_enrolled {
                    color: @common_book_text_color;
                    position: relative;
                    line-height: 20px;
                    padding: 30px 25px;
                    padding-top: 22px;
                    .charge_details {
                        width: 328px;
                        display: inline-block;
                        .check_wrapper {
                            margin-bottom: 5px;
                        }
                        .charge_pwrapper {
                            padding-top: 15px;
                        }
                    }
                    p {
                        color: @common_book_text_color;
                        font-size: 14px;
                    }
                    .update_cc {
                        font-weight: 500;
                        line-height: 22px;
                        color: @common_text_color_dark;
                    }
                    .autopay_cancel {
                        float: right;
                        font-size: 12px;
                        font-weight: 500;
                        color: #fff;
                        background-color: #989898;
                        border-radius: 3px;
                        padding: 4px 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .next_payment_wrapper {
            width: 100%;
            height: 50px;
            .flex-display(@display: flex);
            .align-items(center);
            background-color: @primary_color;
            padding: 10px;
            padding-right: 27px;
            box-sizing: border-box;
            margin-bottom: 8px;
            color: #fff;
            li {
                display: inline-block;
                &.pay_tittle {
                    font-size: 18px;
                    font-weight: 500;
                    width: 153px;
                }
                &.date {
                    font-size: 14px;
                    font-weight: 500;
                    width: 206px;
                    text-align: left;
                }
                &.amount {
                    width: 90px;
                    text-align: right;
                    font-weight: 500;
                    font-size: 18px;
                    margin-left: auto;
                }
                &.drop_down {
                    font-size: 14px;
                    position: relative;
                    .flex-display(@display: flex);
                    .align-items(center);
                    .paythrough-icon-arrow {
                        top: 12px;
                        left: 171px;
                        color: #5a5a5a;
                        font-size: 7px;
                        position: absolute;
                        pointer-events: none;
                    }
                    select {
                        font-size: 14px !important;
                        font-weight: bold;
                        width: 161px;
                        height: 18px;
                        padding: 5px 20px 5px 11px;
                        box-sizing: content-box;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: #fff;
                        border: 1px solid #c7c7c7;
                        border-radius: 4px;
                        cursor: pointer;
                        color: @common_book_text_color;
                        font-size: 16px;
                        outline: none;
                        margin-bottom: 0px;
                        margin-right: 25px;
                        &.selected {
                            color: @common_book_text_color !important;
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }
            }
            &.total_amount_thankyou {
                display: none;
                .align-items(center);
                .justify-content(space-between);
            }
        }
        &.payment_success {
            .unit_detail_section .price_wrapper {
                display: none;
            }
            .unit_price_wrapper {
                margin-bottom: 0px;
                .autopay_status {
                    display: none;
                }
                .amount_details {
                    width: 722px;
                }
            }
            .next_payment_wrapper {
                display: none;
                &.total_amount_thankyou {
                    padding-left: 25px;
                    .flex-display(@display: flex);
                    li.pay_tittle {
                        width: auto;
                    }
                }
            }
        }
    }
</style>
