<template>
    <div class="space-search">
        <div class="form_row">
            <span class="error" v-if="(spaceSearchResult.length == 0 && spaceSearchActive) && !errors.has('space_search_keyword')">No spaces found</span>
            <div class="search-box">
                <label class="show_in_keyboard">Space Number</label>
                <span class="error" v-if="errors.has('space_search_keyword')">{{ errors.first('space_search_keyword') }}</span>
                <input v-bind:class="{ input_error: errors.has('space_search_keyword') || (spaceSearchResult.length == 0 && spaceSearchActive) }" v-validate="{ required: true }" v-model="model.space_search_keyword" name="space_search_keyword" type="text" placeholder="Space Number" data-vv-as="space number" required @focus="showKeyBoard" data-layout="submit"/>
                <button type="submit" class="ripple" @click.prevent="onSubmit" ><span>FIND THIS SPACE</span></button>
            </div>
        </div>
        <vue-touch-keyboard id="keyboard" :style="getHeaderStyle" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField" :submit="onSubmit"/>
    </div>
</template>

<script>
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { mapState } from 'vuex'
import { keyboardMixin } from '../../mixins/Keyboard.js'
Vue.use(VeeValidate)

export default {
  name: 'SpaceSearch',
  props: ['spaceSearchResult', 'spaceSearchActive'],
  mixins: [keyboardMixin],
  data: function () {
    return {
      model: {
        space_search_keyword: ''
      }
    }
  },
  methods: {
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('spaceNumberSearch', this.model.space_search_keyword)
        }
      })
    }
  },
  computed: {
    ...mapState({
      windowMargin: state => state.windowMargin
    }),
    getHeaderStyle () {
      return {
        top: this.windowMargin
      }
    }
  },
  watch: {
    keyBoardVisible: function () {
      if (this.keyBoardVisible) {
        const width = document.documentElement.clientWidth
        if (width > 1920) {
          setTimeout(function () {
            const keyboard = document.getElementsByClassName('vue-touch-keyboard')[0]
            const inputBox = document.getElementsByClassName('in_focus')[0]
            const keyBoardDelta = (width - 1920) / 2 + 576
            keyboard.style.left = keyBoardDelta + 'px'
            const inputDelta = keyBoardDelta + 87
            inputBox.style.cssText = 'left: ' + inputDelta + 'px !important'
          }, 1)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
    @import '../../assets/less/colors';
    @import '../../assets/less/mixins';
    @import '../../assets/less/flexbox';
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .big_screen {
        .space-search {
            .vue-touch-keyboard {
                position: fixed !important;
                background: rgba(0, 0, 0, 0.7);
                height: 1080px;
                width: 1344px;
                z-index: 2222;
                &#keyboard {
                    &:after {
                        display: none;
                    }
                    .keyboard {
                        width: 1200px;
                        margin: 0px 77px;
                        left: auto;
                        bottom: 20vh;
                    }
                }
            }
        }
    }
    .space-search {
        margin-bottom: 20px;
        position: relative;
        .search-box {
            width: 100%;
            input {
                float: left;
            }
            button {
                .loader {
                    margin: 0px auto !important;
                }
            }
            &.in_focus {
                display: block;
                button.ripple {
                    display: none;
                }
            }
            .error {
                display: block;
            }
        }
        input {
            padding-left: 30px;
            margin: 0px;
            width: 55%;
        }
        button {
            width: 45%;
            text-align: center;
            display: inline-block;
            height: 54.1px;
            box-shadow: none;
            margin: 0;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 2px;
            background-color: @primary_color !important;
            position: relative;
            overflow: hidden;
            z-index: 1;
            &.loading .loader {
                margin: 0px auto;
            }
        }
    }
    .vue-touch-keyboard#keyboard {
        &:after {
            left: 30%;
        }
    }
</style>
