import Vue from 'vue'
import App from './App'
import router from './router'
import VueMeta from 'vue-meta'

import store from './store/store'
import VuejsDialog from 'vuejs-dialog'

import './assets/less/fonts.less'
import './assets/less/fontello.less'
import './assets/less/reset.less'
import './assets/less/keyboard.less'
import './assets/less/main.less'

import { VueMaskDirective } from 'v-mask'
import { formatAmount, formatPhone, toFloat, pluralize, formatCardMonth } from './utils/base'
import VueCurrencyFilter from 'vue-currency-filter'
import VueSession from 'vue-session'
// import VueTouchKeyboard from 'vue-touch-keyboard'

import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueMeta)

Vue.directive('mask', VueMaskDirective)
Vue.use(VueObserveVisibility)

const vkb = require('./utils/vue-touch-keyboard.js')
const eventsHub = new Vue()

Vue.use(VueCurrencyFilter,
  {
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false
  })

Vue.use(VueSession)
Vue.use(VuejsDialog)
Vue.use(vkb)

Vue.directive('loading', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    el.innerHTML = el.innerHTML + '<div class="loader"></div>'
  }
})

Vue.filter('formatAmount', value => formatAmount(value))
Vue.filter('toFloat', value => toFloat(value))
Vue.filter('formatPhone', value => formatPhone(value))
Vue.filter('pluralize', (array, txt) => pluralize(array, txt))
Vue.filter('formatCardMonth', val => formatCardMonth(val))

const bugsnag = require('bugsnag-js')


var bugsnagClient = bugsnag({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  releaseStage: process.env.VUE_APP_RELEASE_STAGE
})

const bugsnagVue = require('bugsnag-vue')
bugsnagClient.use(bugsnagVue(Vue))

window.app = new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  components: { App }
})

export {
  // bugsnagClient,
  eventsHub
}
