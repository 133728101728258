<template>
    <div class="register wrapper_container">
        <KHeader title="Create an account" sub_title="Please enter an email address and password to get started"></KHeader>
        <div class="container">
            <div class="login_form">
                <span class="error" v-if="fieldErrors.__all__">{{ fieldErrors.__all__[0] }}</span>
                <div class="form_row">
                    <label class="show_in_keyboard">Email</label>
                    <span class="error" v-if="fieldErrors.username" v-show="!errors.has('username')">User with the same email id already exists</span>
                    <span class="error" v-show="errors.has('username')">{{ errors.first('username') }}</span>
                    <input @focus="showKeyBoard" class="email" v-bind:class="{ input_error: errors.has('username') }" v-validate="{ required: true, email: true}" v-model="model.username" name="username" type="text" placeholder="Email" required data-layout="email"/>
                </div>
                <div class="form_row">
                    <label class="show_in_keyboard">Password</label>
                    <p class="password_note">Your password has to be at least 6 characters long, must contain 1 uppercase and 1 special character.</p>
                    <span class="error" v-show="errors.has('password')">{{ errors.first('password') }}</span>
                    <span class="error" v-show="!errors.has('password') && showStrongMsg">{{ strongMsg }}</span>
                    <input @focus="showKeyBoard" v-bind:class="{ input_error: errors.has('password') }" v-validate="{ required: true, min:6 }" v-model="model.password" required name="password" placeholder="Password" type="password" data-layout="normal"/>
                </div>
                <div class="form_row">
                    <label class="show_in_keyboard">Re-enter Password</label>
                    <span class="error" v-show="errors.has('password1')">{{ errors.first('password1') }}</span>
                    <input @focus="showKeyBoard" v-bind:class="{ input_error: errors.has('password1') }" v-validate="{ required: true, confirmed:model.password }" v-model="model.password1" required name="password1" placeholder="Re-enter Password" type="password" data-vv-as="password" data-layout="normal"/>
                </div>
                <button v-loading type="submit" v-bind:class="{ loading: loading }" class="ripple" @click.prevent="onSubmit"><span>Create My Account</span></button>
            </div>
            <div class="form_links">
                <a href="#" @click.prevent="setActiveSection('login')">Already have an account?</a>
            </div>
        </div>
        <vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField" />
    </div>
</template>

<script>
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { mapGetters, mapActions, mapState } from 'vuex'
import KHeader from '../Header'
// import VueTouchKeyboard from "vue-touch-keyboard"
import { keyboardMixin } from '../../mixins/Keyboard.js'
import { zxcvbn } from '../../utils/base'
// Vue.use(VueTouchKeyboard)
Vue.use(VeeValidate)

window.cc = zxcvbn

export default {
  name: 'register',
  mixins: [keyboardMixin],
  data () {
    return {
      loading: false,
      showStrongMsg: false,
      strongMsg: '',
      model: {
        username: '',
        password: ''
      },
      fieldErrors: {
        __all__: '',
        username: '',
        password: ''
      }
    }
  },
  components: {
    KHeader
  },
  mounted: function () {
    var inputs = document.getElementsByTagName('input')
    for (const autoinput of inputs) {
      if (autoinput.type.toLowerCase() == 'email') {
        autoinput.setAttribute('autocomplete', 'nope')
      }
      if (autoinput.type.toLowerCase() == 'password') {
        autoinput.setAttribute('autocomplete', 'new-password')
      }
      if (autoinput.type.toLowerCase() == 'text') {
        autoinput.setAttribute('autocomplete', 'off')
      }
    }
  },
  methods: Object.assign({},
    mapActions({
      setActiveSection: 'paybill/setActiveSection',
      createUser: 'createUser',
      login: 'login'
    }),
    {
      onSubmit: function () {
        this.showStrongMsg = false
        this.$validator.validateAll().then((result) => {
          if (result) {
            var passCheck = zxcvbn(this.model.password)
            if (passCheck.score > 0) {
              this.loading = true
              this.createUser(this.model).then(response => {
                this.loading = false
                if (response.status == 'error') {
                  this.fieldErrors = response.errors
                } else {
                  this.login(this.model)
                }
              })
            } else {
              this.showStrongMsg = true
              this.strongMsg = 'Password is too weak. '
              if (passCheck.feedback && passCheck.feedback.suggestions[0]) {
                this.strongMsg = 'Password is too weak. ' + passCheck.feedback.suggestions[0]
              }
            }
          }
        })
      }
    }
  ),
  watch: {
    'model.password': function () {
      this.showStrongMsg = false
    }
  }
}
</script>

<style scoped lang="less">
    .password_note {
        font-size: 14px;
        font-weight: 300;
        font-family: "Gotham";
    }
</style>
