import API from '../utils/api'

const api = new API()

const actions = {
  setActiveSection (context, section) {
    context.commit('setActiveSection', section)
  },
  setWindowMargin (context, section) {
    context.commit('setWindowMargin', section)
  },
  setDocType (context, docType) {
    context.commit('setDocType', docType)
  },
  setSizeGuideUnit (context, size) {
    api.getSizeGuide(true).then(response => {
      var result = response.data.result.filter(video => video.size == size)
      context.commit('setSizeGuideUnit', result.length ? result[0] : '')
    })
  },
  setLogin (context, response) {
    context.commit('setLogin', { token: response.token, email: response.email })
  },
  logout (context) {
    context.commit('logout')
    context.commit('setActiveSection', 'home')
  },
  getFacilityInfo (context) {
    api.getFacilityInfo().then(response => context.commit('setFacilityInfo', response.data.result))
  },
  getCapabilities (context, ownerId) {
    api.getCapabilities(ownerId).then(response => context.commit('setCapabilities', response.data?.data?.capabilities))
  },
  getAmenities (context) {
    api.getAmenities().then(response => {
      const data = {}
      Object.keys(response.data.result).forEach(function (key) {
        const value = response.data.result[key]
        data[key] = {
          image_url: value.image_url,
          name: value.name.en
        }
      })
      context.commit('setAmenities', data)
    })
  },
  getFacilityMedia (context) {
    api.getFacilityMedia().then(response => {
      // Adding a null object to handle map and street view in detail page
      response.data.result.splice(1, 0, {})
      context.commit('setFacilityMedia', response.data.result)
    })
  },
  getFacilityUnits (context) {
    api.getFacilityUnits().then(response => {
      var key
      var units = response.data.result
      context.commit('setAllUnits', units)
      var sortedUnits = {}
      units.map(unit => {
        if (unit.parking) key = 'Parking'
        else if (unit.wine_storage) key = 'Wine Storage'
        else key = unit.size
        key = key.toLowerCase().replace(' ', '_')
        // var availabilityThreshold = Math.max(unit.stop_available_to_rent_when_below, 0)
        if (unit.quantity_available_to_rent > 0) {
          try {
            sortedUnits[key].push(unit)
          } catch (error) {
            sortedUnits[key] = [unit]
          }
        }
        if (unit.description.length) {
          unit.description = unit.description + ', ' + unit.features.join(', ')
        } else {
          unit.description = unit.features.join(', ')
        }
        return unit
      })
      context.commit('setFacilityUnits', sortedUnits)
    })
  },
  getFacilityNonInteractiveDocs (context) {
    api.getNonInteractiveAgreements().then(response => {
      var panda_agreements = response.data.result.agreements
      context.commit('setFacilityNonInteractiveDocs', panda_agreements)
    }).catch(error => {
      return error
    })
  },
  login (context, model) {
    return api.login(model).then(response => {
      context.commit('setLogin', { token: response.data.result.user_token, email: model.username })
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  forgotPassword (context, model) {
    return api.forgotPassword(model).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  linkRental (context, model) {
    return api.linkRental(model).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  createUser (context, model) {
    return api.createUser(model).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  getUserItems (context) {
    return api.getUserItems(context.state).then(response => {
      return response.data
    }).catch(error => {
      return error.response
    })
  },
  getFacilityCities (context) {
    return api.getFacilityCities().then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  getUserDetails (context, idvId = '') {
    return api.getUserDetails(context.state.userToken, idvId).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  findReservation (context, model) {
    return api.findReservation(model).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  getFacilityMap (state, spaces) {
    return api.getFacilityMap().then(response => {
      if (response.data.status == 'ok') {
        var imageData = response.data.result
        var vp = document.getElementById('facilitymap')
        var vph = vp.offsetHeight - 50
        var vpw = vp.offsetWidth
        Object.keys(imageData).forEach(function (key, index) {
          var resizeRatio = 1
          var details = imageData[key]
          var bgh = details.image_data.height
          var bgw = details.image_data.width
          var drawDetails = details.image_data.polygon
          if (bgh > vph && bgw > vpw) {
            if ((bgh / vph) > (bgw / vpw)) {
              resizeRatio = bgh / vph
            } else {
              resizeRatio = bgw / vpw
            }
          } else {
            if (bgh > vph) {
              resizeRatio = bgh / vph
            }
            if (bgw > vpw) {
              resizeRatio = bgw / vpw
            }
          }
          details.image_data.height = (bgh / resizeRatio) + 'px'
          details.image_data.width = (bgw / resizeRatio) + 'px'
          if (drawDetails.length) {
            Object.keys(drawDetails).forEach(function (key, index) {
              var doorName = drawDetails[key].door_name
              if (doorName) {
                var space = spaces.find(function (i) {
                  return i.door_name == doorName
                })
                if (space) drawDetails[key].space = space
              }
              drawDetails[key].height = (drawDetails[key].height / resizeRatio) + 'px'
              drawDetails[key].width = (drawDetails[key].width / resizeRatio) + 'px'
              drawDetails[key].top = (drawDetails[key].top / resizeRatio) + 'px'
              drawDetails[key].left = (drawDetails[key].left / resizeRatio) + 'px'
              var mediaDetails = drawDetails[key].media
              if (mediaDetails.length) {
                Object.keys(mediaDetails).forEach(function (key, index) {
                  if (mediaDetails[key].media_type == 'yt') {
                    mediaDetails[key].media_url = 'https://www.youtube.com/embed/' + mediaDetails[key].media_url + '?autoplay=1&rel=0'
                  }
                  if (mediaDetails[key].media_type == 'wistia') {
                    mediaDetails[key].media_url = 'http://fast.wistia.net/embed/iframe/' + mediaDetails[key].media_url + '?wmode=transparent&rel=0&hd=1&autoplay=1'
                  }
                })
              }
            })
          }
        })
        return response.data
      }
    }).catch(error => {
      return error.data
    })
  },
  getAllSpaceStatus (context) {
    return api.getAllSpaceStatus().then(response => {
      context.commit('setFacilitySpaces', response.data.result)
      return response.data
    }).catch(error => {
      return error
    })
  },
  getSpaceStatus (state, obj) {
    return api.getSpaceStatus(obj).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  getSpaceDetails (state, obj) {
    return api.getSpaceDetails(obj).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  }
}

export default actions
