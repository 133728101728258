<template>
    <div class="link_rental wrapper_container">
        <div class="custom_keyboard" v-if="showCustomKeyBoard">
            <div class="keyboard_el_wrapper">
                <ul class="controls">
                    <li><a class="close_button" @click="hideKeyBoard">Close</a></li>
                </ul>
                <ul class="select_wrapper">
                    <li v-for="(i, index) in selectOptions" @click.prevent="setSelectedOption(i, index)" :class="{selected: i.value == selectedOptionValue}">{{ i.text }}</li>
                </ul>
                <ul class="controls">
                    <li><a class="next_button" @click="nextKeyBoardField">Next</a></li>
                </ul>
            </div>
        </div>
        <KHeader title="Link a space to your account" sub_title=""></KHeader>
        <div class="container">
            <div class="login_form">
                <span class="error" v-if="fieldErrors.__all__">{{ fieldErrors.__all__[0] }}</span>
                <div class="select-wrap">
                    <div class="form_row form_field_align form_select">
                        <label class="show_in_keyboard">Facility City</label>
                        <span class="error" v-if="errors.has('facility_city')">This field is required</span>
                        <select @click="showSelectKeyBoard" name="facility_city" v-model="model.facility_city" v-bind:class="{ input_error: errors.has('facility_city') }" v-validate="{ required: true}">
                            <option value="">Select Facility City</option>
                            <option v-for="city in facilityCityList" :value="city.city" v-if="city.facilities.length">{{ city.city }}</option>
                        </select>
                    </div>
                </div>
                <div class="select-wrap" v-for="facilities in facilityCityList" v-if="facilities.city==model.facility_city">
                    <div class="form_row form_field_align form_select">
                        <label class="show_in_keyboard">Facility Address</label>
                        <span class="error" v-if="errors.has('facility_address')">This field is required</span>
                        <select @click="showSelectKeyBoard" name="facility_address" v-model="model.facility_address" v-bind:class="{ input_error: errors.has('facility_address') }" v-validate="{ required: true}">
                            <option value="">Select Facility Address</option>
                            <option v-for="fac in facilities.facilities" :value="fac.facility_id">{{ fac.address }}</option>
                        </select>
                    </div>
                </div>
                <p v-if="error_message" class="error_message" v-html="error_message"></p>
                <div class="form_row">
                    <label class="show_in_keyboard">Space Number</label>
                    <span class="error" v-if="errors.has('unit_no')">This field is required</span>
                    <input @focus="showKeyBoard" name="unit_no" type="text" v-model="model.unit_no" placeholder="Space Number" v-bind:class="{ input_error: errors.has('unit_no') }" v-validate="{ required: true}" data-layout="normal"/>
                </div>
                <div class="form_row">
                    <h2 v-if="model.phone" class="gatecode-msg">If you do not know your gatecode, please contact your facility manager at <strong>{{ model.phone|formatPhone }}</strong></h2>
                    <label class="show_in_keyboard">Gate Code</label>
                    <span class="error" v-if="errors.has('gate_code')">This field is required</span>
                    <input @focus="showKeyBoard" name="gate_code" type="text" v-model="model.gate_code" placeholder="Gate Code" v-bind:class="{ input_error: errors.has('gate_code') }" v-validate="{ required: true}" data-layout="submit"/>
                </div>
                <button type="submit" v-bind:class="{ loading: loading }" class="ripple" @click.prevent="onSubmit"><span>Link My Rental</span><div class="loader"></div></button>
            </div>
        </div>
        <vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField"  :submit="onSubmit"/>

    </div>

</template>

<script>
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { mapGetters, mapActions, mapState } from 'vuex'
import KHeader from '../Header'
import { keyboardMixin } from '../../mixins/Keyboard.js'

export default {
  name: 'linkrental',
  mixins: [keyboardMixin],
  components: {
    KHeader
  },
  data () {
    return {
      loading: false,
      model: {
        facility_city: '',
        facility_address: '',
        unit_no: '',
        facility_id: '',
        phone: '',
        gate_code: ''
      },
      fieldErrors: {
        __all__: '',
        facility_state: '',
        facility_address: '',
        spacenumber: '',
        gatecode: ''
      },
      facilityCityList: [],
      error_message: ''

    }
  },
  computed: {
    facility_city () {
      return this.model.facility_city
    }
  },
  methods: Object.assign({},
    mapActions({
      setActiveSection: 'paybill/setActiveSection',
      getFacilityCities: 'getFacilityCities',
      linkRental: 'linkRental'
    }),
    {
      onSubmit: function () {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.loading = true
            this.model.user_token = this.$store.state.userToken
            this.linkRental(this.model).then(response => {
              this.loading = false
              if (response.status == 'error') {
                this.fieldErrors = response.errors
                this.error_message = response.errors
              } else {
                this.error_message = ''
                this.setActiveSection('pay')
              }
            }).catch(error => {
              this.loading = false
              this.error_message = 'Invalid Gate code or Unit Number'
            })
          }
        })
      }
    }
  ),
  mounted () {
    this.getFacilityCities().then((response) => {
      this.facilityCityList = response.result
    })
  },
  watch: {
    facility_city () {
      var self = this
      var filteredCity = this.facilityCityList.filter(function (el) {
        return el.city == self.model.facility_city
      })
      if (filteredCity.length) {
        filteredCity = filteredCity[0]
        if (filteredCity.facilities.length == 1) {
          this.model.facility_address = filteredCity.facilities[0].facility_id
          this.model.phone = filteredCity.facilities[0].phone
          return
        }
      }
      this.model.facility_address = ''
      this.model.phone = ''
    },
    'model.facility_address': function (value) {
      var self = this
      self.model.facility_id = value
      this.facilityCityList.forEach(function (el) {
        el.facilities.forEach(function (ele) {
          if (ele.facility_id == value) {
            self.model.phone = ele.phone
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
    .wrapper_container {
        .container {
            background-image: url('../../assets/graphics/link_rental.png') !important;
        }
    }
    .error_message {
        color: #F83600;
        a {
            color: #F83600;
        }
        ul {
            li, p {
                margin-bottom: 10px;
            }
            li {
                font-weight: bold;
            }
        }
    }
</style>
