import actions from './actions.js'
import mutations from './mutations.js'

const rental = {
  namespaced: true,
  state: {
    selectedUnit: '',
    section: 'detail_page',
    secondarySelected: false,
    militarySelected: false,
    openedUnitGroup: '',
    dossierId: '',
    idVerificationStatus: false,  //to save id verification status from dossier
    dossierPollingStartedStatus: false
  },
  actions: actions,
  mutations: mutations
}

export default rental
