<template>
    <div class="login wrapper_container">
        <KHeader :title="loginHeader" sub_title="Please enter your email so we can look up your account"></KHeader>
        <div class="container">
            <div class="login_form">
                <span class="error" v-if="fieldErrors.__all__">{{ fieldErrors.__all__[0] }}</span>
                <div class="form_row">
                    <label class="show_in_keyboard">Email</label>
                    <span class="error" v-if="errors.has('username')">{{ errors.first('username') }}</span>
                    <input @focus="showKeyBoard" class="email" v-bind:class="{ input_error: errors.has('username') }" v-validate="{ required: true, email: true}" v-model="model.username" name="username" type="text" placeholder="Email" required data-layout="email"/>
                </div>
                <div class="form_row">
                    <label class="show_in_keyboard">Password</label>
                    <span class="error" v-if="errors.has('password')">{{ errors.first('password') }}</span>
                    <input @focus="showKeyBoard" v-bind:class="{ input_error: errors.has('password') }"  v-validate="{ required: true}" v-model="model.password" required name="password" placeholder="Password" type="password" data-layout="submit"/>
                </div>
                <button v-loading type="submit" :class="{loading: submitLogin}" class="ripple" @click.prevent="onSubmit"><span>Login</span></button>
            </div>
            <div class="form_links" v-if="showForgot">
                <a href="#" @click.prevent="setActiveSection('register')">Don't have an Account?</a>
                <a href="#" @click.prevent="setActiveSection('forgot_password')">Forgot your Password?</a>
            </div>
        </div>
        <vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField" :submit="onSubmit"/>
    </div>
</template>
<script>
import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import { mapGetters, mapActions, mapState } from 'vuex'
import KHeader from '../Header'
import { normalx } from '../../utils/layouts.js'
import { keyboardMixin } from '../../mixins/Keyboard.js'
import { v4 as uuidv4 } from 'uuid'
Vue.use(VeeValidate)

const dictionary = {
  en: {
    messages: {
      required: () => 'This field is required'
    }
  }
}

Validator.localize(dictionary)
// const validator = new Validator({ username: 'required', password: 'required' });

export default {
  name: 'login',
  props: ['isRental'],
  mixins: [keyboardMixin],
  data () {
    return {
      submitLogin: false,
      model: {
        username: '',
        password: ''
      },
      fieldErrors: {
        __all__: '',
        username: '',
        password: ''
      }

    }
  },
  components: {
    KHeader
  },
  computed: {
    loginHeader () {
      if (this.isRental) {
        return 'Login'
      } else {
        return 'Login or create an account'
      }
    },
    showForgot () {
      if (this.isRental) {
        return false
      }
      return true
    }

  },
  mounted: function () {
    var inputs = document.getElementsByTagName('input')
    // for(var i = 0; i < inputs.length; i++) {
    for (const autoinput of inputs) {
      if (autoinput.type.toLowerCase() == 'email') {
        autoinput.setAttribute('autocomplete', 'nope')
      }
      if (autoinput.type.toLowerCase() == 'password') {
        autoinput.setAttribute('autocomplete', 'new-password')
      }
      if (autoinput.type.toLowerCase() == 'text') {
        autoinput.setAttribute('autocomplete', 'off')
      }
    }
  },
  methods: Object.assign({},
    mapActions({
      setActiveSection: 'paybill/setActiveSection',
      login: 'login'
    }),
    {
      onSubmit: function () {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submitLogin = true
            this.login(this.model).then(response => {
              this.submitLogin = false
              if (response.status == 'error') {
                this.fieldErrors = response.errors
              }
            })
          }
        })
      }
    }
  )
}
</script>

<style scoped lang="less"></style>
