<template>
    <div id="paybill">
        <component :is="currentComponent"></component>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { keyboardMixin } from '../../mixins/Keyboard.js'
import { PhoneMixin } from '../../mixins/phone.js'
import Login from './Login'
import Register from './Register'
import ForgotPassword from './ForgotPassword'
import LinkRental from './LinkRental'
import Details from './Details'
// import NonInteractiveAgreements from '../NonInteractiveAgreements'

export default {
  name: 'paybill',
  mixins: [keyboardMixin, PhoneMixin],
  data () {
    return {
      currentComponent: 'login'
    }
  },
  methods: Object.assign({},
    mapActions({
      setActiveSection: 'paybill/setActiveSection'
    }), {
      updateOnLogin () {
        if (this.loggedIn) {
          this.setActiveSection('pay')
          this.currentComponent = 'Details'
        }
      }
    }
  ),
  components: {
    Login, Register, Details, LinkRental, ForgotPassword
  },
  watch: {
    activeSection: function () {
      if (this.activeSection == 'login') {
        this.currentComponent = 'login'
      }
      if (this.activeSection == 'register') {
        this.currentComponent = 'Register'
      }
      if (this.activeSection == 'link_rental') {
        this.currentComponent = 'LinkRental'
      }
      if (this.activeSection == 'pay') {
        this.currentComponent = 'Details'
      }
      if (this.activeSection == 'forgot_password') {
        this.currentComponent = 'ForgotPassword'
      }
      // if (this.activeSection == 'non_interactive_agreements') {
      //     this.currentComponent = 'NonInteractiveAgreements'
      // }
    },
    loggedIn: function () {
      this.updateOnLogin()
    }
  },
  computed: mapState({
    loggedIn: state => state.loggedIn,
    activeSection: state => state.paybill.section
  }),
  mounted () {
    if (!this.loggedIn) {
      this.setActiveSection('login')
    }
    this.updateOnLogin()
  }
}
</script>

<style scoped>

</style>
