<template>
    <li>
        <div class="img-wrapper">
            <img :src="review.image_url ? review.image_url : defaultImage" @error="getDefaultImage">
        </div>
        <div class="text-wrapper">
            <h3>{{ review.author_name }}</h3>
            <span class="sub-tittle">{{ reviewDate }}</span>
            <div class="comments">
                <div class="rating_wrapper" :class="{google_star: reviewType!='yelp', yelp_star: reviewType=='yelp'}">
				    <span v-for="item in ratingList" class="icon-rating_icon" :class="{active: item==100}">
                        <span v-if="item < 100 && item > 0" class="icon-rating_icon active" v-bind:style="{width: item+'%'}"></span>
                    </span>
			    </div>
                <span>{{ review.text }}</span>
            </div>
        </div>
        <div class="clear"></div>
    </li>
</template>

<script>
var hdate = require('human-date')
export default {
  name: 'review',
  props: ['review', 'reviewType'],
  data: function () {
    return {
      defaultImage: 'https://storagefront.com/media/review_default.png'
    }
  },
  methods: {
    humanize: function (value, text) {
      const pluralizedText = value > 1 ? text + 's' : text
      return ' ' + pluralizedText + ' '
    },
    getDefaultImage: function (ev) {
      ev.target.src = this.defaultImage
    }
  },
  computed: {
    reviewDate: function () {
      var date, dateText
      var dateSuffix = 'ago'
      // Date expects a timestamp in milliseconds from epoch
      var publishedDate = hdate.relativeTime(new Date(this.review.time * 1000), { returnObject: true })
      if (publishedDate.years) {
        date = publishedDate.years
        dateText = this.humanize(date, 'year')
      } else {
        publishedDate = publishedDate.days
        if (publishedDate >= 30) {
          date = Math.floor(publishedDate / 30)
          dateText = this.humanize(date, 'month')
        } else if (publishedDate >= 7) {
          date = Math.floor(publishedDate / 7)
          dateText = this.humanize(date, 'week')
        } else {
          date = publishedDate
          if (date == 0) {
            date = ''
            dateText = 'Today'
            dateSuffix = ''
          } else {
            dateText = this.humanize(date, 'day')
          }
        }
      }
      return date + dateText + dateSuffix
    },
    ratingList: function () {
      var i = 1
      var rList = []
      var rating = this.review.user_rate
      for (i = 1; i < 6; i++) {
        if (rating >= i) {
          rList.push(100)
        } else if (rating > i - 1) {
          rList.push((1 - (i - rating)) * 100)
        } else {
          rList.push(0)
        }
      }
      return rList
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../assets/less/colors';
    li {
        margin-top: 28px;
        .img-wrapper {
            width: 120px;
            float: left;
            padding-left: 10px;
            display: inline-block;
            box-sizing: border-box;
            img {
                width: 103px;
                height: 103px;
                border-radius: 50%;
                border: 3px solid #dcdcdc;
            }
        }
        .text-wrapper {
            float: left;
            font-size: 14px;
            margin-left: 20px;
            display: inline-block;
            color: @common_text_color;
            width: calc(~'100% - 140px');
            h3 {
                font-weight: bold;
                margin-top: 10px;
                font-size: 14px;
                line-height: 14px;
            }
            .sub-tittle {
                font-size: 12px;
            }
            .comments {
                line-height: 20px;
            }
        }
    }
</style>
