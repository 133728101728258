<template>
    <div >
        <section id="sign_docs" :class="{loading: sectionLoading}">
            <div class="loader_wrapper">
                <div class="loader"></div>
            </div>
            <header class="pandadoc_header" :style="getHeaderStyle">
                <h1>Documents</h1>
                <ul class="agreements_list">
                    <li v-for="(doc, index) in panda_agreements" :class="{ active: index==selected_document }" @click="setSelectedDocument(index)" :key="doc.url">{{ doc.display_name }}</li>
                </ul>
            </header>
            <span v-if="showCloseButton" @click.prevent="closeAction" class="close-button icon-x-out"></span>
            <div class="container">
                <div class="document_list">
                    <embed  v-for="(doc, index) in panda_agreements" v-show="index==selected_document" :src="doc.url" :key="doc.url"></embed>
                </div>
            </div>
        </section>

    </div>
</template>

<script >
import Vue from 'vue'
import Api from '../utils/api.js'
import { mapState } from 'vuex'

var api = new Api()
export default {
  name: 'non_interactive_agreements',
  data: function () {
    return {
      panda_agreements: [],
      selected_document: 0,
      pandaError: false,
      sectionLoading: false
    }
  },
  props: ['showCloseButton', 'userDocuments', 'selectedDocumentIndex', 'docType'],
  mounted () {
    if (this.userDocuments) {
      this.selected_document = this.selectedDocumentIndex
      this.panda_agreements = this.userDocuments
    } else {
      this.selected_document = 0
      this.getDocuments()
    }
  },
  methods: {
    setSelectedDocument: function (id) {
      this.selected_document = id
    },
    closeAction: function () {
      this.$emit('closeAction')
    },
    getDocuments: function () {
      var self = this
      this.sectionLoading = true
      self.panda_agreements = []
      // var insurance_agreement_list = ['insurance_brochure_non_interactive', 'insurance_certificate_non_interactive', 'insurance_enrollment_form_non_interactive']
      var insurance_agreement_list = ['insurance_brochure_non_interactive']

      if (this.docType == 'insurance') {
        this.docs.forEach(function (agreement, index, array) {
          if (insurance_agreement_list.includes(agreement.document_type)) {
            self.panda_agreements.push(agreement)
          }
        })
      } else if (this.docType == 'rental_docs') {
        this.docs.forEach(function (agreement, index, array) {
          if (!insurance_agreement_list.includes(agreement.document_type)) {
            self.panda_agreements.push(agreement)
          }
        })
      } else {
        this.panda_agreements = this.docs
      }
      this.sectionLoading = false
    }
  },
  computed: Object.assign(
    {},
    mapState({
      docs: state => state.facilityNonInteractiveDocs,
      windowMargin: state => state.windowMargin
    }),
    {
      getHeaderStyle () {
        return {
          top: this.windowMargin
        }
      }
    }
  ),
  watch: {
    docType: function (newDoc, oldDoc) {
      this.selected_document = 0
      this.getDocuments()
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../assets/less/colors';
    @import '../assets/less/mixins';
    @import '../assets/less/flexbox';
    #sign_docs {
        .close-button {
            float: right;
            color: @primary_color;
            font-size: 28px;
            position: fixed;
            right: 80px;
            top: 70px;
            z-index: 1;
        }
    }
</style>
