<template>
    <li :class="[{ sub: unit.isSecond, active: isActive }, sizeClass]" @click.prevent="setVideo(unit)">
        <!-- <span class="position"></span> -->
        <span class="size_name">{{ unit|shortform }}</span>
        <span class="size">{{ unit.name }}</span>
        <span class="icon-select_arrow_up"></span>
    </li>
</template>

<script>
export default {
  name: 'unit',
  props: [
    'unit'
  ],
  computed: {
    isActive: function () {
      let status = false
      if (this.unit.video_id == this.$parent.video) {
        this.$emit('clickedUnitGroup', this.unit)
        status = true
      }
      return status
    },
    sizeClass: function () {
      return this.unit.size.toLowerCase().replace(/\s+/g, '-')
    }
  },
  methods: {
    setVideo: function (unit) {
      this.$emit('setVideo', unit.video_id)
      this.$emit('clickedUnitGroup', unit)
    }
  },
  filters: {
    shortform: function (unit) {
      const sizeNames = {
        'Extra Small': 'XS',
        Small: 'S',
        Medium: 'M',
        Large: 'L',
        'Extra Large': 'XL'
      }
      return unit.isSecond ? ' ' : sizeNames[unit.size]
    }
  }
  // mounted() {
  //     this.$emit('clickedUnitGroup', this.unit)
  // }
}

</script>

<style scoped lang="less">
    @import '../../assets/less/colors.less';
    li {
        color: #9B9B9B;
        padding-top: 35px;
        padding-bottom: 30px;
        display: inline-block;
        float: left;
        text-align: center;
        font-family: 'Gotham';
        font-weight: 400;
        cursor: pointer;
        height: 64px;
        width: 45px;
        padding-left: 142px;
        .size {
            display: block;
            font-size: 16px;
            padding-top: 2px;
            text-decoration: none;
        }
        .size_name {
            display: block;
            // max-width: 93px;
            min-height: 24px;
            line-height: 24px;
            font-size: 20px;
            color: black;
        }
        &.sub {
            padding-left: 49px;
            & + li.medium, & + li.large {
                padding-left: 49px;
            }
        }
        .icon-select_arrow_up {
            display: none;
            color: @call_to_action_color;
            font-size: 25px;

        }
        &.active {
            .size {
                color: black;
                font-weight: 500;
            }
            .icon-select_arrow_up {
                display: block;
            }
        }
    }
</style>
