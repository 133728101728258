<template>
<div>
		<h1>{{time}}</h1>
</div>
</template>

<script >
export default {
  data(){
    return {'time': parseInt(new Date().getTime() / 1000)}}
}
// methods:{
// 	toHome(){
//            this.router.push({ name: 'home', params: { facId: this.route } })
// 		}
// 	}
</script>
