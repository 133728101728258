import axios from 'axios'
import qs from 'qs'

class MTSCRAWebApi {
  getCardSwipe () {
    var obj = {
      ConnectionType: 4, // USB
      WaitTime: 45 // Wait time for card swipe in seconds
    }
    return axios.post('http://localhost:9002/api/mtscrahost/RequestCardSwipe', obj, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getDeviceHealth () {
    return axios.get('http://localhost:9002/api/mtscrahost/CheckHealth', {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

export default MTSCRAWebApi
