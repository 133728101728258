const mutations = {
  setKioskConfig (state, config) {
    state.kioskConfig = config
  },
  setCapabilities (state, data) {
    state.capabilities = data
  },
  setActiveSection (state, section) {
    const sameComponentSections = ['rent', 'find_reservation']
    // Refreshing same component with different active section results in unexpected behaviour
    if (!(section == 'find_reservation' && sameComponentSections.includes(state.activeSection))) { state.activeSectionKey = (new Date()).getTime() }
    state.activeSection = section
  },
  setDocType (state, docType) {
    state.docType = docType
  },
  setFacilityInfo (state, data) {
    state.facilityInfo = data
  },
  setWindowMargin (state, data) {
    state.windowMargin = data
  },
  setAmenities (state, data) {
    state.facilityAmenities = data
  },
  setSizeGuideUnit (state, selecUnit) {
    state.selectedSizeGuideUnit = selecUnit
  },
  setFacilityMedia (state, data) {
    state.facilityMedia = data
  },
  setFacilityUnits (state, units) {
    state.facilityUnits = units
  },
  setAllUnits (state, units) {
    state.allFacilityUnits = units
  },
  setFacilitySpaces (state, spaces) {
    state.facilitySpaces = spaces
  },
  setFacilityNonInteractiveDocs (state, docs) {
    state.facilityNonInteractiveDocs = docs
  },
  setLogin (state, obj) {
    state.loggedIn = true
    state.userToken = obj.token
    state.userEmail = obj.email
  },
  logout (state) {
    state.userToken = ''
    state.userEmail = ''
    state.loggedIn = false
  },
  updateSkipAndVerifyStatus(state, data) {
    state.skipAndVerify = data
  }
}

export default mutations
