import API from '../utils/api'

const hdate = require('human-date')
const api = new API()

export const paybillMixin = {
  data: function () {
    return {
      balance: [],
      loadingMonthlyCharges: true,
      monthlyCharges: [],
      prePayDetails: {}
    }
  },
  filters: {
    getDate: function (dateString) {
      return dateString.split(' ')[1].replace(',', '')
    },
    humanDate: function (timestamp, ms = false) {
      const date = ms ? new Date(timestamp) : new Date(timestamp * 1000)
      return hdate.prettyPrint(date)
    },
    maskCardNumber: function (cardNumber) {
      return 'XXXX XXXX XXXX ' + cardNumber.slice(-4)
    }
  },
  computed: {
    due: function () {
      const balance = this.space.balance
      if (balance) {
        let amount = 0
        for (const bal of balance) {
          const amt = bal.total ? bal.total : bal.amount
          if (bal.costType === 'discount') { amount -= amt } else { amount += amt }
        }
        return amount
      } else {
        return 0
      }
    }
  },
  methods: {
    getBillPaidThroughDate: function () {
      const paidThroughDate = new Date(this.space.paidThrough * 1000)
      return paidThroughDate.setDate(paidThroughDate.getDate() + 1)
    },
    getPrePayDetails: function (period = '1') {
      this.loadingMonthlyCharges = true
      api.getPrePayDetails(this.space.gds_id, this.tenant.tenant_id, this.space.spaceId, period).then(response => {
        this.loadingMonthlyCharges = false
        this.prePayDetails = response.data.result
        this.monthlyCharges = this.prePayDetails.movein_cost
        const balance = this.prePayDetails.balance
        this.balance = balance || []
      }).catch(error => {
        this.loadingMonthlyCharges = false
        this.monthlyCharges = []
        this.balance = []
      })
    }
  }
}
