<template>
    <li :class="{ 'default_promo': defaultPromo }">
        <div class="redeem" v-if="defaultPromo">
            <span>Save &amp; Rent Now</span>
        </div>
        <section>
            <span class="name-wrapper">
                <span class="name">{{ unit.actual_width}} X {{ unit.actual_length }} {{ unitDescription }}</span>
                <span class="type" v-if="!isCategory">{{ unit.unit_type }}</span>
            </span>
            <span class="board-rate" v-if="!isInstore && unit.rental_rate">
                <span class="text">IN-STORE</span>
                <span class="price">{{ unit.board_rate }}</span>
            </span>
            <span class="action-sec">
                <span class="rental-rate" v-if="unit.rental_rate">
                    <span class="text" v-if="isInstore">IN-STORE</span>
                    <span class="text" v-else>SELF-SERVE</span>
                    <span class="price">{{ unit.rental_rate }}</span>
                </span>
                <span class="rent-button" @click.prevent="setSelected" v-if="unit.rental_rate">SELECT</span>
                <a class="ga_phone_call" :href="`tel:${phone}`" aria-label="Call" tabindex="0" v-if="!unit.rental_rate">CALL</a>
            </span>
        </section>
        <div class="unit-des">
            <p class="description">{{ description }}</p>
            <p class="default-promo" v-if="defaultPromo">{{ defaultPromo.promo_description }}</p>
        </div>
    </li>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'unitType',
  props: [
    'unit',
    'isInstore',
    'isCategory'
  ],
  computed: {
    ...mapState({
      facility: state => state.facilityInfo
    }),
    defaultPromo: function () {
      return this.unit.promos.filter((promo) => promo.promo_type == 'default')[0]
    },
    unitDescription: function () {
      return this.unit.parking ? 'Space' : 'Unit'
    },
    description: function () {
      return this.unit.description_updated ? this.unit.sf_description : this.unit.description
    },
    phone: function () {
      return this.facility.real_phone
    }
  },
  methods: Object.assign(
    {},
    mapActions({
      setSelectedUnit: 'rental/setSelectedUnit',
      setActiveSection: 'rental/setActiveSection'
    }),
    {
      setSelected () {
        this.setSelectedUnit(this.unit)
        this.setActiveSection('hold_unit')
        // this.setActiveSection('card_details')
      }
    }
  )
}
</script>

<style lang="less" scoped>
    @import '../../assets/less/colors';
    @import '../../assets/less/flexbox';
    li {
        overflow: hidden;
        position: relative;
        background-color: @primary_shading_color;
        &.default_promo {
            background-color: @call_to_action_shading;
        }
        .redeem {
            display: block;
            width: 108px;
            height: 108px;
            pointer-events: none;
            background-color: @promo_color;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            position: absolute;
            top: -55px;
            right: -55px;
            box-shadow: 0 0 5px 1px #aaa;
            span {
                position: absolute;
                bottom: 3px;
                font-size: 12px;
                right: 20px;
                text-align: center;
                color: white;
                font-weight: 600;
                width: 65px;
                line-height: 14px;
            }
        }
        section {
            span {
                display: inline-block;
                color: @common_text_color_dark;
            }
            .flex-display(flex);
            .align-items(center);
            .name-wrapper {
                width: 48%;
                .name {
                    font-size: 18px;
                    font-weight: bold;
                    display: block;
                    line-height: 18px;
                }
                .type {
                    font-size: 14px;
                }
            }
            .board-rate {
                .text {
                    font-size: 12px;
                    display: block;
                    line-height: 12px;
                }
                .price {
                    font-size: 18px;
                    text-align: right;
                    display: block;
                    &:before {
                        content: "$";
                        font-size: 16px;
                    }
                }
            }
            .action-sec {
                .flex-display(flex);
                .align-items(center);
                margin-left: auto;
                .rental-rate {
                    margin-right: 20px;
                    .text {
                        font-size: 12px;
                        font-weight: bold;
                        display: block;
                    }
                    .price {
                        font-size: 28px;
                        font-weight: 500;
                        text-align: right;
                        display: block;
                        line-height: 28px;
                        &:before {
                            content: "$";
                            font-size: 26px;
                        }
                    }
                }
                .rent-button {
                    margin-left: auto;
                    font-size: 15px;
                    font-weight: 500;
                    color: #fff;
                    padding: 9px 23px;
                    box-sizing: border-box;
                    background-color: @call_to_action_color;
                    border-radius: 3px;
                }
                .ga_phone_call {
                    margin-left: auto;
                    font-size: 15px;
                    font-weight: 500;
                    color: #fff;
                    padding: 7px 30px;
                    box-sizing: border-box;
                    background-color: var(--call-to-action-color);
                    border-radius: 3px;
                    width: 105px;
                }
            }
        }
        .unit-des {
            .description {
                font-size: 14px;
                line-height: 18px;
                margin-top: 23px;
            }
            .default-promo {
                color: @call_to_action_color;
                font-weight: bold;
            }
        }
    }
</style>
