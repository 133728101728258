import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/components/Main'
import Unavailable from '@/components/Unavailable'
import Time from '@/components/Time'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/time/',
      name: 'time',
      component: Time,
    },
    {
      path: '/:facId',
      name: 'home',
      component: Main
    },
    {
      path: '*',
      name: 'unavailable',
      component: Unavailable,
      props: true
    }
  ],
  mode: 'history'
})
