<template>
    <block
        cols="30% 70%"
        rows="30% 70%"
        pattern="side main, side main">
        <div slot="side" @click="sidebarClick">
            <div class="logo_wrapper">
                <a href="#" v-on:click.prevent="setActiveSection('home')" class="logo" :style="logoImage">Home</a>
            </div>
            <ul v-bind:class="{ 'welcome': $store.state.activeSection == 'home' }" class="main_menu">
                <li v-bind:class="{ 'active': $store.state.activeSection == 'rent' }" v-on:click.prevent="setDetailPageActiveSection" class="ripple">
                    <a href="#">Select a Space</a>
                </li>
                <li :class="{ 'active': $store.state.activeSection == 'find_reservation' }" v-on:click.prevent="setFindReservationActiveSection" class="ripple">
                    <a href="#">Find my reservation</a>
                </li>
                <li v-bind:class="{ 'active': $store.state.activeSection == 'size_guide' }" v-on:click.prevent="setActiveSection('size_guide')" class="ripple">
                    <a href="#">SIZE GUIDE</a>
                </li>
                <li v-bind:class="{ 'active': $store.state.activeSection == 'pay_bill' }" v-on:click.prevent="setActiveSection('pay_bill')" class="ripple" v-if="!facilityInfo.external_paybill">
                    <a href="#">PAY MY BILL</a>
                </li>
                <li v-bind:class="{ 'active': $store.state.activeSection == 'autopay' }" class="ripple" v-if="!facilityInfo.external_paybill">
                    <a v-on:click.prevent="setActiveSection('autopay')" href="#">Setup autopay</a>
                </li>
                <li v-bind:class="{ 'active': $store.state.activeSection == 'rental_docs' }" class="ripple" v-show="showDocs.lease">
                    <a v-on:click.prevent="setActiveSection('rental_docs')" href="#">Rental Documents</a>
                </li>
                <li v-bind:class="{ 'active': $store.state.activeSection == 'insurance' }" class="ripple" v-show="showDocs.insurance">
                    <a v-on:click.prevent="setActiveSection('insurance')" href="#">Protection Brochure</a>
                </li>
                <!--
                <li v-bind:class="{ 'active': $store.state.activeSection == 'samples' }" class="ripple">
                    <a v-on:click.prevent="setActiveSection('samples')" href="#">Samples</a>
                </li>
                -->
                <li v-bind:class="{ 'active': $store.state.activeSection == 'facility_map' }" v-if="facilityInfo.facility_map_url" class="ripple">
                    <a v-on:click.prevent="setActiveSection('facility_map')" href="#">Facility map</a>
                </li>
                <li v-if="$store.state.userToken.length" class="ripple logout_btn" @click="logoutClick">
                    <a href="#">Logout <span v-if="!dossierPollingStartedStatus" class="timer">{{countDownTimer}}</span></a>
                </li>
            </ul>
        </div>
        <div slot="main" id="content">
            <transition mode="out-in">
                <component :is="currentComponent" v-bind="currentProperties" :key="currentKey"></component>
            </transition>
            <div v-show="idle" @click.prevent="updateIdle" class="dialogbox">
                <div>
                    <div class="dg-backdrop"></div>
                    <div class="dg-container">
                        <div class="dg-content-cont dg-content-cont--floating">
                            <div class="dg-main-content">
                                <div class="dg-content-body">
                                    <div class="dg-content">
                                        <h2>ARE YOU STILL HERE?</h2>
                                        <p v-if="$store.state.userToken.length"><span>For your safety we will automatically log you</span><span>out in {{ countDownSec }} seconds if you don’t interact.</span></p>
                                        <p v-else><span>If you don’t interact we’ll bring you back to</span><span>the start screen in {{ countDownSec }} seconds.</span></p>
                                        <div class="countDownTimer">{{countDownTimer}}</div>
                                    </div>
                                </div>
                                <div v-if="$store.state.userToken.length" class="dg-content-footer log-out-buttons">
                                    <button class="dg-btn dg-btn--ok dg-pull-left" @click="confirmlogoutClick">
                                        <span class="dg-btn-content">
                                            <span>LOGOUT</span>
                                        </span>
                                    </button>
                                    <button class="dg-btn dg-btn--ok dg-pull-right">
                                        <span class="dg-btn-content">
                                            <span>CONTINUE</span>
                                        </span>
                                    </button>
                                    <div class="dg-clear"></div>
                                </div>
                                <div v-else class="dg-content-footer">
                                    <button class="dg-btn dg-btn--ok dg-pull-right">
                                        <span class="dg-btn-content">
                                            <span>I WANT TO CONTINUE</span>
                                        </span>
                                    </button>
                                    <div class="dg-clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </block>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import block from 'vue-block'
import Rental from './Rental/Base'
import Welcome from './Welcome'
import SizeGuide from './SizeGuide/Base'
import Samples from './Samples'
import PayBill from './Paybill/Base'
import FacilityMap from './FacilityMap'
import NonInteractiveAgreements from './NonInteractiveAgreements'
import { eventsHub } from '../main.js'
import API from '../utils/api.js'
import background_log from '../assets/graphics/logo.png'

const defaultTime = 1
const api = new API()

export default {
  name: 'Main',
  data () {
    return {
      currentComponent: 'Welcome',
      now: Math.trunc((new Date()).getTime() / 1000),
      expiryTime: Math.trunc(((new Date()).getTime() + 3 * 60000) / 1000),
      idle: false,
      extendedTime: Math.trunc(((new Date()).getTime() + 30 * 1000) / 1000),
      timeout: '',
      route: this.$route.params.facId,
      dossierPollingStartedStatus: false
    }
  },
  components: {
    block, Rental, Welcome, SizeGuide, Samples, PayBill, FacilityMap, NonInteractiveAgreements
  },
  methods: {
    sidebarClick () {
      eventsHub.$emit('sidebarClicked')
    },
    setFindReservationActiveSection () {
      this.setActiveSection('find_reservation')
      this.rentalActiveSection('find_reservation')
    },
    setDetailPageActiveSection () {
      this.setActiveSection('rent')
      this.rentalActiveSection('detail_page')
    },
    logoutClick () {
      this.logData('Show Logout Confirmation')
      this.$dialog.confirm('Are you sure you want to logout?', { okText: 'LOGOUT', cancelText: 'Cancel' }).then(() => {
        this.logData('Logout From Confirmation Popup')
        this.logoutUser()
      })
    },
    confirmlogoutClick () {
      this.logData('Logout Session')
      this.logoutUser()
    },
    onIdle () {
      const self = this
      this.logData('Show Idle Warning')
      this.idle = true
      this.extendedTime = Math.trunc(((new Date()).getTime() + 30 * 1000) / 1000)
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(function () {
        // console.log('Auto reload')
        self.logData('Auto Reload')
        self.$session.destroy()
        location.reload()
      }, 60 * 1000)
    },
    updateIdle () {
      if (this.$session.exists()) { this.logData('Continue Session') }
      clearTimeout(this.timeout)
      this.idle = false
    },
    setExpiry () {
      if (!this.$session.exists() && this.activeSection != 'home') {
        this.$session.start()
        this.logData('New Session')
      }
      this.expiryTime = Math.trunc(((new Date()).getTime() + 3 * 60000) / 1000)
    },
    logData (action) {
      return api.logData({
        session: this.$session.id(),
        action: action,
        activeSection: this.activeSection
      })
    },
    logoutUser () {
      this.$session.destroy()
      this.logout()
    },
    setAppDimension () {
      const height = document.documentElement.clientHeight
      const width = document.documentElement.clientWidth
      const wrapper = document.getElementsByClassName('app_wrapper')[0]
      if (width > 1920 || height > 1080) {
        document.body.classList.add('big_screen')
        if (height > 1080) {
          const marginTop = (height - 1080) / 2
          wrapper.style.marginTop = marginTop + 'px'
        }
      } else {
        document.body.classList.remove('big_screen')
        wrapper.style.marginTop = '0px'
      }
      this.setWindowMargin(wrapper.style.marginTop)
    },
    ...mapActions([
      'setActiveSection',
      'setDocType',
      'getFacilityInfo',
      'getAmenities',
      'getFacilityMedia',
      'getFacilityUnits',
      'getFacilityNonInteractiveDocs',
      'setWindowMargin',
      'logout',
      'getAllSpaceStatus'
    ]),
    ...mapActions({
      rentalActiveSection: 'rental/setActiveSection'
    })

  },
  mounted () {
    this.getFacilityInfo()
    this.getAmenities()
    this.getFacilityMedia()
    this.getFacilityUnits()
    this.getFacilityNonInteractiveDocs()
    this.getAllSpaceStatus()
    this.setAppDimension()
    // Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', Dynamsoft_OnReady)
    window.addEventListener('resize', this.setAppDimension)
    window.addEventListener('click', this.setExpiry)
    window.setInterval(() => {
      this.now = Math.trunc((new Date()).getTime() / 1000)
      if (this.now === this.expiryTime && this.dossierPollingStartedStatus) {
        this.expiryTime = Math.trunc(((new Date()).getTime() + 3 * 60000) / 1000)
      }
    }, 1000)

    const store = this.$store

    window._wq = window._wq || []
    _wq.push({
      id: '_all',
      onReady: function (video) {
        video.time(0)
        try {
          if (store.state.kioskConfig.mute_audio) {
            video.mute()
          }
        } catch (err) {
          video.mute()
        }
        video.play()
      }
    })
  },
  computed: Object.assign(
    {
      logoImage: function () {
        if (this.$store.state.kioskConfig.logo) {
          return {
            'background-image': `url(${this.$store.state.kioskConfig.logo})`
          }
        } else {
          return {
            'background-image': background_log
          }
        }
      },
      countDownTimer: function () {
        const remainingTime = this.expiryTime - this.now
        if (remainingTime <= 0) {
          return '0:00' // Handle the case when the timer has expired
        }
        let sec = (remainingTime) % 60
        const min = Math.trunc((remainingTime / 60) % 60)
        sec = sec < 10 ? '0' + sec : sec > 0 ? sec : 0
        return min + ':' + sec
      },
      countDownSec: function () {
        const sec = (this.extendedTime - this.now) % 60
        return (sec > 0) ? sec : 0
      },
      homePageReload: function () {
        return this.now - this.expiryTime
      }
    },
    mapState({
      activeSection: state => state.activeSection,
      facilityNonInteractiveDocs: state => state.facilityNonInteractiveDocs,
      loggedIn: state => state.loggedIn,
      facilityInfo: state => state.facilityInfo,
      currentKey: state => state.activeSectionKey
    }), {
      currentProperties: function () {
        if (this.activeSection == 'rental_docs') {
          return { docType: 'rental_docs' }
        }
        if (this.activeSection == 'insurance') {
          return { docType: 'insurance' }
        }
        if (this.activeSection == 'find_reservation') {
          return { findReservationSection: true, showDocs: this.showDocs }
        }
        if (this.activeSection == 'rent') {
          return { findReservationSection: false, showDocs: this.showDocs }
        }
      },
      showDocs: function () {
        const insurance_agreement_list = [
          'insurance_brochure_non_interactive',
          'insurance_certificate_non_interactive',
          'insurance_enrollment_form_non_interactive'
        ]
        const leaseDocs = []
        const insuranceDocs = []
        for (var docs of this.facilityNonInteractiveDocs) {
          // console.log(docs)
          // console.log(insurance_agreement_list.includes(docs.document_type))
          if (insurance_agreement_list.includes(docs.document_type)) { insuranceDocs.push(docs) } else { leaseDocs.push(docs) }
        }
        return {
          lease: leaseDocs.length,
          insurance: insuranceDocs.length
        }
      }
    }
  ),
  watch: {
    activeSection: function () {
      if (this.activeSection == 'home') {
        this.currentComponent = 'Welcome'
      }
      if (this.activeSection == 'rent') {
        this.currentComponent = 'Rental'
      }
      if (this.activeSection == 'size_guide') {
        this.currentComponent = 'SizeGuide'
      }
      if (this.activeSection == 'pay_bill') {
        this.currentComponent = 'PayBill'
      }
      if (this.activeSection == 'autopay') {
        this.currentComponent = 'PayBill'
      }
      if (this.activeSection == 'samples') {
        this.currentComponent = 'Samples'
      }
      if (this.activeSection == 'rental_docs') {
        this.currentComponent = 'NonInteractiveAgreements'
      }
      if (this.activeSection == 'insurance') {
        this.currentComponent = 'NonInteractiveAgreements'
      }
      if (this.activeSection == 'facility_map') {
        this.currentComponent = 'FacilityMap'
      }
      if (this.activeSection == 'find_reservation') {
        this.currentComponent = 'Rental'
      }
    },
    countDownTimer: function () {
      this.dossierPollingStartedStatus = this.$store.state?.rental?.dossierPollingStartedStatus
      if (this.countDownTimer === '0:30' && (this.loggedIn || this.activeSection !== 'home') && !this.dossierPollingStartedStatus) {
        this.onIdle()
      }
    },
    homePageReload: function (value) {
      // Reload only after 30min if the active screen is home page
      if (!value && !this.idle) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        const self = this
        this.timeout = setTimeout(function () {
          self.$session.destroy()
          location.reload()
        }, 30 * 60 * 1000)
      }
    }
  }
}
</script>
<style src="vue-block/dist/block.css"></style>
