import actions from './actions.js'
import mutations from './mutations.js'

const paybill = {
  namespaced: true,
  state: {
    selectedUnit: '',
    section: 'login',
    totalAmount: 0
  },
  actions: actions,
  mutations: mutations
}

export default paybill
