const mutations = {
  setActiveSection (state, section) {
    state.section = section
  },
  updateTotalAmount (state, amount) {
    state.totalAmount += amount
  },
  clearTotalAmount (state) {
    state.totalAmount = 0
  }
}

export default mutations
