import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import rental from './modules/rental/index'
import paybill from './modules/paybill/index'
import mutations from './mutations.js'

Vue.use(Vuex)

const state = {
  activeSection: 'home',
  activeSectionKey: (new Date()).getTime(),
  loggedIn: false,
  facilityId: '',
  userToken: '',
  userEmail: '',
  selectedSizeGuideUnit: false,
  facilityInfo: {},
  facilityMedia: {},
  facilityUnits: {},
  facilitySpaces: [],
  kioskConfig: {},
  facilityAmenities: {},
  allFacilityUnits: [],
  windowMargin: '0px',
  facilityNonInteractiveDocs: [],
  skipAndVerify: false
}

export default new Vuex.Store({
  modules: {
    rental: rental,
    paybill: paybill
  },
  state,
  actions: actions,
  mutations: mutations
})
