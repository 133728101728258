<template>
    <div id="welcome" v-bind:style="configuration.styleObj">
        <img v-if="!configuration.showBgImage" class="welcome_image" src="../assets/graphics/girl.png" />
        <section v-if="configuration.showQuotes">
            <div>
                <h1>{{ configuration.headline }}</h1>
                <h4>{{ configuration.subHeadline }}</h4>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: 'Welcome',
  computed: Object.assign(
    {
      configuration: function () {
        const { head_line, sub_head_line, show_copy, show_background_image, background_image } = this.$store.state.kioskConfig || {}
        return {
          headline: head_line || 'WELCOME',
          subHeadline: sub_head_line || 'Get started by selecting one of the options to the left',
          showBgImage: show_background_image,
          showQuotes: show_copy,
          styleObj: {
            ...(show_background_image && { background: `url("${background_image}")` })
          }
        }
      }
    }
  )
}
</script>
<style scoped lang="less">
    section {
        float: right;
        height: calc(100%);
        margin-right: calc(10%);
        div {
            width: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: calc(100%);
            h1 {
                max-width: 600px;
                color: #FFFFFF;
                font-size: 75px;
                line-height: 75px;
                font-weight: normal;
                margin-bottom: 62px;
                letter-spacing: 19.18px;
                text-align: center;
            }
            h4 {
                color: #FFFFFF;
                font-size: 30px;
                line-height: 41px;
                font-weight: 100;
                max-width: 450px;
                text-align: center;
            }
        }
    }
</style>
