import axios from 'axios'
import qs from 'qs'
import { mapModelToFormData } from './base'
const CancelToken = axios.CancelToken
let cancel
const commonAxios = axios.create()
const communicationAppAxios = axios.create()
var envConfig = process.env
// Add a request interceptor
commonAxios.interceptors.request.use(function (config) {
  config.baseURL = envConfig.VUE_APP_API_BASE_URL
  config.headers['X-storageapi-date'] = Date.now().toString().slice(0, -3)
  config.headers['X-storageapi-key'] = envConfig.VUE_APP_KIOSK_API_KEY
  config.headers['X-storageapi-trace-id'] = sessionStorage['vue-session-key'] ? JSON.parse(sessionStorage['vue-session-key'])['session-id'] : ''
  return config
})
communicationAppAxios.interceptors.request.use(function (config) {
  config.baseURL = envConfig.VUE_APP_API_BASE_URL
  config.headers['X-storageapi-date'] = Date.now().toString().slice(0, -3)
  config.headers['X-storageapi-key'] = envConfig.VUE_APP_KIOSK_API_KEY
  config.headers['X-storageapi-trace-id'] = sessionStorage['vue-session-key'] ? JSON.parse(sessionStorage['vue-session-key'])['session-id'] : ''
  return config
})
commonAxios.interceptors.response.use(
  (response) => {
    const appId = Object.keys(response.data.applicationData)
    return {
      data: response.data.applicationData[appId][0]
    }
  },
  (error) => {
    const appId = Object.keys(error.response.data.applicationData)
    const e = { data: error.response.data.applicationData[appId][0] }
    return Promise.reject(e)
  }
)
const path = window.location.pathname
const match = path.match(/^\/(fac[\d\w]*)/)
var facId = match ? match[1] : ''
class API {
  constructor (facilityId = facId) {
    this.facilityId = facilityId
    // generate passport token header
    this.passportTokenHeader = token => {
      return token ? { headers: { 'X-passport-token': token } } : null
    }
  }

  async createDossierId (ownerId, facilityId, data) {
    try {
      const response = await commonAxios.post(
        `/applications/${envConfig.VUE_APP_MITEK_APP_ID}/v1/owners/${ownerId}/facilities/${facilityId}/dossiers/`,
        data
      )
      return response.data?.data
    } catch (error) {
      console.log('error', error)
    }
  }

  async updateDossierData (dossierId, data) {
    const response = await commonAxios.put(
      `/applications/${envConfig.VUE_APP_MITEK_APP_ID}/v1/dossiers/${dossierId}/`,
      data
    )
    return response
  }

  async getDossierData (dossierId) {
    const response = await commonAxios.get(
      `/applications/${envConfig.VUE_APP_MITEK_APP_ID}/v1/dossiers/${dossierId}/`
    )
    return response.data?.data
  }

  // to send IDV verification link
  async sendSms (ownerId, facilityId, body, sendNotification) {
    // console.log('ownerId', ownerId, 'facilityId', facilityId, 'body', body)
    const response = await commonAxios.post(
      `/applications/${envConfig.VUE_APP_IDV_APP_ID}/v1/owners/${ownerId}/facilities/${facilityId}/identity-verification?sendNotification=${sendNotification}`,
      body
    )
    return response.data?.data
  }

  // eslint-disable-next-line camelcase
  async getExistingIncodeSessionData (facilityId, incode_token, clientId) {
    const headers = {
      'X-incode-token': incode_token
    }
    const response = await commonAxios.get(
      `/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${facilityId}/get-existing-onboarding-url/?clientId=${clientId}&liveData=true`,
      {
        headers: headers
      }
    )
    return response.data
  }

  async getIncodeSessionData (facilityId) {
    const response = await commonAxios.get(
      `/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${facilityId}/generate-incode-session/?liveData=true`
    )
    return response.data
  }

  async getIncodeStatus (facilityId, interviewID) {
    const headers = {
      'X-incode-token': JSON.parse(localStorage.getItem('X-incode-token')).token
    }
    const response = await commonAxios.get(
      `/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${facilityId}/get-status/?id=${interviewID}&liveData=true`,
      {
        headers: headers
      }
    )
    return response.data
  }

  async getOCRData (facilityId, interviewID) {
    const headers = {
      'X-incode-token': JSON.parse(localStorage.getItem('X-incode-token')).token
    }
    const response = await commonAxios.get(
        `/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${facilityId}/get-ocr-image-data/?interview_id=${interviewID}&liveData=true`,
        {
          headers: headers
        }
    )
    return response.data
  }

  async getImageData (facilityId, interviewID) {
    const headers = {
      'X-incode-token': JSON.parse(localStorage.getItem('X-incode-token')).token
    }
    const response = await commonAxios.post(
        `/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${facilityId}/get-ocr-image-data/?interview_id=${interviewID}&liveData=true`,
        {},
        {
          headers: headers
        }
    )
    return response.data
  }

  async getScores (facilityId, interviewID) {
    const headers = {
      'X-incode-token': JSON.parse(localStorage.getItem('X-incode-token')).token
    }
    const response = await commonAxios.get(
        `/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${facilityId}/get-score/?interview_id=${interviewID}&liveData=true`,
        {
          headers: headers
        }
    )
    return response.data
  }

  async uploadToS3 (url, data, headers) {
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: headers
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async getMitekDetails (ownerId, facilityId, idvId, body) {
    const response = await commonAxios.post(
      `/applications/${envConfig.VUE_APP_IDV_APP_ID}/v1/owners/${ownerId}/facilities/${facilityId}/identity-verification/${idvId}/verify`,
      body
    )
    return response.data
  }

  async getPreviouslyVerifiedUserDetails (ownerId, emaiId, userToken) {
    try {
      const headers = {
        'X-passport-token': userToken
      }
      const response = await commonAxios.get(
        `/applications/${envConfig.VUE_APP_IDV_APP_ID}/v1/owners/${ownerId}/items?emailId=${encodeURIComponent(emaiId)}`,
        {
          headers: headers
        }
      )
      return response.data?.data
    } catch (error) {
      console.log('error', error)
    }
  }

  async getDocumentURL (ownerId, docId, token = null) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await commonAxios.get(
        `/applications/${envConfig.VUE_APP_FILE_APP_ID}/v1/owners/${ownerId}/files/${docId}/`,
        { cancelToken: token }
      )
      const fileAppResponse = response?.data?.file
      // console.log('FileResponse', fileAppResponse)
      if (typeof fileAppResponse === 'object') { return fileAppResponse?.download_url }
      return fileAppResponse
    } catch (error) {
      throw error
    }
  }

  async getIncodeDocumentURL (ownerId, docId, token = null) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await commonAxios.get(
        `/applications/${envConfig.VUE_APP_FILE_APP_ID}/v1/owners/${ownerId}/files/${docId}/`,
        { cancelToken: token }
      )
      return response
    } catch (error) {
      throw error
    }
  }

  async getFileUploadURL (ownerId, body) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await commonAxios.post(
        `/applications/${envConfig.VUE_APP_FILE_APP_ID}/v1/owners/${ownerId}/files/`,
        body
      )
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  async getUser (requestData, throwErrorBack = false) {
    try {
      const response = await commonAxios.get(`/applications/${envConfig.VUE_APP_PASSPORT_KEY}/users`, {
        params: requestData,
        data: requestData
      })
      return response
    } catch (error) {
      if (throwErrorBack) throw error
      // console.log('Get user error', error)
    }
  }

  async getOtpPhone (phone, ownerID) {
    // console.log('phone', phone)
    const body = {
      phone: phone
    }
    const response = await commonAxios.post(
        `/applications/${
          envConfig.VUE_APP_COMMUNICATION_APP_ID
        }/v1/owners/${ownerID}/otp/`,
        body
    )
    // console.log('otpResponse', response?.data?.data)
    return response?.data?.data
  }

  async loginUser (credentials, type = '') {
    // console.log('credentials', credentials)
    // 'type' can be anything like google, facebook etc.. (if support from apps)
    let url = `/applications/${envConfig.VUE_APP_PASSPORT_KEY}/login`
    if (type) url += `/${type}`
    const response = await commonAxios.post(url, credentials)
    return response?.data?.data
    // try {

    // } catch (error) {
    //   throw error?.response?.data?.applicationData?.[
    //     envConfig.VUE_APP_PASSPORT_KEY
    //   ]?.[0]?.error ?? 'error'
    // }
  }

  async getUserWithToken (token, throwErrorBack = false) {
    try {
      const response = await commonAxios.get(
        `/applications/${envConfig.VUE_APP_PASSPORT_KEY}/users`,
        this.passportTokenHeader(token)
      )
      return response?.data?.data?.user
    } catch (error) {
      if (throwErrorBack) {
        throw error?.response?.data?.applicationData?.[
          envConfig.VUE_APP_PASSPORT_KEY
        ]?.[0]?.data?.[0]
      }
      // console.log('Get user error', error)
    }
  }

  async getKioskConfig () {
    const response = await commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/configuration/`)
    return response.data.result ? response.data.result : response
  }

  getFacilityInfo () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/`, {
      params: {
        All: true,
        facility_id: this.facilityId
      }
    })
  }

  getAmenities () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/amenities/`, {
      params: {
      }
    })
  }

  postCardSwipe (obj) {
    var obj2 = {}
    obj2.card_data = JSON.stringify(JSON.parse(obj).CardSwipeOutput)
    var strObj = qs.stringify(obj2)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/credit-card/`, strObj)
  }

  getFacilityMedia () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/media/`, {
      params: {
        facility_id: this.facilityId,
        enable_size_guide_videos: true
      }
    })
  }

  getFacilityInsurance (unitTypeId = '') {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/facility-insurances/`, {
      params: {
        facility_id: this.facilityId,
        space_type_id: unitTypeId
      }
    })
  }

  getFacilityLockDetail (unitTypeId = '') {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/merchandise/`, {
      params: {
        facility_id: this.facilityId,
        space_type_id: unitTypeId
      }
    })
  }

  getFacilityUnits () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/space-types/`, {
      params: {
        All: true,
        facility_id: this.facilityId
      }
    })
  }

  getMoveInCost ({ unitId = '', availableUnitId = '', promoId = '', insuranceId = '', holdToken = '', merchandiseItem = '', reservationId = '' }) {
    if (cancel) {
      cancel()
    }
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/space-types/${unitId}/rental-cost/`, {
      cancelToken: new CancelToken(function executor (c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
      params: {
        facility_id: this.facilityId,
        available_unit_id: availableUnitId,
        promo_id: promoId,
        insurance_id: insuranceId,
        hold_token: holdToken,
        merchandise_item: merchandiseItem,
        res_id: reservationId
      }
    }).catch(function (thrown) {
      if (commonAxios.isCancel(thrown)) {
        return 'cancelled'
      } else {
        // handle error
      }
    })
  }

  processRental (obj) {
    obj.facility_id = this.facilityId
    var strObj = qs.stringify(obj)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/rental/`, strObj)
  }

  saveFailedRental (obj) {
    obj.facility_id = this.facilityId
    var strObj = qs.stringify(obj)
    return commonAxios.post('/save-failed-rental/', strObj)
  }

  processReservation (data) {
    const obj = {
      tenant_info: mapModelToFormData(data)
    }
    obj.move_in_date = data.reservation_date
    obj.hold_token = data.hold_token
    var strObj = qs.stringify(obj)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/space/${data.selectedSpace}/reserve/`, strObj)
  }

  textGateCode (obj) {
    var strObj = qs.stringify(obj)
    return commonAxios.post('/text-gate-code/', strObj)
  }

  updatePassword (obj) {
    var strObj = qs.stringify(obj)
    return commonAxios.post('/update-consumer-password/', strObj)
  }

  processAutoPay (obj) {
    obj.facility_id = this.facilityId
    const spaceId = obj.space_id
    const tenantId = obj.tenant_id
    var strObj = qs.stringify(obj)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/tenant/${tenantId}/space/${spaceId}/autopay/`, strObj)
  }

  processMerchandise (obj) {
    obj.facility_id = this.facilityId
    var strObj = qs.stringify(obj)
    return commonAxios.post('/process-merchandise-sales/', strObj)
  }

  getSizeGuide (all = false) {
    var obj = {
      facility_id: this.facilityId,
      all: all
    }
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/size-guide/`, {
      params: obj
    })
  }

  getUnitsAvailabilityForFacility (unitIds = []) {
    var params = new URLSearchParams()
    params.append('facility_id', this.facilityId)
    params.append('unit_ids', JSON.stringify(unitIds))
    return commonAxios.get('/get-units-availability-for-facility/', { params })
  }

  getHoldSpaceTypes (unitId) {
    var params = new URLSearchParams()
    params.append('facility_id', this.facilityId)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/space-types/${unitId}/hold/`, params)
  }

  getUnitPromo (unitId) {
    return commonAxios.get('/get-unit-promo/', {
      params: {
        facility_id: this.facilityId,
        unit_id: unitId
      }
    })
  }

  holdSpace (spaceId) {
    var params = new URLSearchParams()
    params.append('facility_id', this.facilityId)
    params.append('space_id', spaceId)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/spaces/${spaceId}/hold/`, params)
  }

  validateGateCode (gateCode) {
    var str = qs.stringify({
      facility_id: this.facilityId,
      gatecode: gateCode
    })
    return commonAxios.post('/validate-gatecode/', str)
  }

  login (model) {
    var params = new URLSearchParams()
    params.append('facility_id', this.facilityId)
    params.append('username', model.username)
    params.append('password', model.password)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/login/`, params)
    // return axios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/login/`, {
    //     params: {
    //         token: token,
    //         username: model.username,
    //         password: model.password,
    //         facility_id: this.facilityId
    //     }
    // })
  }

  forgotPassword (model) {
    var params = new URLSearchParams()
    params.append('email', model.email)
    params.append('facility_id', this.facilityId)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/reset-password/`, params)
  }

  findReservation (model) {
    var params = new URLSearchParams()
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/find-reservation/`, {
      params: {
        reservation_code: model.reservation_code,
        facility_id: this.facilityId
      }
    })
  }

  createUser (model) {
    var params = new URLSearchParams()
    params.append('facility_id', this.facilityId)
    params.append('username', model.username)
    params.append('password', model.password)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/user/`, params)
  }

  getUserDetails (userToken, idvId) {
    const params = {
      user_token: userToken,
      facility_id: this.facilityId
    }
    if (idvId) {
      params.idv_id = idvId
    }
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/get-user-details/`, {
      params: params
    })
  }

  getUserItems (obj) {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/user/items/`, {
      params: {
        user_token: obj.userToken,
        user_email: obj.userEmail
      }
    }).catch(error => {
      return error.response.data
    })
  }

  getUserItemDetails (gdsId, tenantId) {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${gdsId}/tenant/${tenantId}/`)
  }

  getPrePayDetails (gdsId, tenantId, spaceId, period = '1') {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${gdsId}/tenant/${tenantId}/space/${spaceId}/pre-pay/`, {
      params: {
        period: period
      }
    })
  }

  payBill (model) {
    const gdsId = model.facility_gds_id
    const tenantId = model.tenant_id
    const spaceId = model.space_id
    model.charges = JSON.stringify(model.prePayDetails)
    // model['total_amount'] = 1 // for testing

    const data = qs.stringify(model)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${gdsId}/tenant/${tenantId}/space/${spaceId}/pre-pay/`, data)
  }

  enrollAutoPay (model) {
    const gdsId = model.facility_gds_id
    const tenantId = model.tenant_id
    const spaceId = model.space_id
    model.charges = JSON.stringify(model.prePayDetails)
    // model['total_amount'] = 1 // for testing

    const data = qs.stringify(model)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${gdsId}/tenant/${tenantId}/space/${spaceId}/autopay/`, data)
  }

  cancelAutoPay (model) {
    const gdsId = model.facility_gds_id
    const tenantId = model.tenant_id
    const spaceId = model.space_id
    // let str =  qs.stringify({token: token})
    // return axios.delete('/user/facility/' + gdsId + '/tenant/' + tenantId + '/space/' + spaceId + '/autopay/', str)
    return commonAxios.delete(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${gdsId}/tenant/${tenantId}/space/${spaceId}/autopay/`)
  }

  linkRental (model) {
    const data = qs.stringify(model)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${model.facility_id}/user/items/`, data)
  }

  getFacilityCities () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/owner/cities/`)
  }

  getPandaTokens (obj) {
    if (!obj.facility_id) {
      obj.facility_id = this.facilityId
    }
    var str = qs.stringify(obj)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/pandadocs/generate-tokens/`, str)
  }

  getPandaAgreements (obj, flow = 'moveIn') {
    obj.flow = flow
    if (!obj.facility_id) {
      obj.facility_id = this.facilityId
    }
    obj.facility_id
    var str = qs.stringify(obj)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/pandadocs/generate-agreements/`, str)
  }

  releaseSpace (spcId, holdToken) {
    var obj = {}
    obj.facility_id = this.facilityId
    obj.space_id = spcId
    obj.hold_token = holdToken
    var str = qs.stringify(obj)
    return commonAxios.post(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/release-space/`, str)
  }

  getNonInteractiveAgreements () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/pandadocs/non-interactive-agreements/`, {
      params: {
        facility_id: this.facilityId
      }
    })
  }

  getFacilityMap () {
    return commonAxios.get('/user/facility/' + this.facilityId + '/map/', {
      params: {
        facility_id: this.facilityId
      }
    })
  }

  getAllSpaceStatus () {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/spaces/`, {
      params: {
        facility_id: this.facilityId
      }
    })
  }

  getSpaceStatus (obj) {
    var dic = {
      facility_id: this.facilityId,
      live_data: true
    }
    // var str = qs.stringify(obj);
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/space/${obj.door_id}/`, {
      params: dic
    })
  }

  getSpaceDetails (spaceId) {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/space/${spaceId}/details/`)
  }

  logData (data) {
    const logData = {
      component: 'kiosk',
      log_level: 'info',
      timestamp: parseInt(new Date().getTime() / 1000).toString(),
      event_name: `Navigation ${data.action}`,
      log: data
    }
    return commonAxios.post(`/applications/${envConfig.VUE_APP_LOGGING_APP_ID}/log`, logData)
  }

  getStatename (opt, model) {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_KIOSK_APP_ID}/v1/facilities/${this.facilityId}/zipcode/`, {
      params: {
        zipcode: model
      }
    })
    // return opt.statename = 'florida'
    // console.log("Model Value in getStatename222222", opt.statename)
  }

  getCapabilities (ownerId) {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_WEBSITE_APP_ID}/v1/owners/${ownerId}/website/facilities/${this.facilityId}/capabilities/`)
  }

  validateNumber (number) {
    return commonAxios.get(`/applications/${envConfig.VUE_APP_COMMON_DATA_APP_ID}/v1/validate-phone/`, {
      params: {
        phone_number: number
      }
    })
    // return opt.statename = 'florida'
    // console.log("Model Value in getStatename222222", opt.statename)
  }

  autoPaymentMail (data) {
    const response = communicationAppAxios.post(`/applications/${envConfig.VUE_APP_COMMUNICATION_APP_ID}/v1/owners/${window.kioskConfig.owner_id}/facilities/${data.facilityId}/tenant/${data.tenantId}/autopay/`, data.data)
    return response.data
  }

  paymentMail (data) {
    const response = communicationAppAxios.post(`/applications/${envConfig.VUE_APP_COMMUNICATION_APP_ID}/v1/owners/${window.kioskConfig.owner_id}/facilities/${data.facilityId}/tenant/${data.tenantId}/payment/`, data.data)
    return response.data
  }

  async reservationSuccessMail (data) {
    const response = await communicationAppAxios.post(`/applications/${envConfig.VUE_APP_COMMUNICATION_APP_ID}/v1/owners/${window.kioskConfig.owner_id}/facilities/${this.facilityId}/space-type/${data.space_type}/reservation/`, data)
    return response.data
  }

  reservationFailureMail (data) {
    const response = communicationAppAxios.post(`/applications/${envConfig.VUE_APP_COMMUNICATION_APP_ID}/v1/owners/${window.kioskConfig.owner_id}/facilities/${this.facilityId}/space-type/${data.space_type}/reservation/failure/`, data)
    return response.data
  }

  rentalSuccessMail (data) {
    const response = communicationAppAxios.post(`/applications/${envConfig.VUE_APP_COMMUNICATION_APP_ID}/v1/owners/${window.kioskConfig.owner_id}/facilities/${this.facilityId}/space-type/${data.space_type}/rental/`, data)
    return response.data
  }

  rentalFailureMail (data) {
    const response = communicationAppAxios.post(`/applications/${envConfig.VUE_APP_COMMUNICATION_APP_ID}/v1/owners/${window.kioskConfig.owner_id}/facilities/${this.facilityId}/space-type/${data.space_type}/rental/failure/`, data)
    return response.data
  }
}

export default API
