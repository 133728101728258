<template>
    <div class="review-box" :class="reviewType">
        <div class="review-overall-section">
            <img v-if="reviewType == 'google'" class="google_image" src="../../assets/graphics/google_logo.png" />
            <img v-else class="yelp_image" src="../../assets/graphics/yelp.png" />
            <div class="hide"><span class="typexxx">{{ reviewType }}</span></div>
            <div class="hide"><span>Review Count {{ reviews.review_count }}</span></div>
            <div class="hide"><span>Overall Rating {{ reviews.overall_rating }}</span></div>
        </div>
        <ul>
            <Review
                v-for="(review, index) in reviews.user_reviews"
                :review="review"
                :key="index"
                :reviewType="reviewType"
            ></Review>
        </ul>
    </div>
</template>

<script>
import Review from './Review'
export default {
  name: 'reviewBox',
  props: ['reviewType', 'reviews'],
  components: {
    Review
  }
}
</script>

<style lang="less" scoped>
    @import '../../assets/less/mixins';
    .hide {
        display: none;
    }
    .review-box {
        padding: 30px;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #DCDCDC;
        background-color: #FFFFFF;
        .review-overall-section {
            padding-bottom: 20px;
            border-bottom: 1px solid #e7e7e7;
        }
        &.google {
            margin-bottom: 20px;
        }
        &.yelp {
            margin-top: 35px;
            margin-bottom: 20px;
        }
    }
</style>
