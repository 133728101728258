<template>
    <div class="facility-unit-wraper" v-if="availableUnits">
        <h3 @click.prevent="unitToggle" :class="{ 'active': isUnitActive }">
            <span class="unit-tittle">{{ unitSize }}</span>
            <span class="unit-left" v-if="!showCount">
                <span class="text">{{ availableUnitDescription }} LEFT</span>
                <span class="unit-available">{{ availableUnits }}</span>
            </span>
            <span class="unit-button-wrapper">
                <span class="unit-price">
                    <span class="text">BEST PRICE</span>
                    <span class="price">{{ bestPriceList[0].rental_rate }}</span>
                    <span class="text">SELF-SERVE</span>
                </span>
                <span class="button ripple">SELECT</span>
            </span>
            <span class="thumbnail-sec">
                <span class="fit-text">See what fits!</span>
                <img class="video-thumbnail" @click.prevent="sizeVideo(unitSize)" src="../../assets/graphics/icon_video.png">
            </span>
        </h3>
        <ul class="unit-spaces">
            <li
                is="UnitType"
                v-for="(unit, index) in sortedUnitsList"
                :key="index"
                :unit="unit"
                :is-instore="isInstore"
                :is-category="hideCategory"
            ></li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UnitType from './UnitType'
export default {
  name: 'unitCategory',
  data: function () {
    return {
      isUnitActive: false
    }
  },
  props: [
    'unitSize',
    'unitsList',
    'isInstore',
    'showCount',
    'hideCategory'
  ],
  methods: Object.assign(
    {},
    mapActions({
      setSelectedUnit: 'rental/setSelectedUnit',
      setActiveSection: 'setActiveSection',
      setSizeGuideUnit: 'setSizeGuideUnit'
    }),
    {
      unitToggle: function () {
        this.isUnitActive = !this.isUnitActive
        if (this.isUnitActive) {
          this.$emit('setVideo', this.unitSize.replace('X-', 'Extra '))
        } else {
          this.$emit('setVideo', '')
        }
      },
      sizeVideo: function (unitSize) {
        this.setSizeGuideUnit(unitSize.replace('X-', 'Extra '))
        this.setActiveSection('size_guide')
      }
    }
  ),
  computed: Object.assign(
    {},
    mapState({
      openedUnitGroup: state => state.rental.openedUnitGroup
    }),
    {
      sortedUnitsList: function () {
        let sortedUnitsList = this.unitsList.slice().sort((a, b) => a.actual_width < b.actual_width ? -1 : (a.actual_width > b.actual_width ? 1 : 0))
        sortedUnitsList = sortedUnitsList.sort((a, b) => a.rental_rate < b.rental_rate ? -1 : (a.rental_rate > b.rental_rate ? 1 : 0))
        sortedUnitsList = sortedUnitsList.sort((a, b) => a.actual_square_feet < b.actual_square_feet ? -1 : (a.actual_square_feet > b.actual_square_feet ? 1 : 0))
        return sortedUnitsList
      },
      availableUnits: function () {
        var count = 0
        for (var i = 0; i < this.sortedUnitsList.length; i++) {
          count += this.sortedUnitsList[i].quantity_available_to_rent
        }
        return count
      },
      availableUnitDescription: function () {
        return this.unitSize.toLowerCase() == 'parking' ? 'spaces' : 'units'
      },
      bestPriceList: function () {
        var bestpriceList = this.unitsList.sort((a, b) => a.actual_width < b.actual_width ? -1 : (a.actual_width > b.actual_width ? 1 : 0))
        bestpriceList = bestpriceList.filter(function (val) {
          return val.rental_rate > 0
        })
        bestpriceList = bestpriceList.sort((a, b) => a.rental_rate < b.rental_rate ? -1 : (a.rental_rate > b.rental_rate ? 1 : 0))
        bestpriceList = bestpriceList.sort((a, b) => a.actual_square_feet < b.actual_square_feet ? -1 : (a.actual_square_feet > b.actual_square_feet ? 1 : 0))
        return bestpriceList
      }
    }
  ),
  components: {
    UnitType
  },
  mounted () {
    if (this.openedUnitGroup == this.unitSize) {
      this.isUnitActive = true
    }
    this.$emit('updateSizeCount', this.unitsList.length)
  }
}
</script>

<style lang="less" scoped>
    @import '../../assets/less/colors';
    @import '../../assets/less/mixins';
    @import '../../assets/less/flexbox';
    .box(@padding: 0px) {
        padding: @padding;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #DCDCDC;
        background-color: #FFFFFF;
    }
    .facility-unit-wraper {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0px;
        }
        h3 {
            height: 72px;
            .flex-display(flex);
            .align-items(center);
            font-weight: 500;
            .box(@padding: 11px 15px 11px 30px);
            color: @common_text_color_dark;
            span {
                display: inline-block;
            }
            .unit-tittle {
                width: 39%;
                font-size: 22px;
                text-transform: uppercase;
            }
            .unit-left {
                line-height: 14px;
                position: relative;
                top: -5px;
                .text {
                    font-size: 10px;
                    text-transform: uppercase;
                }
                .unit-available {
                    display: block;
                    font-size: 25px;
                    text-align: center;
                    line-height: 26px;
                }
            }
            .unit-button-wrapper {
                .flex-display(flex);
                .align-items(center);
                margin-left: auto;
                .unit-price {
                    font-size: 10px;
                    margin-right: 20px;
                    .price {
                        font-size: 25px;
                        text-align: right;
                        display: block;
                        line-height: 20px;
                        &:before {
                            content: "$";
                            font-size: 24px;
                        }
                    }
                }
                .button {
                    color: #FFFFFF;
                    font-size: 15px;
                    line-height: 14px;
                    padding: 14px 19px;
                    border-radius: 3px;
                    margin-left: auto;
                    background-color: @call_to_action_color;
                }
            }
            .fit-text, .video-thumbnail {
                display: none;
            }
            &.active {
                padding: 10px 15px 10px 30px;
                .thumbnail-sec {
                    .flex-display(flex);
                    .align-items(center);
                    margin-left: auto;
                    .fit-text {
                        font-size: 18px;
                        margin-right: 30px;
                        color: @common_text_color_dark;
                    }
                }
                .unit-button-wrapper, .unit-left {
                    display: none;
                }
                .fit-text, .video-thumbnail {
                    display: block;
                }
                + .unit-spaces {
                    max-height: 5000px;
                    border: 1px solid #dcdcdc;
                    border-top: none;
                }
            }
        }
        .unit-spaces {
            .transition(all .5s ease-out);
            max-height: 0px;
            overflow: hidden;
            border-radius: 0px 0px 5px 5px;
            border: none;
            li {
                border-bottom: 1px solid #dcdcdc;
                padding: 15px 25px 19px 25px;
                // background-color: @non_action_bg;
                color: @common_text_color_dark;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
</style>
