export const PhoneMixin = {
  methods: {
    formatPhone: function (phone) {
      if (phone) {
        phone = phone.replace('+1', '')
        return phone.replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      }
      return ''
    }
  }
}
