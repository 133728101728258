<template>
    <div>
        <header :style="getHeaderStyle">
            <h1>{{ title }}</h1>
            <h2>{{ sub_title }}</h2>
        </header>
        <div class="header_space"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'kheader',
  props: ['title', 'sub_title'],
  computed: {
    ...mapState({
      windowMargin: state => state.windowMargin
    }),
    getHeaderStyle () {
      return {
        top: this.windowMargin
      }
    }
  }
}
</script>

<style scoped lang="less">
    @import '../assets/less/colors';
    .big_screen {
        header {
            width: 1344px;
        }
    }
    header {
        padding: 70px 94px 65px;
        background: white;
        position: fixed;
        width: 70%;
        top: 0px;
        height: 195px;
        box-sizing: border-box;
    }
    .header_space {
        height: 195px;
    }
    h1 {
        font-family: Gotham;
        font-weight: 500;
        font-size: 35px;
        color: @call_to_action_color;
        letter-spacing: 7.16px;
        margin: 0px;
        line-height: 35px;
        text-transform: uppercase;
    }
    h2 {
        max-width: 780px;
        font-family: Gotham;
        font-size: 22px;
        color: @small_instructions;
        line-height: 24px;
        font-weight: 400;
        margin: 15px 0px 0px;
    }
</style>
