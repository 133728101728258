<template>
    <router-view></router-view>
</template>

<script type="text/javascript">
import API from './utils/api'
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      colors: {},
      FacId: this.$route.params.facId,
      RouterName: this.$route.name,
      pageTitle: ''
    }
  },
  metaInfo () {
    return {
      title: this.pageTitle
      //   link: [
      //     { rel: 'icon', href: 'https://dxe8u5ve6yax0.cloudfront.net/media/favicon_ds.ico'
      // }
      // ]
    }
  },
  computed: {
    ...mapState(['kioskConfig'])
  },
  async created () {
    const api = new API(this.FacId)
    let kioskConfig = {}
    try {
      kioskConfig = await api.getKioskConfig()
      this.colors = kioskConfig.colors
      window.kioskConfig = {
        ...kioskConfig,
        mute_audio: false
      }
      this.pageTitle = kioskConfig.title
      this.appendGlobalColors(kioskConfig.colors)
    } catch (error) {
      if (this.RouterName != 'time')
      this.$router.push({ name: 'unavailable', params: { error: `Kiosk is not configured for this facility '${this.FacId}'` } })
    } finally {
      if (kioskConfig.owner_id)
        this.getCapabilities(kioskConfig.owner_id)
      this.setKioskConfig(kioskConfig)
    }
  },
  methods: {
    ...mapMutations(['setKioskConfig']),
    ...mapActions(['getCapabilities']),
    appendGlobalColors (styles) {
      const style = document.createElement('style')
      style.innerHTML = `
                :root {
                    ${Object.entries(styles).reduce((acc, curr) => {
                        acc = `${acc} --${curr[0]}: ${curr[1]};`
                      return acc
                    }, '')}
                }
            `
      document.head.appendChild(style)
    }
  }
}
</script>
