const mutations = {
  setActiveSection (state, section) {
    state.section = section
  },
  setMilitary (state, value) {
    state.militarySelected = value
  },
  setSelectedUnit (state, value) {
    const unit = value || {}
    state.selectedUnit = unit
  },
  setOpenedUnitGroup (state, value) {
    state.openedUnitGroup = value
  },
  setDossierId (state, value) {
    state.dossierId = value
  },
  updateIdVerificationStatus (state, value) {
    state.idVerificationStatus = value
  },
  updateDossierPollingStarted (state, value) {
    state.dossierPollingStartedStatus = value
  }
}

export default mutations
