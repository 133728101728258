<template>
    <li :class="{ 'default_promo': defaultPromo }" v-observe-visibility="{
          callback: visibilityChanged,
          throttle: 100,
        }">
        <div class="redeem" v-if="defaultPromo && space.status=='available'">
            <span>Save &amp; Rent Now</span>
        </div>
        <div class="top">
            <div class="unit-name" @click.prevent="unitToggle">
                <span class="icon-view_more_icon icon" :class="{ 'active': isExpand }"></span>
                <span class="name-wrapper">
                    <span class="name">{{ space.unit.actual_width}} X {{ space.unit.actual_length }} Unit</span>
                    <span class="type">
                        <span class="door-name">{{ space.door_name }}</span> | <span>{{ space.unit.unit_type }}</span>
                    </span>
                </span>
            </div>
            <span class="action-sec" v-if="space.status.length==0">
                <div class="loading"><span class="loader"></span></div>
            </span>
            <span class="occupied" v-else-if="space.status=='rented'">OCCUPIED</span>
            <span class="action-sec" v-else-if="space.status=='available'">
                <span class="rental-rate">
                    <span class="text">SELF-SERVE</span>
                    <span class="price">{{ space.unit.rental_rate }}</span>
                </span>
                <span class="rent-button" @click.prevent="setSelected">SELECT</span>
            </span>
            <span class="occupied" v-else-if="space.status=='held'">TEMPORARY HOLD IN PLACE</span>
            <span class="occupied" v-else>UNAVAILABLE</span>
        </div>
        <transition name="fade">
            <SpaceAccordion
                v-if="isExpand"
                :space="space"
                :defaultPromo="defaultPromo"
                :spaceImages="spaceDetails.images"
                :amenities="spaceDetails.amenities"
                :features="spaceDetails.features"
            ></SpaceAccordion>
        </transition>
    </li>
</template>

<script>
import { mapActions } from 'vuex'
import SpaceAccordion from './SpaceAccordion'
export default {
  name: 'spaceDetails',
  props: ['space', 'index', 'total', 'buttonStatus', 'facilityMedia', 'isExpand'],
  components: {
    SpaceAccordion
  },
  computed: {
    defaultPromo: function () {
      return this.space.unit.promos.filter((promo) => promo.promo_type == 'default')[0]
    }
  },
  methods: {
    ...mapActions({
      getSpaceStatus: 'getSpaceStatus',
      getSpaceDetails: 'getSpaceDetails',
      setActiveSection: 'setActiveSection',
      setRentalActiveSection: 'rental/setActiveSection'
    }),
    visibilityChanged (isVisible) {
      if (isVisible && this.space.status.length == 0) {
        this.getSpaceStatus(this.space).then(response => {
          if (response.status == 'ok') {
            this.space.status = response.result[0].status
            this.space.unit.rental_rate = response.result[0].unit.rate.rental_rate
            if (this.space.status != 'rented' && this.buttonStatus) {
              this.$emit('allSpaceOccupied')
            }
          } else {
            this.space.status = 'unavailable'
          }
        })
      }
    },
    setSelected () {
      this.$emit('setSelectedSpace', {
        available: true,
        available_unit_id: this.space.door_id,
        door_number: this.space.door_name,
        space_type_id: this.space.unit_type_id,
        unit: this.space.unit
      })
    },
    clearSpaceSearch: function () {
      this.$emit('clearSpaceSearch')
    },
    unitToggle: function () {
      if (!this.isExpand) {
        this.getSpaceDetails(this.space.door_id).then(response => {
          if (response.status == 'ok') {
            this.spaceDetails = response.result
            this.isExpand = !this.isExpand
          }
        })
      } else {
        this.isExpand = !this.isExpand
      }
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../../assets/less/colors';
    @import '../../assets/less/flexbox';
    li {
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
        background-color: #fff;
        border-radius: 2.5px;
        padding: 30px 0px;
        .redeem {
            display: block;
            width: 108px;
            height: 108px;
            pointer-events: none;
            background-color: @promo_color;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            position: absolute;
            top: -55px;
            right: -55px;
            box-shadow: 0 0 5px 1px #aaa;
            span {
                position: absolute;
                bottom: 3px;
                font-size: 12px;
                right: 20px;
                text-align: center;
                color: white;
                font-weight: 600;
                width: 65px;
                line-height: 14px;
            }
        }
        .top {
            .flex-display(flex);
            .align-items(center);
            .justify-content(@justify: space-between);
            padding: 0px 30px;
            span {
                display: inline-block;
                color: @common_text_color_dark;
            }
            .unit-name {
                width: 45%;
                .flex-display(flex);
                .icon {
                    height: 35px;
                    width: 35px;
                    border-radius: 3px;
                    line-height: 36px;
                    text-align: center;
                    margin-right: 15px;
                    border: 1px solid @common_text_color_dark;
                    &:before {
                        display: inline-block;
                        transform: rotate(90deg);
                        -webkit-transform: rotate(90deg);
                        transition: all 0.5s ease-in-out;
                        -webkit-transition: all 0.5s ease-in-out;
                    }
                    &.active:before {
                        display: inline-block;
                        transform: rotate(-90deg);
                        -webkit-transform: rotate(-90deg);
                        transition: all 0.5s ease-in-out;
                        -webkit-transition: all 0.5s ease-in-out;
                    }
                }
            }
            .name-wrapper {
                // width: 48%;
                .name {
                    font-size: 18px;
                    font-weight: bold;
                    display: block;
                    line-height: 18px;
                }
                .type {
                    font-size: 14px;
                    .door-name {
                        text-transform: uppercase;
                    }
                }
            }
            .board-rate {
                .text {
                    font-size: 12px;
                    display: block;
                    line-height: 12px;
                }
                .price {
                    font-size: 18px;
                    text-align: right;
                    display: block;
                    &:before {
                        content: "$";
                        font-size: 16px;
                    }
                }
            }
            .action-sec {
                .flex-display(flex);
                .align-items(center);
                margin-left: auto;
                .rental-rate {
                    margin-right: 20px;
                    .text {
                        font-size: 12px;
                        font-weight: bold;
                        display: block;
                    }
                    .price {
                        font-size: 28px;
                        font-weight: 500;
                        text-align: right;
                        display: block;
                        line-height: 28px;
                        &:before {
                            content: "$";
                            font-size: 26px;
                        }
                    }
                }
                .rent-button {
                    margin-left: auto;
                    font-size: 15px;
                    font-weight: 500;
                    color: #fff;
                    padding: 9px 23px;
                    box-sizing: border-box;
                    background-color: @call_to_action_color;
                    border-radius: 3px;
                }
            }
            .occupied {
                font-size: 18px;
                font-weight: bold;
                color: #ED1C24;
            }
        }
        .fade-enter-active, .fade-leave-active {
            transition: opacity .5s;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
    }
    .loading span {
        display: block !important;
    }
</style>
