const AMENITIES = {
  climate: 'Climate Control',
  secure: '24 Hour Cameras',
  vehicle: 'RV, Car, Boat',
  truck: 'Truck Rental',
  driveup: 'Drive-up Access',
  twentyfour: '24 Hour Access',
  spanish: 'Se Habla Espanol',
  boxes: 'Boxes & Supplies',
  business: 'Business Center',
  wine: 'Wine Storage',
  mobile: 'Mobile Storage',
  online: 'Online Bill Pay',
  verified: 'Verified',
  inside: 'Inside',
  ezdrivein: 'EZ Drive In',
  ezdrivethru: 'EZ Drive Thru',
  kiosk: '24-hour On-site Payment Kiosk',
  '0freetruck': 'Free Move-In Truck'
}

const DL_STATES = [
  { value: '', text: 'State*' },
  { value: 'AL', text: 'Alabama' },
  { value: 'AK', text: 'Alaska' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DE', text: 'Delaware' },
  { value: 'DC', text: 'District of Columbia' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'HI', text: 'Hawaii' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'IA', text: 'Iowa' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'ME', text: 'Maine' },
  { value: 'MD', text: 'Maryland' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MT', text: 'Montana' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NY', text: 'New York' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VT', text: 'Vermont' },
  { value: 'VA', text: 'Virginia' },
  { value: 'WA', text: 'Washington' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WY', text: 'Wyoming' }
]

const STATES = [
  ...DL_STATES,
  { value: 'AA', text: 'Armed Forces (the) Americas' },
  { value: 'AE', text: 'Armed Forces Europe' },
  { value: 'AP', text: 'Armed Forces Pacific' }
]

const RENTAL_MODEL = {
  // Tenant Info
  tenant_first_name: '',
  tenant_last_name: '',
  tenant_email: '',
  tenant_phone: '',
  mailing_address1: '',
  mailing_address2: '',
  mailing_city: '',
  mailing_state: '',
  mailing_zip: '',
  mailing_zipcode: '',
  driving_lis_no: '',
  driving_lis_state: '',
  driving_lis_expiry: '',
  reservation_code: '',
  reservation_date: (new Date()).toLocaleDateString('en-US'),
  // Secondary Contact Info
  no_secondary_contact: true,
  secondary_contact_first_name: '',
  secondary_contact_last_name: '',
  secondary_contact_email: '',
  secondary_contact_phone: '',
  secondary_contact_address1: '',
  secondary_contact_address2: '',
  secondary_contact_city: '',
  secondary_contact_state: '',
  secondary_contact_zipcode: '',
  // Military Info
  active_military: '',
  military_ssn: '',
  military_ets_date: '',
  military_email: '',
  military_contact_name: '',
  military_commanding_officer: '',
  military_branch: '',
  military_name: '',
  military_unit_phone: '',
  military_address1: '',
  military_address2: '',
  military_city: '',
  military_state: '',
  military_zip: '',

  store_vehicle: '',
  storing_vehicle_type: '',
  other_vehicle_describe: '',

  insurance_id: '',
  item_id: '', // merchandise item id
  promo_id: '',
  gatecode: '',
  reservation_id: '',
  name_on_card: '',
  cc_exp_month: '',
  cc_exp_year: '',
  cc_number: '',
  cvv: '',
  unit_door_number: '',
  unit_type_id: '',
  auto_debit: false,
  hold_token: '',

  password: '',
  repassword: '',
  phonenumber: '',
  newpassword: '',
  newpassword1: '',

  total_amount: 0,
  senior: false,
  birthday: '',
  student: false,
  school_name: '',
  student_id: ''
}

const monthList = Array.from(Array(12).keys(), x => x + 1)
const yearList = Array.from(Array(21).keys(), x => new Date().getFullYear() + x)

function generateOptions (data, defaultValue) {
  const optionsList = data.map(value => {
    const obj = {}
    obj.value = value
    obj.text = value
    return obj
  })
  optionsList.splice(0, 0, { value: '', text: defaultValue })
  return optionsList
}

// let MONTHS = Array.from(Array(12).keys(), x => x + 1)
// MONTHS.splice(0, 0, 'Month')
// let YEARS = Array.from(Array(21).keys(), x => new Date().getFullYear() + x)
// YEARS.splice(0, 0, 'Year')

const MONTHS = generateOptions(monthList, 'Month*')
const YEARS = generateOptions(yearList, 'Year*')



const BARCODE_INFO = [
      { desc: "All", val: -32505857, bf:1 },
      { desc: "1D Barcodes", val: 2047, bf:1  },
      { desc: "QR Code", val: 67108864, bf:1  },
      { desc: "PDF417", val: 33554432, bf:1  },
      { desc: "DATAMATRIX", val: 134217728 , bf:1 },
      { desc: "Aztec Code", val: 268435456, bf:1  },
      { desc: "GS1 Databar", val: 260096, bf:1  },
      { desc: "Maxicode", val: 536870912, bf:1  },
      { desc: "Patch Code", val: 262144, bf:1  },
      { desc: "GS1 COMPOSITE", val: -2147483648, bf:1 },
      { desc: "Postal Code", val: 32505856, bf:2 },
      { desc: "DotCode", val: 2, bf:2 },
      { desc: "CODE_39", val: 1, bf:1 },
      { desc: "CODE_128", val: 2, bf:1  },
      { desc: "CODE_93", val: 4, bf:1  },
      { desc: "CODABAR", val: 8, bf:1  },
      { desc: "EAN_13", val: 32, bf:1  },
      { desc: "EAN_8", val: 64, bf:1  },
      { desc: "UPC_A", val: 128, bf:1  },
      { desc: "UPC_E", val: 256, bf:1  },
      { desc: "Interleaved 2 of 5", val: 16, bf:1  },
      { desc: "Industrial 2 of 5", val: 512, bf:1  },
      { desc: "CODE 39 EXTENDED", val: 1024, bf:1  }
]

const DL_FIELDS = [
        { 'abbreviation': 'DAB', 'description': 'Last Name', 'field': 'NameLast1'},
        { 'abbreviation': 'DAC', 'description': 'First Name', 'field': 'NameFirst1' },
        { 'abbreviation': 'DAG', 'description': 'Mailing Street Address1', 'field': 'Address' },
        { 'abbreviation': 'DAH', 'description': 'Mailing Street Address2', 'field': 'Address2' },
        { 'abbreviation': 'DAI', 'description': 'Mailing City', 'field': 'City' },
        { 'abbreviation': 'DAJ', 'description': 'Mailing Jurisdiction Code', 'field': 'State'},
        { 'abbreviation': 'DAK', 'description': 'Mailing Postal Code', 'field': 'Zip' },
        { 'abbreviation': 'DAQ', 'description': 'License or ID Number', 'field': 'license'},
        { 'abbreviation': 'DBA', 'description': 'License Expiration Date', 'field':'ExpirationDate4'},
        { 'abbreviation': 'DBB', 'description': 'Date of Birth' },
        { 'abbreviation': 'DBC', 'description': 'Sex' },
        { 'abbreviation': 'DBD', 'description': 'License or ID Document Issue Date' },
        { 'abbreviation': 'DBE', 'description': 'Issue Timestamp' },
        { 'abbreviation': 'DBF', 'description': 'Number of Duplicates' },
        { 'abbreviation': 'DBG', 'description': 'Medical Indicator Codes' },
        { 'abbreviation': 'DBH', 'description': 'Organ Donor' },
        { 'abbreviation': 'DBI', 'description': 'Non-Resident Indicator' },
        { 'abbreviation': 'DBJ', 'description': 'Unique Customer Identifier' },
        { 'abbreviation': 'DBK', 'description': 'Social Security Number' },
        { 'abbreviation': 'DBL', 'description': 'Date Of Birth' },
        { 'abbreviation': 'DBM', 'description': 'Social Security Number' },
        { 'abbreviation': 'DBN', 'description': 'Full Name' },
        { 'abbreviation': 'DBO', 'description': 'Last Name', 'field': 'NameLast2' },
        { 'abbreviation': 'DBO', 'description': 'Family Name' },
        { 'abbreviation': 'DBP', 'description': 'First Name', 'field': 'NameFirst2' },
        { 'abbreviation': 'DBP', 'description': 'Given Name' },
        { 'abbreviation': 'DBQ', 'description': 'Middle Name' },
        { 'abbreviation': 'DBQ', 'description': 'Middle Name or Initial' },
        { 'abbreviation': 'DBR', 'description': 'Suffix' },
        { 'abbreviation': 'DBS', 'description': 'Prefix' },
        { 'abbreviation': 'DCA', 'description': 'Virginia Specific Class' },
        { 'abbreviation': 'DCB', 'description': 'Virginia Specific Restrictions' },
        { 'abbreviation': 'DCD', 'description': 'Virginia Specific Endorsements' },
        { 'abbreviation': 'DCE', 'description': 'Physical Description Weight Range' },
        { 'abbreviation': 'DCF', 'description': 'Document Discriminator' },
        { 'abbreviation': 'DCG', 'description': 'Country territory of issuance' },
        { 'abbreviation': 'DCH', 'description': 'Federal Commercial Vehicle Codes' },
        { 'abbreviation': 'DCI', 'description': 'Place of birth' },
        { 'abbreviation': 'DCJ', 'description': 'Audit information' },
        { 'abbreviation': 'DCK', 'description': 'Inventory Control Number' },
        { 'abbreviation': 'DCL', 'description': 'Race Ethnicity' },
        { 'abbreviation': 'DCM', 'description': 'Standard vehicle classification' },
        { 'abbreviation': 'DCN', 'description': 'Standard endorsement code' },
        { 'abbreviation': 'DCO', 'description': 'Standard restriction code' },
        { 'abbreviation': 'DCP', 'description': 'Jurisdiction specific vehicle classification description' },
        { 'abbreviation': 'DCQ', 'description': 'Jurisdiction-specific' },
        { 'abbreviation': 'DCR', 'description': 'Jurisdiction specific restriction code description' },
        { 'abbreviation': 'DCS', 'description': 'Family Name' },
        { 'abbreviation': 'DCS', 'description': 'Last Name', 'field': 'NameLast3' },
        { 'abbreviation': 'DCT', 'description': 'Given Name' },
        { 'abbreviation': 'DCT', 'description': 'First Name', 'field': 'NameFirst3' },
        { 'abbreviation': 'DCU', 'description': 'Suffix' },
        { 'abbreviation': 'DDA', 'description': 'Compliance Type' },
        { 'abbreviation': 'DDB', 'description': 'Card Revision Date' },
        { 'abbreviation': 'DDC', 'description': 'HazMat Endorsement Expiry Date' },
        { 'abbreviation': 'DDD', 'description': 'Limited Duration Document Indicator' },
        { 'abbreviation': 'DDE', 'description': 'Family Name Truncation' },
        { 'abbreviation': 'DDF', 'description': 'First Names Truncation' },
        { 'abbreviation': 'DDG', 'description': 'Middle Names Truncation' },
        { 'abbreviation': 'DDH', 'description': 'Under 18 Until' },
        { 'abbreviation': 'DDI', 'description': 'Under 19 Until' },
        { 'abbreviation': 'DDJ', 'description': 'Under 21 Until' },
        { 'abbreviation': 'DDK', 'description': 'Organ Donor Indicator' },
        { 'abbreviation': 'DDL', 'description': 'Veteran Indicator' },
        { 'abbreviation': 'PAA', 'description': 'Permit Classification Code' },
        { 'abbreviation': 'PAB', 'description': 'Permit Expiration Date' },
        { 'abbreviation': 'PAC', 'description': 'Permit Identifier' },
        { 'abbreviation': 'PAD', 'description': 'Permit IssueDate' },
        { 'abbreviation': 'PAE', 'description': 'Permit Restriction Code' },
        { 'abbreviation': 'PAF', 'description': 'Permit Endorsement Code' },
        { 'abbreviation': 'ZVA', 'description': 'Court Restriction Code' }
    ];

export {
  AMENITIES,
  DL_STATES,
  STATES,
  MONTHS,
  YEARS,
  RENTAL_MODEL,
  BARCODE_INFO,
  DL_FIELDS
}
