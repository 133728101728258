<template>
    <div class="login wrapper_container reset_password">
        <KHeader :title="passwordHeader" :sub_title="passwordSubHeader"></KHeader>
        <div class="container" id="hold_unitds">
            <div class="login_form" v-if="!showSection">
                <div class="form_row">
                    <label class="show_in_keyboard">Email</label>
                    <span class="error" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                    <input @focus="showKeyBoard" class="email" v-bind:class="{ input_error: errors.has('email') }" v-validate="{ required: true, email: true}" v-model="model.email" name="email" type="text" placeholder="Email" required data-layout="email"/>
                </div>
                <button type="submit" v-loading :class="{loading: submitLogin}" class="ripple" @click.prevent="onSubmit"><span>Reset Password</span></button>
            </div>
            <div class="form_links" v-if="!showSection">
                <a href="#" @click.prevent="setActiveSection('register')">Don't have an Account? <strong>Register here</strong></a>
            </div>

            <div  class="thankyou" v-if="showSection">
                <h3 v-html="msgString"></h3>
                <button type="submit" class="ripple" @click.prevent="setActiveSection('login')">
                    <span>Login</span>
                </button>
            </div>

        </div>
        <vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField" />
    </div>

</template>

<script>
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { mapGetters, mapActions, mapState } from 'vuex'
import KHeader from '../Header'
import { keyboardMixin } from '../../mixins/Keyboard.js'
Vue.use(VeeValidate)

export default {
  name: 'forgot_password',
  props: ['isRental'],
  mixins: [keyboardMixin],
  data () {
    return {
      submitLogin: false,
      /* showSection ---- Created for Redirect page */
      showSection: false,
      msgString: '<span>An email with a password reset link has been sent to you!</span> Please follow the instructions in the email to reset your password.',
      model: {
        email: ''
      },
      fieldErrors: {
        __all__: '',
        email: ''
      }

    }
  },
  components: {
    KHeader
  },
  computed: {
    passwordHeader () {
      if (this.showSection) {
        return 'Thank you!'
      } else {
        return 'Forgot Password'
      }
    },
    passwordSubHeader () {
      if (this.showSection) {
        return ''
      } else {
        return 'Please enter your email so we can look up your account'
      }
    }
  },
  methods: Object.assign({},
    mapActions({
      setActiveSection: 'paybill/setActiveSection',
      forgot_password: 'forgotPassword'
    }),
    {
      onSubmit: function () {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submitLogin = true
            this.forgot_password(this.model).then(response => {
              this.submitLogin = false
              if (response.status == 'error') {
                this.fieldErrors = response.errors
                this.msgString = this.fieldErrors.__all__[0]
                this.showSection = true
              } else {
                this.showSection = true
              }
            })
          }
        })
      }
    }
  )
}
</script>

<style scoped lang="less">
 @import '../../assets/less/colors';
.reset_password {
    .form_links {
        width: 425px;
    }
    .thankyou {
        width: 80%;
        // display: none;
        // &.active {
        //     display: block;
        // }
        h3 {
            font-size: 28px;
            font-weight: 300;
            color: @call_to_action_color;
            span {
                font-weight: 500;
            }
        }
        button {
            width: 391px;
        }
    }
}
</style>
