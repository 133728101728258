const questions = [
  {
    question: 'How many baby great white sharks can fit into a 5x5 storage unit?',
    options: {
      a: '4',
      b: '7',
      c: '5'
    },
    answer: 'a'
  }, {
    question: 'How many disco balls can fit into a 5x5 storage unit?',
    options: {
      a: '460',
      b: '370',
      c: '250'
    },
    answer: 'c'
  }, {
    question: 'How many top hats can fit into a 5x5 storage unit?',
    options: {
      a: '600',
      b: '485',
      c: '717'
    },
    answer: 'b'
  }, {
    question: 'How many Tyrannosaurus rex skulls can fit into a 5x5 storage unit?',
    options: {
      a: '6',
      b: '2',
      c: '4'
    },
    answer: 'b'
  }, {
    question: 'How many pairs of jeans will fit into a 5x5 storage unit?',
    options: {
      a: '875',
      b: '2,300',
      c: '1,080'
    },
    answer: 'c'
  }, {
    question: 'Before storing your vehicle, which of the following must you have?',
    options: {
      a: 'Registration',
      b: 'New tires',
      c: 'Jumper cables'
    },
    answer: 'a'
  }, {
    question: 'What kind of cardboard boxes should books be stored in?',
    options: {
      a: 'Plastic-lined ',
      b: 'Acid-free',
      c: 'Organic'
    },
    answer: 'b'
  }, {
    question: "Which horror movie villain's storage unit did we see on-screen?",
    options: {
      a: 'Jason Voorhees',
      b: 'Michael Myers',
      c: 'Hannibal Lecter'
    },
    answer: 'c'
  }, {
    question: 'What is the ideal temperature for storing antiques?',
    options: {
      a: '55 degrees',
      b: '71 degrees',
      c: '64 degrees'
    },
    answer: 'c'
  }, {
    question: 'What is the ideal temperature for storing wine?',
    options: {
      a: '55 degrees',
      b: '48 degrees',
      c: '60 degrees'
    },
    answer: 'a'
  }, {
    question: 'Which Hollywood legend kept a horse carriage in his storage unit?',
    options: {
      a: 'Cary Grant',
      b: 'Burt Reynolds',
      c: 'Clint Eastwood'
    },
    answer: 'b'
  }, {
    question: "Which socialite's storage unit was auctioned off for $2,775 in 2005?",
    options: {
      a: 'Lindsay Lohan',
      b: 'Paris Hilton',
      c: 'Kim Kardashian'
    },
    answer: 'b'
  }, {
    question: 'What is the smallest storage unit that will comfortably fit a queen sized mattress?',
    options: {
      a: '5x5',
      b: '5x10',
      c: '5x7'
    },
    answer: 'c'
  }, {
    question: "Which late singer's storage unit contained 273 unreleased songs?",
    options: {
      a: 'David Bowie',
      b: 'Michael Jackson',
      c: 'Frank Sinatra'
    },
    answer: 'b'
  }, {
    question: 'Which self storage lock is the most secure?',
    options: {
      a: 'Disc lock',
      b: 'Padlock ',
      c: 'Combination lock'
    },
    answer: 'a'
  }
]

export default questions
