<template>
    <div class="wrapper">
        <div class="address">
            <h2>{{ space.facility_name }}</h2>
            <p class="mt5">{{ space.address }}</p>
            <p>{{ space.city }}, {{ space.state }} {{ space.zipcode }}</p>
            <span class="icon-call_icon call">{{ space.phone_number|formatPhone }}</span>
            <h4>{{ space.actual_length }}' x {{ space.actual_width }}' {{ space.unit_standard }}</h4>
            <p>{{ space.unit_description }}</p>
        </div>
        <div class="not_movecost bar_loader" v-show="loadingMonthlyCharges">
            <span></span>
        </div>
        <ul class="access-details">
            <li>
                <span>{{ unitDescriptions }} Number:</span>
                <span>{{ space.unit_number }}</span>
            </li>
            <li>
                <span>Gate Code:</span>
                <span>{{ space.accessCode }}</span>
            </li>
        </ul>
        <div class="address info">
            <h3>Account Information:</h3>
            <p>{{ name.first }} {{ name.last }}</p>
            <p>{{ email }}</p>
            <p>{{ phone|formatPhone }}</p>
            <p class="full-address">{{ address.address1 }}, {{ address.address2 }}</p>
            <p>{{ address.city }}, {{ address.stateCode }}, {{ address.postalCode }}</p>
        </div>
        <ul class="agreements" v-if="space.documents">
            <li v-for="doc in space.documents">
                <span>{{ doc.name }}</span>
                <span class="view" @click.prevent="showBrochure(space.documents, doc)">View</span>
            </li>
        </ul>
        <div class="balance">
            <span>Balance Due Now</span>
            <span class="price" v-if="due">{{ due | formatAmount }}</span>
            <span class="price" v-else>$0.00</span>
        </div>
        <div class="next_payment">
            <span class="pay_date">
                <span class="pay_tittle">Next Payment</span>
                <span class="date" v-if="!loadingMonthlyCharges"> Due {{ dueDate | humanDate(ms=true) }}</span>
            </span>
            <span class="amount" v-if="!loadingMonthlyCharges">{{ nextPaymentAmount | formatAmount }}</span>
        </div>
        <div class="card-images">
            <img src="../../assets/graphics/rental/godady.png">
            <img  class="all-cards" src="../../assets/graphics/rental/cards_image.png">
        </div>
        <img class="zigzag-img" src="../../assets/graphics/rental/bottom.png">
    </div>
</template>

<script>
import { paybillMixin } from '../../mixins/paybill.js'
import { mapActions, mapState } from 'vuex'
import NonInteractiveAgreements from '../NonInteractiveAgreements'

export default {
  name: 'rentUnitDetails',
  mixins: [paybillMixin],
  props: ['space', 'tenant'],
  components: {
    NonInteractiveAgreements
  },
  data: function () {
    return {
      isShowBrochure: false
    }
  },
  computed: Object.assign(
    {},
    mapState({
      activeSection: state => state.paybill.section
    }),
    {
      name: function () {
        return this.tenant.name
      },
      email: function () {
        return this.tenant.email
      },
      address: function () {
        return this.tenant.address
      },
      phone: function () {
        const phones = this.tenant.phones
        for (const phone of phones) {
          if (phone.type == 'cell') return phone.number
        }
        for (const phone of phones) {
          if (phone.type == 'home') return phone.number
        }
        for (const phone of phones) {
          if (!phone.type) return phone.number
        }
      },
      nextPaymentAmount: function () {
        return this.due ? this.due : this.prePayDetails.total_amt
      },
      dueDate: function () {
        return this.getBillPaidThroughDate()
      },
      unitDescriptions: function () {
        return this.space.is_parking ? 'Space' : 'Unit'
      }
    }
  ),
  methods: Object.assign(
    {},
    mapActions({
      setActiveSection: 'paybill/setActiveSection'
    }),
    {
      showBrochure: function (docs, clickedDoc) {
        var index = docs.findIndex(x => x.type == clickedDoc.type)
        this.$emit('showBrochure', docs, index)
      }
    }
  ),
  mounted: function () {
    this.getPrePayDetails()
  }

}
</script>

<style lang="less" scoped>
    @import '../../assets/less/colors';
    @import '../../assets/less/flexbox';
    @import '../../assets/less/fontello';

    div, ul, li, span, p {
        box-sizing: border-box;
    }
    .wrapper {
        color: @common_text_color_dark;
        .address {
            padding: 0px 25px 0px 30px;
            h2 {
                font-size: 22px;
                font-weight: 500;
                margin-top: 34px;
                line-height: 24px;
            }
            h3 {
                font-size: 15px;
                font-weight: 500;
            }
            p {
                font-size: 14px;
                font-weight: 300;
                line-height: 18px;
                color: @common_text_color;
            }
            .mt5 {
                margin-top: 5px;
            }
            .full-address {
                margin-top: 8px;
                h3 {
                    padding-bottom: 8px;
                }
            }
            .call {
                font-size: 14px;
                margin-bottom: 20px;
                display: block;
                margin-top: 5px;
                color: @primary_color;
                &:before {
                    color: #fff;
                    height: 30px;
                    width: 30px;
                    margin-right: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    line-height: 30px;
                    background-color: @primary_color;
                }
            }
            &.info {
                margin-bottom: 22px;
            }
        }
        .access-details {
            padding: 14px 15px;
            margin: 15px;
            margin-bottom: 20px;
            background-color: @ma_unit_block_top_bg;
            li {
                .flex-display(flex);
                .align-items(center);
                .justify-content(space-between);
                font-size: 15px;
                font-weight: 500;
            }
        }
        .agreements {
            padding: 20px 25px 10px 30px;
            background-color: @ma_unit_block_top_bg;
            li {
                .flex-display(flex);
                .align-items(center);
                .justify-content(space-between);
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px;
                .view {
                    border-radius: 5px;
                    display: inline-block;
                    font-size: 12px;
                    width: 80px;
                    height: 25px;
                    text-align: center;
                    line-height: 25px;
                    color: #fff;
                    background-color: @primary_color;
                }
            }
        }
        .balance {
            height: 50px;
            color: #fff;
            font-weight: 500;
            padding: 0px 25px 0px 30px;
            .flex-display(flex);
            .align-items(center);
            .justify-content(space-between);
            background-color: @primary_color;
            .price {
                font-size: 22px;
            }
        }
        .next_payment {
            height: 65px;
            .flex-display(flex);
            .align-items(center);
            padding: 0px 30px;
            font-weight: 500;
            background-color: @primary_shading_color;
            color: @common_text_color_dark;
            .pay_date {
                width: 250px;
                .pay_tittle {
                    font-size: 18px;
                    display: block;
                    line-height: 20px;

                }
                .date {
                    font-size: 13px;
                }
            }
            .amount {
                width: 149px;
                text-align: right;
                font-size: 22px;
            }
        }
        .card-images {
            padding: 26px;
            padding-bottom: 0px;
            .flex-display(flex);
            .align-items(center);
            .justify-content(space-between);
            .all-cards {
                width: 202px;
                margin-left: 15px;
            }
        }
        .zigzag-img {
            left: 0px;
            top: 33px;
            position: relative;
        }
    }
</style>
