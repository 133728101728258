<template>
    <div class="banner_slider_wrapper" ref="slide">
        <ul class="slider_controls">
            <li v-if="leftPosition < 0" class="prev icon-arrow_top" @click.prevent="prev"></li>
            <li v-if="showNext" class="next icon-arrow_top" @click.prevent="next"></li>
        </ul>
        <ul class="banner_slider" :style="{ left: leftPosition + 'px' }">
            <li v-for="(media, index) in facilityMedia" v-if="index!=1">
                <div class="slide_box">
                    <a class="slide_wrapper" v-on:click="popUpImage(index)">
                        <img :src="media.thumbnail_image" :alt="media.category"/>
                    </a>
                </div>
            </li>
            <li v-else>
                <div class="slide_box" v-on:click="showGoogleMap(index)">
                    <a class="slide_wrapper" data-href="google_map">
                        <img :src="mapUrl" style="width:283px; height: 91px;float: left;">
                    </a>
                </div>
                <div class="slide_box" v-on:click="showStreetView(index)">
                    <a class="slide_wrapper" data-href="street_view">
                        <img :src="streetViewUrl">
                    </a>
                </div>
            </li>
        </ul>
        <div class="imgbox" v-bind:class="{ block: showPopup, 'media-loder': isMediaLoading}" v-if="selectedPop">
            <div class="gif-loader-wrapper">
                <div class="gif-loader"></div>
            </div>
            <div class="imgbox-wrapper on-media-load">
                <div class="close-button" @click.prevent="hidePopup">
                    <span class="icon-x-out"></span>
                </div>
                <div class="imgbox-content-wrapper">
                    <div class="imgbox-content">
                        <iframe v-if="selectedMediaIndex == 1" :src="embedUrl" alt="" @load="mediaLoad" alt="" width="875" height="489" ></iframe>
                        <img v-else-if="selectedPop.media_type=='image'" @load="mediaLoad" :src="selectedPop.media_url" alt="" width="879" height="570">
                        <object v-else-if="getYoutubeUrl(selectedPop.media_url).length > 0" id="Swiff_jj3wa1wx" height="489" width="875" type="application/x-shockwave-flash" :data="getYoutubeUrl(selectedPop.media_url)">
                            <param name="quality" value="high">
                            <param name="allowScriptAccess" value="always">
                            <param name="wMode" value="transparent">
                            <param name="swLiveConnect" value="true">
                            <param name="autoplay" value="true">
                            <param name="bgcolor" value="#ff3300">
                        </object>
                        <iframe v-else-if="selectedPop.media_type!='video'" :src="selectedPop.media_url" alt="" @load="mediaLoad" width="875" height="489"></iframe>
                        <div v-for="(media, index) in facilityMedia" v-if="media.wistia_video_id && !video" v-show="index == selectedMediaIndex && media.wistia_video_id !='' && media.media_type=='video'" :class="videoClass(media.wistia_video_id)" class="wistia_embed"  :id="videoId(media.wistia_video_id)" style="width:960px;height:540px;"></div>
                    </div>
                    <div class="imgbox-arrows">
                        <span class="prev-arrow icon-arrow_top" @click.prevent="prevPopUp" v-if="selectedMediaIndex > 0"></span>
                        <span class="next-arrow icon-arrow_top" @click.prevent="nextPopUp" v-if="selectedMediaIndex < facilityMedia.length-1"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mask" v-bind:class="{ block: showPopup }"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'slider',
  props: ['facilityMedia', 'video'],
  data: function () {
    return {
      leftPosition: 0,
      scrollDelta: 0,
      totalSlides: 0,
      showNext: false,
      showPopup: false,
      selectedMediaIndex: 0,
      isMediaLoading: false,
      isStreetView: false,
      isMapView: false,
      mapKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      wistiaVideo: ''
    }
  },
  mounted: function () {
    this.scrollDelta = Math.floor(this.$refs.slide.clientWidth / 285)
    this.updateSlideData()
  },
  methods: {
    next: function () {
      var x = this.leftPosition - this.scrollDelta * 285
      if (-(x - this.scrollDelta * 285) > this.totalSlides * 285) {
        this.leftPosition = -(this.totalSlides * 285 - this.$refs.slide.clientWidth)
      } else {
        this.leftPosition = x
      }
    },
    prev: function () {
      var x = this.leftPosition + this.scrollDelta * 285
      this.leftPosition = (x < 0) ? x : 0
    },
    videoClass: function (video) {
      return 'wistia_async_' + video
    },
    videoId: function (video) {
      return 'id_wistia_async_' + video
    },
    updateSlideData: function () {
      this.totalSlides = Math.ceil((this.facilityMedia.length - 1) / 2) + 2
      if (this.totalSlides * 285 < this.scrollDelta * 285 || !this.facilityMedia.length) {
        this.showNext = false
      } else {
        this.showNext = true
      }
    },
    popUpImage: function (index) {
      this.selectedMediaIndex = index
      this.showPopup = true
    },
    hidePopup: function () {
      this.showPopup = false
      this.selectedMediaIndex = 0
    },
    prevPopUp: function () {
      if (this.selectedMediaIndex == 1) {
        if (this.isStreetView) {
          this.isStreetView = false
          this.isMapView = true
          return
        }
      }
      this.selectedMediaIndex--
      if (this.selectedMediaIndex == 1) this.isStreetView = true
      this.isMediaLoading = true
    },
    nextPopUp: function () {
      if (this.selectedMediaIndex == 1) {
        if (this.isMapView) {
          this.isMapView = false
          this.isStreetView = true
          return
        }
      }
      this.selectedMediaIndex++
      if (this.selectedMediaIndex == 1) this.isMapView = true
    },
    mediaLoad: function () {
      var self = this
      setTimeout(function () { self.isMediaLoading = false }, 1000)
    },
    showStreetView: function () {
      this.isMapView = false
      this.isStreetView = true
      this.popUpImage(1)
    },
    showGoogleMap: function () {
      this.isStreetView = false
      this.isMapView = true
      this.popUpImage(1)
    },
    getStreetViewUrlParams: function () {
      try {
        var svo = this.facility.street_view
        return 'key=' + this.mapKey + '&location=' + svo.lat + ',' + svo.lon + '&heading=' + svo.heading + '&pitch=' + svo.pitch
      } catch (err) {
        return 'key=' + this.mapKey + '&location=' + this.facility.latitude + ',' + this.facility.longitude + '&heading=114.86266053582285&pitch=-0.5911672348795207'
      }
    },
    getYoutubeUrl: function (url) {
      var self = this
      if (url != undefined || url != '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        if (match && match[2].length == 11) {
          setTimeout(function () { self.isMediaLoading = false }, 1000)
          return 'https://www.youtube.com/v/' + match[2] + '?rel=0&amp;hd=1&amp;autoplay=1'
        }
      }
      return ''
    }
  },
  computed: Object.assign(
    {},
    mapState({
      facility: state => state.facilityInfo
    }),
    {
      mapUrl: function () {
        return 'https://maps.googleapis.com/maps/api/staticmap?key=' + this.mapKey + '&center=' + this.facility.latitude + ',' + this.facility.longitude + '&zoom=13&size=283x91&maptype=roadmap&markers=label:S%7C' + this.facility.latitude + ',' + this.facility.longitude + '&style=feature%3Alandscape%7Celement%3Ageometry%7Ccolor%3A0xf6f6f6&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Ccolor%3A0xababab&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xf3f3f3&style=feature:road.highway%7Celement:labels.icon%7Chue:0xffaa00%7Csaturation:-92'
      },
      streetViewUrl: function () {
        return 'https://maps.googleapis.com/maps/api/streetview?size=283x91&' + this.getStreetViewUrlParams()
      },
      streetViewEmbedUrl: function () {
        return 'https://www.google.com/maps/embed/v1/streetview?' + this.getStreetViewUrlParams()
      },
      mapEmbedUrl: function () {
        var url = 'https://www.google.com/maps/embed/v1/place?key=' + this.mapKey + '&zoom=18'
        var latLng = this.facility.latitude + ',' + this.facility.longitude
        url = url + '&q=' + latLng
        return url
      },
      selectedPop: function () {
        return this.facilityMedia[this.selectedMediaIndex]
      },
      embedUrl: function () {
        if (this.isStreetView) return this.streetViewEmbedUrl
        else return this.mapEmbedUrl
      }
    }
  ),
  watch: {
    leftPosition: function () {
      if (this.leftPosition == -(this.totalSlides * 285 - this.$refs.slide.clientWidth) || this.totalSlides * 285 < this.scrollDelta * 285) {
        this.showNext = false
      } else {
        this.showNext = true
      }
    },
    facilityMedia: function () {
      this.updateSlideData()
    },
    selectedMediaIndex: function (newVal, oldVal) {
      const videoId = this.facilityMedia[newVal].wistia_video_id
      const oldVid = this.facilityMedia[oldVal].wistia_video_id
      if (oldVid) {
        const video = Wistia.api(this.videoId(oldVid))
        video.time(0)
        video.play()
      }
      if (videoId) {
        this.wistiaVideo = Wistia.api(this.videoId(videoId))
        this.wistiaVideo.time(0)
        this.wistiaVideo.play()
        this.isMediaLoading = false
        if (window.kioskConfig.mute_audio) {
          this.wistiaVideo.mute()
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
    @import '../../assets/less/mixins';
    .banner_slider_wrapper {
        width: 100%;
        height: 372px;
        overflow: hidden;
        background-color: #f8f8fa;
        position: relative;
        ul {
            box-sizing: border-box;
            display: block;
            &.banner_slider {
                position: absolute;
                left: 0px;
                width: 1000000px;
                .transition(all .65s cubic-bezier(.42,.97,.52,1.49));
                li {
                    background-color: #f8f8fa;
                    width: 283px;
                    height: 185px;
                    cursor: pointer;
                    margin: 0px;
                    position: relative;
                    display: inline-block;
                    margin-left: 2px;
                    .slide_box {
                        display: block;
                        height: 100%;
                        width: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .slide_wrapper {
                            position: relative;
                            display: inline-block;
                            width: 100%;
                            &.icon_360:after {
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                content:'';
                                display: block;
                                position: absolute;
                                // z-index:1;
                                background: url(../../assets/graphics/360.png) no-repeat center;
                            }
                        }
                    }
                    span {
                        position: absolute;
                        font-size: 80px;
                        color: #fff;
                    }
                    &:nth-child(odd) {
                        vertical-align: bottom;
                        margin-left: -283px;
                    }
                    &:nth-child(even) {
                        vertical-align: top;
                    }
                    &:nth-child(1) {
                        width: 570px;
                        height: 372px;
                        margin: 0px;
                        img {
                            height: 374px;
                        }
                    }
                    &:nth-child(2) {
                        .slide_box {
                            height: 91.5px;
                        }
                        .slide_box:first-child {
                            margin-bottom: 2px;
                        }
                    }
                }
            }
            &.slider_controls {
                z-index: 1;
                position: relative;
                li {
                    color: #f8f8fa;
                    position: absolute;
                    font-size: 21px;
                    top: 160px;
                    color: #fff;
                    z-index: 10;
                    cursor: pointer;
                    background-color: rgba(0,0,0, 0.5);
                    padding: 13px;
                    border-radius: 5px;
                    list-style-type: none;
                    &.next {
                        right: -32px;
                        padding-left: 30px;
                        padding-right: 30px;
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }
                    &.prev {
                        left: -32px;
                        padding-left: 30px;
                        padding-right: 30px;
                        -webkit-transform: rotate(-90deg);
                        transform: rotate(-90deg);
                    }
                    &.disabled {
                        opacity: 0.1;
                        transition: opacity 250ms;
                    }
                }
            }
        }
        div {
            &.imgbox {
                display: none;
                position: fixed;
                top: 50%;
                transform: translateY(-50%);
                left: 757px;
                z-index: 150000;
                .close-button {
                    position: absolute;
                    right: -35px;
                    top: -35px;
                    z-index: 5;
                    padding: 18px;
                    box-sizing: border-box;
                    line-height: 0;
                    color: #53AD29;
                    font-size: 28px;
                    position: absolute;
                    background-color: #fff;
                    border-radius: 50%;
                }
                .imgbox-content-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    iframe {
                        background: white;
                    }
                    .imgbox-arrows {
                        position: absolute;
                        width: 100%;
                        z-index: 5;
                        .next-arrow, .prev-arrow {
                            top: 40%;
                            right: -15%;
                            float: right;
                            position: relative;
                            transform: rotate(90deg);
                            font-size: 50px;
                        }
                        .prev-arrow {
                            transform: rotate(270deg);
                            right: 0;
                            left: -15%;
                            float: left;
                        }
                    }
                }
            }
            &.mask {
                display: none;
                opacity: 0.8;
                width: 100%;
                height: 1749px;
                position: fixed;
                left: 0px;
                top: 0px;
                background: #000;
                z-index: 2;
            }
            &.block {
                display: block;
                -webkit-animation: fade .3s linear;
                -moz-animation: fade .3s linear;

            }
            &.media-loder {
                display: none;
                width: 75%;
                margin: auto;
                left: 25%;
                right: 0px;
                &.block {
                    display: block;
                }
                .gif-loader-wrapper {
                    background: white;
                    height: 200px;
                    width: 300px;
                    border-radius: 5px;
                    margin: auto;
                    .gif-loader {
                        height: 100%;
                        width: 100%;
                        background: url('../../assets/graphics/loader.gif') no-repeat center;
                    }
                }
                .imgbox-content-wrapper {
                    display: none;
                }
                .close-button {
                    display: none;
                }
            }
        }
    }
    @-webkit-keyframes fade {
        0% { opacity: 0;}
        100% { opacity: 1;}
    }
    @-moz-keyframes fade {
        0% { opacity: 0;}
        100% { opacity: 1;}
    }
</style>
