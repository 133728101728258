const actions = {
  setActiveSection (context, section) {
    context.commit('setActiveSection', section)
  },
  updateTotalAmount (context, amount) {
    context.commit('updateTotalAmount', amount)
  },
  clearTotalAmount (context) {
    context.commit('clearTotalAmount')
  }
}

export default actions
