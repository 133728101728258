var _slugify = require('slugify')

function formatAmount (cost) {
  const isObject = typeof (cost) === 'object'
  const validCost = isObject ? Object.keys(cost).length : cost
  if (validCost) {
    let amount = isObject ? cost.amount.toString() : cost.toString()
    let precision = amount.length
    const decimalIndex = amount.indexOf('.')
    if (decimalIndex < 1 && amount.includes('-')) { precision += 1 } else if (decimalIndex < 1) { precision += 2 } else if (decimalIndex < 1) { precision += 2 } else {
      let offset = 2
      if (decimalIndex == 1 && amount[0] == 0) { offset = 1 }
      precision = decimalIndex + offset
    }
    amount = '$' + parseFloat(amount).toPrecision(precision)
    if (isObject && (cost.name.toLowerCase() == 'discount' || cost.description.toLowerCase() == 'discount')) { amount = '- ' + amount }
    return amount
  }
}

function toFloat (value) {
  return parseFloat(value)
}

function formatCardMonth (val) {
  return (val < 10 ? '0' : '') + val
}

function formatPhone (number) {
  number = number.replace('+1', '')
  const re = /\((\d{3})\)\s\d{3}-\d{4}/g
  if (number.match(re)) { return number } else {
    const text = number.match(/\d+/g).reduce((a, b) => a + b)
    return '(' + text.slice(0, 3) + ')' + ' ' + text.slice(3, 6) + '-' + text.slice(6)
  }
}

function mapModelToFormData (model) {
  const result = {
    contacts: {
      primary: {
        email: model.tenant_email,
        name: {
          first: model.tenant_first_name,
          last: model.tenant_last_name
        },
        phone: model.tenant_phone,
        address: {
          address1: model.mailing_address1,
          address2: model.mailing_address2,
          state: model.mailing_state,
          zipcode: model.mailing_zipcode,
          city: model.mailing_city
        }
      }
    },
    driving_license: {
      lic_number: model.driving_lis_no,
      lic_state: model.driving_lis_state,
      expiry_date: 0
    }
  }
  return JSON.stringify(result)
}

function toArray (list, start) {
  start = start || 0
  var i = list.length - start
  var ret = new Array(i)
  while (i--) {
    ret[i] = list[i + start]
  }
  return ret
}

function pluralize (array, txt) {
  return array.length == 1 ? txt : txt + 's'
}

function slugify (text) {
  return _slugify(text, {
    lower: true
  })
}

var zxcvbn = require('zxcvbn')

export {
  formatAmount,
  formatPhone,
  toFloat,
  mapModelToFormData,
  pluralize,
  slugify,
  formatCardMonth,
  zxcvbn
}
