<template>
    <div class="qz_sect">
        <h3>WHILE YOU WAIT....</h3>
        <div class="qns_sect" v-for="item in questions" v-if="item == selectedQuestion">
            <div class="qn_title" v-show="!answerSelected">
                <h4>{{ item.question }}</h4>
            </div>
            <div class="qns_options">
                <ul  v-show="!answerSelected">
                    <li @click.prevent="setSelected('a')">
                        <input type="radio"  name="radio-group">
                        <label>{{ item.options.a }}</label>
                    </li>
                    <li @click.prevent="setSelected('b')">
                        <input type="radio" name="radio-group">
                        <label >{{ item.options.b }}</label>
                    </li>
                    <li @click.prevent="setSelected('c')">
                        <input type="radio" name="radio-group">
                        <label>{{ item.options.c }}</label>
                    </li>
                    <li @click.prevent="showAnswer"><a href="" class="see_ans">See Answer</a></li>
                </ul>
                <div class="answer_wrapper" v-show="answerSelected">
                    <p class="wrong_answer" v-if="selectedAnswer != selectedQuestion.answer"><span class="icon-check_box_enabled"></span>Oops...sorry!</p>
                    <p class="correct_answer" v-else><span class="icon-tick_icon"></span>That's correct!</p>
                    <h4>The correct answer is {{ correctAnswer }}</h4>
                    <button @click.prevent="nextQuestion" class="next_qns">Next Question</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import questions from '../utils/trivia.js'
function shuffle (array) {
  var currentIndex = array.length; var temporaryValue; var randomIndex

  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}
export default {
  name: 'trivia',
  data () {
    return {
      questions: shuffle(questions),
      selectedQuestion: questions[0],
      answerSelected: false,
      correctOption: questions[0].answer,
      currentIndex: 0,
      selectedAnswer: ''
    }
  },
  methods: {
    showAnswer: function () {
      this.answerSelected = true
      this.correctOption = this.selectedQuestion.answer
    },
    nextQuestion: function () {
      this.currentIndex++
      this.selectedQuestion = this.questions[this.currentIndex]
      this.answerSelected = false
    },
    setSelected: function (answer) {
      this.selectedAnswer = answer
      this.showAnswer()
    }
  },
  computed: {
    correctAnswer: function () {
      return this.selectedQuestion.options[this.correctOption]
    }
  }
}
</script>

<style scoped>

</style>
