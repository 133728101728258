<template>
    <div>
        <footer :style="getFooterStyle">
            <a class="cancel ripple" v-if="cancelTitle" @click.prevent="cancelAction" href="#">{{ cancelTitle }}</a>
            <a class="done ripple" v-if="doneTitle" @click.prevent="doneAction" href="#">{{ doneTitle }}</a>
        </footer>
        <div class="footer_space"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'kfooter',
  // props: ['cancelTitle', 'doneTitle', 'cancelAction', 'doneAction'],
  props: ['cancelTitle', 'doneTitle'],
  methods: {
    cancelAction: function () {
      this.$emit('cancelAction')
    },
    doneAction: function () {
      this.$emit('doneAction')
    }
  },
  computed: {
    ...mapState({
      windowMargin: state => state.windowMargin
    }),
    getFooterStyle () {
      return {
        bottom: this.windowMargin
      }
    }
  }
}
</script>

<style scoped lang="less">
    @import '../assets/less/colors';
    @import '../assets/less/flexbox';
    @import '../assets/less/mixins';
    @footer_height: 115px;
    .big_screen {
        footer {
            width: 1344px;
        }
    }
    footer {
        z-index: 3;
        background: @call_to_action_color;
        position: fixed;
        width: 70%;
        bottom: 0px;
        height: @footer_height;
        box-sizing: border-box;
        .flex-display();
        .align-items('space-between');
        a {
            font-family: "Gotham";
            font-size: 38px;
            font-weight: 500;
            color: #FFF;
            letter-spacing: 7.77px;
            line-height: @footer_height;
            height: @footer_height;
            text-decoration: none;
            padding: 0px 94px;
            position: relative;
            text-transform: uppercase;
            &.done {
                margin-left: auto;
                padding-left: 0px;
                &:after {
                    font-family: "fontello";
                    content: '\e86b';
                    position: absolute;
                    right: 50px;
                    font-size: 32px;

                }
            }
            &.cancel {
                color: rgba(255, 255, 255, .65);
                font-size: 25px;
                padding-right: 0px;
                &:after {
                    font-family: "fontello";
                    content: '\e86b';
                    position: absolute;
                    left: 50px;
                    .rotate(180deg);
                    font-size: 20px;
                }
            }
        }
    }
    .footer_space {
        height: 25px;
    }
</style>
