import { isCookieEnabled, getCookie, setCookie, removeCookie } from 'tiny-cookie'
import API from '../../../utils/api.js'

const api = new API()

const actions = {
  setActiveSection (context, section) {
    context.commit('setActiveSection', section)
  },
  setMilitary (context, val) {
    context.commit('setMilitary', val)
  },
  setDossier (context, val) {
    context.commit('setDossierId', val)
  },
  setSelectedUnit (context, val) {
    context.commit('setSelectedUnit', val)
  },
  setOpenedUnitGroup (context, val) {
    context.commit('setOpenedUnitGroup', val)
  },
  clearOpenedUnitGroup (context) {
    context.commit('setOpenedUnitGroup', '')
  },
  updateIdVerificationStatus (context, val) {
    context.commit('updateIdVerificationStatus', val)
  },
  updateDossierPollingStarted (context, val) {
    context.commit('updateDossierPollingStarted', val)
  },
  getPandaTokens (context, data) {
    return api.getPandaTokens(data).then(response => {
      return response.data
    }).catch(error => {
      return error.response.data
    })
  },
  processRental (context, data) {
    data.platform = 'tenant-interface'
    return api.processRental(data).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  saveFailedRental (context, data) {
    return api.saveFailedRental(data).then(response => {
      return response.data
    }).catch(error => {
      return error.response.data
    })
  },
  processReservation (context, data) {
    return api.processReservation(data).then(response => {
      return response.data
    }).catch(error => {
      return error.data
    })
  },
  processAutoPay (context, data) {
    return api.processAutoPay(data).then(response => {
      return response.data
    }).catch(error => {
      return error.response.data
    })
  },
  textGateCode (context, data) {
    return api.textGateCode(data).then(response => {
      return response.data
    }).catch(error => {
      return error.response.data
    })
  },
  validateGateCode (context, data) {
    return api.validateGateCode(data)
  },
  updatePassword (context, data) {
    return api.updatePassword(data).then(response => {
      return response.data
    }).catch(error => {
      return error.response.data
    })
  },
  processMerchandise (context, data) {
    return api.processMerchandise(data).then(response => {
      return response.data
    }).catch(error => {
      return error.response.data
    })
  },
  getPandaAgreements (context, data) {
    return api.getPandaAgreements(data).then(response => {
      response.data.result.agreements.forEach(function (el) {
        el.completed = false
      })
      return response.data.result.agreements
    }).catch(error => {
      return []
    })
  },
  releaseSpace (context) {
    var spaces = getCookie('kiosk_spaces')
    if (spaces) {
      spaces = JSON.parse(spaces)
      spaces.forEach(function (el) {
        api.releaseSpace(el.spcId, el.token)
      })
    }
    removeCookie('kiosk_spaces')
  },
  setHoldCookie (context, obj) {
    var key = 'kiosk_spaces'
    var spaces = getCookie(key)
    if (spaces) {
      spaces = JSON.parse(spaces)
    } else {
      spaces = []
    }
    spaces.push(obj)
    setCookie(key, JSON.stringify(spaces), { expires: '15m' })
  }
}

export default actions
