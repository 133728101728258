import { eventsHub } from '../main.js'

export const keyboardMixin = {
  data () {
    return {
      keyBoardVisible: false,
      layout: 'normal',
      input: null,
      showCustomKeyBoard: false,
      selectOptions: [],
      selectedOptionValue: '',
      options: {
        useKbEvents: false
      }
    }
  },
  created: function () {
    eventsHub.$on('sidebarClicked', () => {
      this.hideKeyBoard()
    })
  },
  updated: function () {
    if (this.input) {
      const inputElName = this.input.name
      if (this.input.nodeName == 'INPUT') {
        this.input = document.querySelector('input[name="' + inputElName + '"]')
      } else if (this.input.nodeName == 'SELECT') {
        this.input = document.querySelector('select[name="' + inputElName + '"]')
      }
    }
  },
  methods: {
    acceptValue (text) {
      this.hideKeyBoard()
    },
    showKeyBoard (e, error = '') {
      if (this.positionAbsolute && this.isPopupEnabled) {
        this.positionAbsolute = false
      }
      if (this.positionRelative && this.isPopupEnabled) {
        this.positionRelative = false
      }
      const inputElName = e.target.name
      var self = this
      self.input = e.target
      // Capitalizing first letter and letter after space
      if (!self.input.classList.contains('email') && self.input.type == 'text') {
        self.input.value = self.input.value.replace(/\b./g, function (a) {
          return a.toUpperCase()
        })
      }
      self.layout = e.target.dataset.layout
      self.input.dispatchEvent(new Event('input'))
      var alredyEnabled = self.input.parentNode.classList.contains('in_focus')
      self.input.parentNode.classList.add('in_focus')
      var value = self.input.value
      var fldLength = value.length
      // To set the cursor at the end in each keyboard show event
      setTimeout(function () {
        if (!alredyEnabled) {
          self.input.setSelectionRange(fldLength, fldLength)
        }
      }, 10)
      if (!this.keyBoardVisible) { this.keyBoardVisible = true }
      this.showCustomKeyBoard = false

      // Fix for cvv issue in credit card section
      if (!e.target.getAttribute('data-retry') && ['cc_cvv', 'cvv', 'unit_no', 'billing_zipcode'].indexOf(inputElName) >= 0) {
        this.$validator.reset()
        this.errors.clear()
        setTimeout(function () {
          // self.hideKeyBoard();
          const selEl = document.querySelector('input[name="' + inputElName + '"]')
          selEl.setAttribute('data-retry', '1')
          selEl.focus()
        }, 10)
      }
    },
    showSelectKeyBoard (e) {
      this.hideKeyBoard()
      const self = this
      self.showCustomKeyBoard = true
      self.selectOptions = []
      self.selectedOptionValue = ''
      const inputElName = e.target.name
      self.input = e.target
      self.input.parentNode.classList.add('in_focus')
      for (var i = 0; i < self.input.length; i++) {
        self.selectOptions.push({ value: self.input.options[i].value, text: self.input.options[i].text })
      }
      self.selectedOptionValue = this.input.options[this.input.selectedIndex].value
      // Fix for cvv issue in credit card section
      if (!e.target.getAttribute('data-retry') && ['cc_exp_month', 'cc_exp_year', 'billing_state'].indexOf(inputElName) >= 0) {
        setTimeout(function () {
          self.hideKeyBoard()
          const selEl = document.querySelector('select[name="' + inputElName + '"]')
          selEl.setAttribute('data-retry', '1')
          selEl.click()
        }, 10)
      }
    },
    setSelectedOption (e, index) {
      this.selectedOptionValue = e.value
      this.model[this.input.name] = e.value
    },
    hideKeyBoard () {
      if (this.isPopupEnabled) {
        if (!this.positionAbsolute) {
          this.positionAbsolute = true
        }
        if (!this.positionRelative) {
          this.positionRelative = true
        }
      }
      if (this.input && this.input.parentNode) {
        this.input.parentNode.classList.remove('in_focus')
      }
      this.input = null
      this.keyBoardVisible = false
      this.showCustomKeyBoard = false
    },
    nextKeyBoardField () {
      const inputs = document.querySelectorAll('input, select')
      let found = false;
      [].forEach.call(inputs, (item, i) => {
        if (!found && item == this.input && i < inputs.length - 1) {
          var cInput = inputs[i + 1]
          if (cInput.type != 'checkbox' && cInput.type != 'radio' && !cInput.readOnly && !cInput.classList.contains('default_select')) {
            found = true
            this.$nextTick(() => {
              if (cInput.nodeName == 'SELECT') {
                cInput.click()
              } else {
                cInput.focus()
              }
            })
          }
        }
      })
      this.input.parentNode.classList.remove('in_focus')
      if (!found) {
        this.input.blur()
        this.hideKeyBoard()
      }
    }
  }
}
